$i: 5;
@for $value from 0 through 18 {
  .u-m#{ $value * $i } {
    margin: $i * $value + px!important;
  }
  .u-mtb#{ $value * $i } {
    margin-top: $i * $value + px!important;
    margin-bottom: $i * $value + px!important;
  }
  .u-mlr#{ $value * $i } {
    margin-left: $i * $value + px!important;
    margin-right: $i * $value + px!important;
  }
  .u-mt#{ $value * $i } {
    margin-top: $i * $value  + px!important;
  }
  .u-mb#{ $value * $i } {
    margin-bottom: $i * $value + px!important;
  }
  .u-ml#{ $value * $i } {
    margin-left: $i * $value + px!important;
  }
  .u-mr#{ $value * $i } {
    margin-right: $i * $value + px!important;
  }
  .u-p#{ $value * $i } {
    padding: $i * $value + px!important;
  }
  .u-pt#{ $value * $i } {
    padding-top: $i * $value + px!important;
  }
  .u-pb#{ $value * $i } {
    padding-bottom: $i * $value + px!important;
  }
  .u-pl#{ $value * $i } {
    padding-left: $i * $value + px!important;
  }
  .u-pr#{ $value * $i } {
    padding-right: $i * $value + px!important;
  }
}

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important;
  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important;
  @include breakpoint-pc {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

$i: 5;
@for $value from 1 through 50 {
  .u-mt {
    &#{ $value * $i }_half {
      margin-top: $value * $i / 2 + px!important;
      @include breakpoint-pc {
        margin-top: $value * $i + px!important;
      }
    }
  }
  .u-mb {
    &#{ $value * $i }_half {
      margin-bottom: $value * $i / 2 + px!important;
      @include breakpoint-pc {
        margin-bottom: $value * $i + px!important;
      }
    }
  }
  .u-ml {
    &#{ $value * $i }_half {
      margin-left: $value * $i / 2 + px!important;
      @include breakpoint-pc {
        margin-left: $value * $i + px!important;
      }
    }
  }
  .u-mr {
    &#{ $value * $i }_half {
      margin-right: $value * $i / 2 + px!important;
      @include breakpoint-pc {
        margin-right: $value * $i + px!important;
      }
    }
  }

  .u-pt {
    &#{ $value * $i }_half {
      padding-top: $value * $i / 2 + px!important;
      @include breakpoint-pc {
        padding-top: $value * $i + px!important;
      }
    }
  }
  .u-pb {
    &#{ $value * $i }_half {
      padding-bottom: $value * $i / 2 + px!important;
      @include breakpoint-pc {
        padding-bottom: $value * $i + px!important;
      }
    }
  }
}

$i: 15;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $i }_23rd {
      margin-bottom: $value * $i * 2 / 3 + px!important;
      @include breakpoint-pc {
        margin-bottom: $value * $i + px!important;
      }
    }
  }
}

$i: 10;
@for $value from 1 through 18 {
  .u-mb {
    &#{ $value * $i }_level {
      margin-bottom: $value * $i / 3 + px!important;
      @include breakpoint-contenthalf {
        margin-bottom: $value * $i / 2 + px!important;
      }
      @include breakpoint-pc {
        margin-bottom: $value * $i + px!important;
      }
    }
  }
}

$i: 10;
@for $value from 1 through 18 {
  .u-mb-minus {
    &#{ $value * $i }_half {
      margin-bottom: - $value * $i / 2 + px!important;
      @include breakpoint-pc {
        margin-bottom: - $value * $i + px!important;
      }
    }
  }
}

@for $value from 1 through 10 {
  .u-mb {
    &#{ $value }em {
      margin-bottom: $value + em!important;
    }

    &#{ $value }em_half {
      margin-bottom: $value / 2 + em!important;
      @include breakpoint-pc {
        margin-bottom: $value +em!important;
      }
    }
  }
}

///// 個別指定 /////
.u-mb0_10 {
  margin-bottom: 10px !important;

  @include breakpoint-pc {
    margin-bottom: 0 !important;
  }
}

.u-mbProductsOb01 {
  margin-bottom: 80px !important;

  @include breakpoint-tb {
    margin-bottom: 40px !important;
  }
  @include breakpoint-pc {
    margin-bottom: 120px !important;
  }
}