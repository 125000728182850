.c-flex {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  
  &.-jStart {
    @include flex-justify-flex-start;
  }
  
  &.-jCenter {
    @include flex-justify-center;
  }
  
  &.-jEnd {
    @include flex-justify-flex-end;
  }
  
  &.-aiCenter {
    @include flex-align-items-center;
  }
  
  &.-rowTtl {
    @include flex-justify-flex-start;
    @include flex-align-items-baseline;
    
    &.-history {
      gap: 0 20px;  //25px * 0.8 = 20px
      
      @include breakpoint-pc {
        gap: 0 25px;
      }
    }
  }
  
  
  ///// お取引実績 - 形態 /////
  &.-bizrecHead {
    @include flex-justify-flex-start;
    @include flex-align-items-center;

    @include breakpoint-contentinnerhalf {
      @include flex-justify-flex-end;
      @include flex-align-items-center;
    }
  }
  
  ///// お取引実績、製品開発(つえがさの特徴) - 紺帯本文＆写真 /////
  &.-bizrecBody, &.-tgFeatures {
    @include flex-wrap-wrap;
    @include flex-justify-flex-start;
    @include flex-align-items-end;

    @include breakpoint-pc {
      @include flex-wrap-nowrap;
    }
  }


  ///// 商品アイテムタイトルボックス(PC縦書き、SP横書き) /////
  &.-productTtl {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-flex-end;
    @include flex-align-items-start;
    @include flex-align-content-start;
    gap: 12px 0;  //columnなので、gapはx(10px) y(0)の順での指定となる、15px * 0.8 = 12px

    @include breakpoint-pc {
      gap: 15px 0;  //columnなので、gapはx(10px) y(0)の順での指定となる
    }
    
    &.-rev {
      @include flex-justify-flex-start;
    }

    .c-flex {
      &_item {
        width: 100%;
        
        @include breakpoint-pc {
          width: auto;
        }
        
        &:nth-child(1) {
          @include flex-order(2);

          @include breakpoint-pc {
            @include flex-order(1);
          }
        }
        &:nth-child(2) {
          @include flex-order(1);

          @include breakpoint-pc {
            @include flex-order(2);
          }
        }
        &:nth-child(3) {
          @include flex-order(3);

          @include breakpoint-pc {
            @include flex-order(3);
          }
        }
      }
    }
  }
  
  ///// 製品開発 - つえがさ - 杖・傘 /////
  &.-frontOriginal01 {
    .c-flex {
      &_item {
        position: relative;
        width: 100%;
        
        @include breakpoint-tb {
          width: 50%;
        }
      }
    }
  }

  ///// 製品開発 - つえがさ - 文章＆製品画像 /////
  &.-frontOriginal01Tg {
    gap: 35px 0;
    
    @include breakpoint-pc {
      gap: 0 0;
    }

    .c-flex {
      &_item {
        position: relative;
        
        &.-txt {
          width: 100%;

          @include breakpoint-pc {
            width: 68.75%;  //770px / 1120px * 100% = 68.75%
          }
        }
        
        &.-img {
          width: 100%;

          @include breakpoint-pc {
            width: calc(100% - 68.75%);  //770px / 1120px * 100% = 68.75%
          }
        }
      }
    }
  }
}