.p2-ftinfo {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-start;
  gap: 20px 0;
  color: color-wh(1);

  @include breakpoint-contentinnerhalf {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-align-items-stretch;
    gap: 0 0;
  }

  &_item {
    width: 100%;
    box-sizing: border-box;

    @include breakpoint-contentinnerhalf {
      width: 50%;
    }
    @include breakpoint-content {
    }

    &.-item1 {
      display: block;
      
      @include breakpoint-pc {
      }
    }

    &.-item2 {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-column;
      @include flex-justify-space-between;
      @include flex-align-items-stretch;
      gap: 15px 0;

      @include breakpoint-pc {
        gap: 10px 0;
      }
    }
  }
  
  &_txtbox {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-center;
    @include flex-align-items-center;

    @include breakpoint-contentinnerhalf {
      @include flex-justify-flex-start;
      @include flex-align-items-start;
    }
  }

  &_txt {
    &.-name {
      margin-bottom: 6px;
      @include font-sprmin("sb");
      @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28

      @include breakpoint-pc {
        margin-bottom: 12px;
      }
    }
    
    &.-address, &.-tel {
      @include font-sprmin("sb");
      @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
      @include lineHeight(1.6, 1.6);
    }    
  }
}