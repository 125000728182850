@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}

@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}

@function color-white01($opacity) {
  @return rgba(248, 248, 248, $opacity);  //#f8f8f8
}
@function color-white02($opacity) {
  @return rgba(244, 244, 244, $opacity);  //#f4f4f4
}

@function color-black01($opacity) {
  @return rgba(51, 51, 51, $opacity);  //#333333
}

@function color-gray01($opacity) {
  @return rgba(215, 218, 227, $opacity);  //#d7dae3
}
@function color-gray02($opacity) {
  @return rgba(219, 219, 219, $opacity);  //#dbdbdb
}
@function color-gray03($opacity) {
  @return rgba(166, 167, 176, $opacity);  //#a6a7b0
}

@function color-blue01($opacity) {
  @return rgba(29, 34, 53, $opacity);  //#1d2235
}
@function color-blue02($opacity) {
  @return rgba(40, 48, 79, $opacity);  //#28304f
}
@function color-blue03($opacity) {
  @return rgba(51, 65, 121, $opacity);  //#334179
}
@function color-blue04($opacity) {
  @return rgba(65, 80, 177, $opacity);  //#4150b1
}
@function color-blue05($opacity) {
  @return rgba(221, 226, 246, $opacity);  //#dde2f6
}

@function color-purple01($opacity) {
  @return rgba(135, 102, 172, $opacity);  //#8766ac
}
@function color-purple02($opacity) {
  @return rgba(151, 131, 181, $opacity);  //#9783b5
}
@function color-purple03($opacity) {
  @return rgba(186, 166, 217, $opacity);  //#baa6d9
}

@function color-orange01($opacity) {
  @return rgba(246, 191, 117, $opacity);  //#f6bf75
}

@function color-red01($opacity) {
  @return rgba(215, 113, 133, $opacity);  //#d77185
}

@function color-yellow01($opacity) {
  @return rgba(241, 217, 121, $opacity);  //#f1d979
}


@mixin color-gradation01($direction:forward, $opacity:1) {
  @if $direction == 'forward' {
    background: color-blue04($opacity);
    background: -moz-linear-gradient(90deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -webkit-linear-gradient(90deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%,color-blue04($opacity) 100%);
    background: -o-linear-gradient(90deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -ms-linear-gradient(90deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: linear-gradient(90deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6bf75', endColorstr='#4150b1',GradientType=1 );
  } @else if $direction == 'reverse' {
    background: color-blue04($opacity);
    background: -moz-linear-gradient(270deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -webkit-linear-gradient(270deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%,color-blue04($opacity) 100%);
    background: -o-linear-gradient(270deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -ms-linear-gradient(270deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: linear-gradient(270deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6bf75', endColorstr='#4150b1',GradientType=1 );
  } @else if $direction == 'up' {
    background: color-blue04($opacity);
    background: -moz-linear-gradient(0deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -webkit-linear-gradient(0deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%,color-blue04($opacity) 100%);
    background: -o-linear-gradient(0deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -ms-linear-gradient(0deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: linear-gradient(0deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#afcbf1', endColorstr='#ad7ee7',GradientType=1 );
  } @else if $direction == 'down' {
    background: color-blue04($opacity);
    background: -moz-linear-gradient(180deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -webkit-linear-gradient(180deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%,color-blue04($opacity) 100%);
    background: -o-linear-gradient(180deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: -ms-linear-gradient(180deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    background: linear-gradient(180deg, color-orange01($opacity) 0%, color-red01($opacity) 35%, color-purple01($opacity) 65%, color-blue04($opacity) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6bf75', endColorstr='#4150b1',GradientType=1 );
  }
}
