.l-header {
  width: 100%;
  height: $header-height-sp;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 5vw;
  box-sizing: border-box;

  @include breakpoint-pc {
    height: $header-height-pc;
    padding: 0 $header-gnav-gap-x-pc 0 5vw;
    mix-blend-mode: exclusion;
  }
  @include breakpoint-narrowinner {
    height: $header-height-ni;
    padding: 0 $header-gnav-gap-x-ni 0 5vw;
  }
  @include breakpoint-narrow {
    height: $header-height-na;
    padding: 0 60px;
  }
  
  &.js-isscroll {

    @include breakpoint-pc {
    }
    @include breakpoint-content {
    }
  }
  &.js-noscroll {

    @include breakpoint-pc {
    }
  }
  
  &_inner {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
}