.p3-headline {
  &.-absolute {
    position: absolute;
  }
  
  &.-relative {
    position: relative;
  }

  ///// 見出しテキスト寄せ /////
  &.-left {
    text-align: left;
  }  
  &.-center {
    text-align: center;
  }  
  &.-right {
    text-align: right;
  }  
  &.-justify {
    text-align: justify;
  }
  
  ///// 見出しテキストカラー /////
  &.-wh {
    color: color-wh(1);
  }

  &.-bk {
    color: color-bk(1);
  }

  &.-white01 {
    color: color-white01(1);
  }
  &.-white02 {
    color: color-white02(1);
  }

  &.-black01 {
    color: color-black01(1);
  }

  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-gray03 {
    color: color-gray03(1);
  }

  &.-blue01 {
    color: color-blue01(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }
  &.-blue03 {
    color: color-blue03(1);
  }
  &.-blue04 {
    color: color-blue04(1);
  }
  &.-blue05 {
    color: color-blue05(1);
  }

  &.-purple01 {
    color: color-purple01(1);
  }
  &.-purple02 {
    color: color-purple02(1);
  }
  &.-purple03 {
    color: color-purple03(1);
  }
  
  &.-orange01 {
    color: color-orange01(1);
  }

  &.-red01 {
    color: color-red01(1);
  }

  &.-yellow01 {
    color: color-yellow01(1);
  }


  ///// letter-spacing /////
  //マイナス値。句読点用
  &.-lsm050 {
    letter-spacing: -0.5em;
  }
  &.-lsm025 {
    letter-spacing: -0.25em;
  }
  //VA80相当
  &.-ls008 {
    letter-spacing: 0.08em;
  }
  //VA100相当
  &.-ls010 {
    letter-spacing: 0.1em;
  }
  //VA250相当・VA255相当
  &.-ls025 {
    letter-spacing: 0.25em;
  }


  ///// 複数列テキスト /////
  &.-col2 {
    div {
      column-count: 1;

      @include breakpoint-pc {
        column-count: 2;
        column-gap: 5vw;
      }
      @include breakpoint-contentinner {
        column-gap: 60px;
      }
    }
  }
  
  
  ///// 縦書き /////
  &.-tategaki {
    @include tategaki("upright");

    &.-mixed {
      @include tategaki("mixed");
    }
    &.-sideways {
      @include tategaki("sideways");
    }

    &.-center {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  &.-pcTate_spYoko {
    @include breakpoint-pc {
      @include tategaki("upright");
    }

    &.-mixed {
      @include breakpoint-pc {
        @include tategaki("mixed");
      }
    }
    &.-sideways {
      @include breakpoint-pc {
        @include tategaki("sideways");
      }
    }
  }
  
  
  ///// 注釈(上付き文字) /////
  &.-annotation {
    font-size: 50%;
    vertical-align: super;
  }

  
  &.-ttl01 {
    @include font-sprmin("r");
    font-size: 3.22rem; //4.6 * 0.7 = 3.22
    @include lineHeight(1.5, 1.5);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh140 {
      @include lineHeight(1.4, 1.4);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 3.68rem; //4.6 * 0.8 = 3.68
    }
    @include breakpoint-pc {
      font-size: 4.6rem;
    }
  }

  &.-ttl02 {
    @include font-sprmin("r");
    font-size: 2.52rem; //3.6 * 0.7 = 2.52
    @include lineHeight(1.5, 1.5);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.88rem; //3.6 * 0.8 = 2.88
    }
    @include breakpoint-pc {
      font-size: 3.6rem;
    }
  }

  &.-ttl03 {
    @include font-sprmin("r");
    font-size: 2.24rem; //3.2 * 0.7 = 2.24
    @include lineHeight(1.5, 1.5);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.56rem; //3.2 * 0.8 = 2.56
    }
    @include breakpoint-pc {
      font-size: 3.2rem;
    }
  }
  
  &.-ttl04 {
    @include font-sprmin("r");
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    @include lineHeight(1.5, 1.5);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }

  &.-ttl71 {
    @include font-critxt("r");
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    @include lineHeight(1, 1);

    &.-semibold {
      @include font-critxt("sb");
    }
    &.-bold {
      @include font-critxt("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }
}