.c-history {
  $history-nav-w-cif: 150px; //current年代の箇所で、右列の左余白と年代の右余白が同程度になる幅が150px
  $history-nav-w-pc: 189px; //270px * 0.7 = 189px
  $history-nav-w-nw: 270px;

  $history-imgbox-w-sp: 100%;
  $history-imgbox-w-pc: 48.7%; //380px / 780px * 100% = 48.717948717948718%
  $history-imgbox-w-nw: 380px;

  position: relative;
  
  ///// 年代 /////
  &_era {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    
    &:first-of-type {
      .c-history {
        &_body {
          padding-top: 5vw;

          @include breakpoint-pc {
            padding-top: 5vw;
          }
          @include breakpoint-narrowwrap {
            padding-top: 60px;
          }
        }
        
        &_nav {
          transform: translateY(0);
          padding-top: 20px;  //40px * 0.5 = 20px

          @include breakpoint-pc {
            transform: translateY(0);
            padding-top: 40px;
          }
        }
      }
    }
    
    &:last-of-type {
      .c-history {
        &_body {
          padding-bottom: 5vw;

          @include breakpoint-pc {
            padding-bottom: 5vw;
          }
          @include breakpoint-narrowwrap {
            padding-bottom: 60px;
          }
        }
      }
    }
  }

  ///// 左側ナビ /////
  &_nav {
    display: none;
    position: relative;
    z-index: 2;
    transform: translateY(-16px);
    box-sizing: border-box;

    @include breakpoint-contentinnerhalf {
      display: block;
      width: $history-nav-w-cif;
    }
    @include breakpoint-pc {
      transform: translateY(-20px);
      width: $history-nav-w-pc;
    }
    @include breakpoint-narrowwrap {
      width: $history-nav-w-nw;
    }
    
    &_inner {
      position: relative;
      
      &.-absolute {
        position: absolute;
        top: 20px !important;
        left: 0;
        width: 100%;
        height: 100%;

        @include breakpoint-pc {
          top: 40px !important;
        }
      }
    }
    
    &_item {
      position: relative;
      padding: 12px 0;  //15px * 0.8 = 12px
      box-sizing: border-box;
      color: color-wh(1);
      
      @include breakpoint-pc {
        padding: 15px 0;
      }

      &.-active {
        padding-left: 34px; //42px * 0.8 = 33.6
        box-sizing: border-box;
        color: color-wh(1);
        
        @include breakpoint-pc {
          padding-left: 42px;
        }

        &::before {
          display: block;
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
          width: 26px;  //32px * 0.8 = 25.6px
          height: 26px;
          content: "";
          background-image: url("../images/common/deco/figure_purple03.png");
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;

          @include breakpoint-pc {
            left: 4px;
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
  
  ///// 右側年表 /////
  &_body {
    position: relative;
    width: 100%;
    padding: 0 0 0 5vw;
    box-sizing: border-box;
    background-color: color-wh(1);

    @include breakpoint-contentinnerhalf {
      width: calc(100% - #{$history-nav-w-cif});
    }
    @include breakpoint-pc {
      width: calc(100% - #{$history-nav-w-pc});
      padding: 0 0 0 5vw;
    }
    @include breakpoint-narrowwrap {
      width: calc(100% - #{$history-nav-w-nw});
      padding: 0 0 0 60px;
    }
    
    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100vw;
      height: 100%;
      content: "";
      background-color: color-wh(1);
    }
    
    &_inner {
      position: relative;
      z-index: 1;
    }
    
    &_ttlbox {
      padding: 20px 10px 16px 10px;  //25px * 0.8 = 20px
      border-bottom: 1px solid color-blue02(1);
      box-sizing: border-box;

      @include breakpoint-pc {
        padding: 25px 10px 25px 10px;
      }

      &.js-slideBtn {
        pointer-events: auto;
        cursor: pointer;
        
        @include breakpoint-contentinnerhalf {
          pointer-events: none;
          cursor: text;
        }
      }
    }

    .js-slidePanel.no-active {
      display: none;

      @include breakpoint-contentinnerhalf {
        display: block;
      }
    }

    .js-slidePanel.is-active {
      display: block;

      @include breakpoint-contentinnerhalf {
        display: block;
      }
    }

    &_rowbox {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-row;
      @include flex-justify-space-between;
      @include flex-align-items-start;
      gap: 25px 0;
      padding: 24px 10px 24px 10px;  //30px * 0.8 = 24px
      border-bottom: 1px solid color-gray01(1);
      box-sizing: border-box;

      @include breakpoint-pc {
        @include flex-wrap-nowrap;
        gap: 0 6.4%;  //50px / 780px * 100% = 6.41025641025641%
        padding: 30px 10px 30px 10px;
      }
      @include breakpoint-narrowwrap {
        gap: 0 50px;
      }
      
      &:last-of-type {
        margin-bottom: 10px;
        border-bottom-style: none;

        @include breakpoint-pc {
          margin-bottom: 20px;
        }
      }
    }
    
    &_txtbox {
      width: 100%;
    }
    
    &_imgbox {
      @include flex-shrink-0;
      width: $history-imgbox-w-sp;
  
      @include breakpoint-pc {
        width: $history-imgbox-w-pc;
      }
      @include breakpoint-narrowwrap {
        width: $history-imgbox-w-nw;
      }
    }
    
    &_dt {
      margin-bottom: 6px;
      
      @include breakpoint-pc {
        margin-bottom: 12px;
      }
    }

    &_ttl {
      margin-bottom: 15px;
      
      @include breakpoint-pc {
        margin-bottom: 30px;
      }
    }
  }
}