.p0-btn1 {
  position: relative;
  width: 720px;
  max-width: 100%;
  box-sizing: border-box;
  @include font-spratq();
  font-size: 1.44rem; //2.4 * 0.6 = 1.44
  text-align: center;
  @include lineHeight(1.25, 1.25);
  @include resRadius(10px, $pc);

  @include breakpoint-sp {
    font-size: 1.68rem; //2.4 * 0.7 = 1.68
  }
  @include breakpoint-tb {
    font-size: 1.92rem; //2.4 * 0.8 = 1.92
  }
  @include breakpoint-pc {
    font-size: 2.4rem;
  }
  
  &.-type01 {
    color: color-black01(1);
    background-color: color-white02(1);
  }
  
  &_inner {
    display: block;
    position: relative;
    padding: 18px 12px 20px;  //22px * 0.8 = 17.6px、15px * 0.8 = 12px、25px * 0.8 = 20px
    box-sizing: border-box;

    @include breakpoint-pc {
      padding: 22px 15px 25px;
    }
    
    &:hover {
      .p0-btn1 {
        &_border {
          &::after {
            animation: 0.5s ease-in 0s 1 both slideRight;
          }
        }
      }
    }
  }
  
  &_txt {
    margin-bottom: 10px;  //12px * 0.8 = 9.6px
    
    @include breakpoint-pc {
      margin-bottom: 12px;
    }
  }
  
  &_border {
    display: block;
    position: relative;
    overflow: hidden;
    width: 248px; //412px * 0.6 = 247.2px
    max-width: 100%;
    height: 5px;
    margin-left: auto;
    margin-right: auto;
    @include color-gradation01("forward");
    
    @include breakpoint-sp {
      width: 288px; //412px * 0.7 = 288.4px
    }
    @include breakpoint-tb {
      width: 330px; //412px * 0.8 = 329.6px
    }
    @include breakpoint-pc {
      width: 412px;
    }

    &::after {
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      transform: translateX(-110%);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: "";
      background-color: color-white02(1);
    }
  }
}


.p0-btn2 {
  overflow: hidden;
  width: 220px;
  border: 1px solid color-blue02(1);
  box-sizing: border-box;
  border-radius: 100vh;
  @include font-spratq();
  @include fontSize(1.8, 1.44); //1.8 * 0.8 = 1.44
  @include lineHeight(1, 1);

  &_inner, &_btn {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 0 11px;  //14px * 0.8 = 12.2px
    width: 100%;
    height: 100%;
    padding: 12px 13px 14px; //16px * 0.8 = 12.8、15px * 0.8 = 12px、17px * 0.8 = 13.6px
    box-sizing: border-box;
    color: color-blue02(1);
    background-color: color-blue05(1);
    -webkit-transition: color .15s linear, background-color .15s linear;
    transition: color .15s linear, background-color .15s linear;

    @include breakpoint-pc {
      gap: 0 14px;
      padding: 15px 16px 17px;
    }
  
    //左余白を多く確保するため
    &::before {
      display: block;
      position: relative;
      width: 0;
      height: 10px;
      content: "";
      margin-right: -1px;
      pointer-events: none;
    }
    
    //右の三角
    &::after {
      display: block;
      position: relative;
      width: 10px;
      height: 10px;
      content: "";
      margin-top: 1px;
      background-image: url("../images/common/icon/tri_right_blue02.png");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
      -webkit-transition: background-image .15s linear;
      transition: background-image .15s linear;
    }
    
    &:hover {
      color: color-wh(1);
      background-color: color-blue02(1);
      -webkit-transition: color .15s linear, background-color .15s linear;
      transition: color .15s linear, background-color .15s linear;
        
      &::after {
        background-image: url("../images/common/icon/tri_right_wh.png");
        -webkit-transition: background-image .15s linear;
        transition: background-image .15s linear;
      }
    }
  }
  
  &_btn {
    
  }
}


///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;
  gap: 16px 16px;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    gap: 20px 20px;
  }
  
  &.-left {
    @include flex-justify-flex-start;
  }
  
  &.-center {
    @include flex-justify-center;
    @include flex-align-items-center;
    
    @include breakpoint-pc {
      @include flex-align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
  }
  
  &_item {
  }
}