.p2-copyright {
  @include font-critxt("sb");
  color: color-wh(1);
  @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
  text-align: center;
  line-height: 1;

  @include breakpoint-contentinnerhalf {
    text-align: right;
  }
}