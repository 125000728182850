//main content padding
$main-content-px-sp: 5vw;
$main-content-px-pc: 5vw;
$main-content-px-content: 60px;


//header nav menu
$header-height-sp: 56px;
$header-height-pc: 56px;  //70px * 0.8 = 56、偶数にするため、微調整して56px
$header-height-ni: 64px;  //70px * 0.9 = 63、偶数にするため、微調整して64px
$header-height-na: 70px;

//$header-contact-width-sp: 140px;
$header-contact-width-sp: 100%;
$header-contact-width-pc: 130px;
$header-contact-width-ni: 160px;
$header-contact-width-na: 200px;

$header-gnav-gap-x-sp: 10px;
$header-gnav-gap-x-pc: 15px;
$header-gnav-gap-x-ni: 20px;

$header-gnav-gap-y-sp: 10px;


//front page kv
$front-kv-height-sp: 120vh;
$front-kv-height-pc: 120vh;

//$front-kv-scene02-height-sp: 240vh;
//$front-kv-scene02-height-pc: 240vh;
$front-kv-scene02-height-sp: auto;
$front-kv-scene02-height-pc: auto;


//contact
$contact-form-th-w-sp: 100%;
$contact-form-th-w-cif: 150px;
$contact-form-th-w-pc: 220px;
$contact-form-th-w-na: 250px;
