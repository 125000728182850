.c-philosophy {
  $philosophy-deco-size-sp: 54px; //68px * 0.8 = 54.4px
  $philosophy-deco-size-pc: 68px;

  $philosophy-deco-adjust-sp: -4px; //5px * 0.8 = 4px
  $philosophy-deco-adjust-pc: -5px;
  
  $philosophy-ttl-gap-x-sp: 12px; //16px * 0.8 = 12.8px
  $philosophy-ttl-gap-x-pc: 16px;

  $philosophy-txt-mt-sp: 5vw; //
  $philosophy-txt-mt-pc: 4.838709677419355vw; //60px / 1240px * 100vw
  $philosophy-txt-mt-nw: 60px;
  
  $philosophy-imgsize-plus-sp: $main-content-px-sp;
  $philosophy-imgsize-plus-pc: $main-content-px-pc;
  $philosophy-imgsize-plus-nw: calc((100vw - #{$narrow}) / 2);
  $philosophy-imgsize-plus-nw-minus: calc((100vw - #{$narrow}) / 2 * -1);
  $philosophy-imgsize-plus-max: 250px;

  position: relative;
  margin-bottom: calc(60px + #{$philosophy-txt-mt-sp});

  @include breakpoint-pc {
    margin-bottom: calc(120px + #{$philosophy-txt-mt-pc})
  }
  @include breakpoint-narrowwrap {
    margin-bottom: calc(120px + #{$philosophy-txt-mt-nw})
  }
  
  &:last-of-type {
    margin-bottom: 0;
    
    @include breakpoint-pc {
      margin-bottom: 0;
    }
    @include breakpoint-max {
      margin-bottom: 0;
    }
  }

  &.-rev {
    .c-philosophy {
      &_ttl {
        @include flex-justify-flex-start;
        margin-right: auto;
        margin-left: -($philosophy-deco-size-sp / 2);

        @include breakpoint-pc {
          margin-right: auto;
          margin-left: -($philosophy-deco-size-pc / 2);
        }
        @include breakpoint-content {
          margin-right: auto;
        }

        h3 {    
          &::after {
            right: auto;
            left: -($philosophy-deco-size-sp) - ($philosophy-ttl-gap-x-sp) - (2px);
            transform: translate(-100%, -50%);
      
            @include breakpoint-pc {
              left: -($philosophy-deco-size-pc) - ($philosophy-ttl-gap-x-pc) - (3px);
            }
          }
        }
      }

      &_body {
        @include breakpoint-pc {
          @include flex-row-reverse;
        }

        &::before {
          left: auto;
          right: -($philosophy-imgsize-plus-sp);
          transform: translateX(-($main-content-px-sp));
    
          @include breakpoint-pc {
            right: -($philosophy-imgsize-plus-pc);
            transform: translateX(-($main-content-px-pc));
          }
          @include breakpoint-content {
            transform: translateX(-($main-content-px-content));
          }
          @include breakpoint-narrowwrap {
            right: $philosophy-imgsize-plus-nw-minus;
          }
          @include breakpoint-max {
            right: -($philosophy-imgsize-plus-max);
          }
        }
      }

      &_txt {
        padding-left: 0;
        padding-right: 5vw;

        @include breakpoint-pc {
          padding-left: 0;
          padding-right: 0;
        }
      }

      &_img {
        @include flex-justify-flex-start;
      }
    }
  }

  &_head {
    margin-bottom: 30px;

    @include breakpoint-pc {
      margin-bottom: 60px;
    }
  }
  
  &_body {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include flex-align-items-start;
    position: relative;
    
    &::before {
      display: block;
      position: absolute;
      top: calc(#{$philosophy-txt-mt-sp} + 0.806451612903226vw); //10px / 1240px * 100vw
      left: -($philosophy-imgsize-plus-sp);
      transform: translateX($main-content-px-sp);
      z-index: 0;
      width: 100vw;
      height: calc(100% - 0.806451612903226vw); //10px / 1240px * 100vw
      content: "";
      background-color: color-wh(1);

      @include breakpoint-pc {
        top: calc(#{$philosophy-txt-mt-pc} + 0.806451612903226vw); //10px / 1240px * 100vw
        left: -($philosophy-imgsize-plus-pc);
        transform: translateX($main-content-px-pc);
        width: 100vw;
        height: calc(100% - 0.806451612903226vw); //10px / 1240px * 100vw
      }
      @include breakpoint-content {
        transform: translateX($main-content-px-content);
        width: 100vw;
      }
      @include breakpoint-narrowwrap {
        top: calc(#{$philosophy-txt-mt-nw} + 10px);
        left: $philosophy-imgsize-plus-nw-minus;
        height: calc(100% - 10px);
      }
      @include breakpoint-max {
        left: -($philosophy-imgsize-plus-max);
      }
    }
  }

  &_ttl {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-end;
    @include flex-align-items-start;
    gap: 0 $philosophy-ttl-gap-x-sp;
    position: relative;
    z-index: 1;
    margin-right: -($main-content-px-sp / 2);
    padding: 0 $main-content-px-sp;
    box-sizing: border-box;

    @include breakpoint-pc {
      gap: 0 $philosophy-ttl-gap-x-pc;
      margin-right: -($main-content-px-pc / 2);
      padding: 0 $main-content-px-pc;
    }
    @include breakpoint-content {
      margin-right: -($main-content-px-content / 2);
      padding: 0 $main-content-px-content;
    }

    &::before {
      display: block;
      position: relative;
      transform: translateY($philosophy-deco-adjust-sp);
      width: $philosophy-deco-size-sp;
      height: $philosophy-deco-size-sp;
      content: "";
      background-image: url("../images/common/deco/figure_purple03.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      @include breakpoint-pc {
        transform: translateY($philosophy-deco-adjust-pc);
        width: $philosophy-deco-size-pc;
        height: $philosophy-deco-size-pc;
      }
    }
    
    h3 {
      position: relative;

      &::after {
        display: block;
        position: absolute;
        top: ($philosophy-deco-size-sp / 2) + ($philosophy-deco-adjust-pc / 2);
        right: -1.25vw;
        transform: translate(100%, -50%);
        width: 100vw;
        height: 1px;
        content: "";
        background-color: color-purple02(1);
  
        @include breakpoint-pc {
          top: ($philosophy-deco-size-pc / 2) + ($philosophy-deco-adjust-pc / 2);
        }
        @include breakpoint-contentinner {
          right: -15px;
        }
      }
    }
  }
  
  &_txt {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: $philosophy-txt-mt-sp;
    padding-top: 0;
    padding-left: 5vw;
    box-sizing: border-box;

    @include breakpoint-pc {
      width: calc(500 / 1120 * 100%); //500px / 1120px * 100%
      margin-top: $philosophy-txt-mt-pc;
      padding-top: 4.62962962962963vw;  //75px / 1620px * 100vw
      padding-left: 0;
    }
    @include breakpoint-max {
      margin-top: $philosophy-txt-mt-nw;
      padding-top: 75px;
    }
  }

  &_img {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-end
    @include flex-align-items-stretch;
    position: relative;
    z-index: 1;
    width: 100%;

    @include breakpoint-pc {
      width: calc(560 / 1120 * 100%); //560px / 1120px * 100%
    }
    
    img {
      width: calc(100% + #{$philosophy-imgsize-plus-sp});
      height: auto;

      @include breakpoint-pc {
        width: calc(100% + #{$philosophy-imgsize-plus-pc});
      }
      @include breakpoint-narrowwrap {
        width: calc(100% + #{$philosophy-imgsize-plus-nw});
      }
      @include breakpoint-max {
        width: calc(100% + #{$philosophy-imgsize-plus-max});
      }
    }
  }
}