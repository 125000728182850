///// トップページのみで使用するCSS /////
.p0-front {
  ///// scene03 /////
  &_scene03 {
    padding-top: 60px;
    padding-bottom: 60px;

    @include breakpoint-pc {
      padding-top: 120px + 320px;
      padding-bottom: 120px;
    }
  }
  
  ///// 事業内容 /////
  // 事業内容 - サービス
  &_about {
    position: relative;

    &_service {
      position: relative;
      padding-bottom: 7.258064516129032vw; //90px / 1240px * 100vw
      box-sizing: border-box;
      
      @include breakpoint-narrowwrap {
        padding-bottom: 90px;
      }
      
      &::before {
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: calc(100% - 24.166666666666667vw);  //(290px / 2) / 600px * 100vw
        content: "";
        background-color: color-blue02(1);

        @include breakpoint-contentinnerhalf {
          height: calc(100% - 11.290322580645161vw);  //(280px / 2) / 1240px * 100vw
        }
        @include breakpoint-narrowwrap {
          height: calc(100% - 140px);
        }
        @include breakpoint-narrowdoublewrap {
          width: calc(100vw - #{$main-content-px-content} - #{$main-content-px-content}); //左右余白をあける
        }
      }
    }
  }
  
  ///// 私たちの想い /////
  &_philosophy {
    margin-top: -1px; //luxy.jsの影響で、スクロール時に前sectionとの境目がチラチラ見えるのを防止するため、上に1pxずらす
  }


  ///// お取引実績、製品開発「つえがさの特徴」 /////
  &_businessrecord, &_tgFeatures {
    $frontBizrecPb-sp: calc(62.5vw - 30px); //300px / 480px * 100vw、-30pxは、紺帯と重なる高さ
    $frontBizrecPb-tb: 40px; //40px + 227px
    $frontBizrecPb-pc: 40px;

    $frontBizrecAdjustY-tb: 227px; //40px + 227px
    $frontBizrecAdjustY-pc: 40px;

    $frontBizrecBox-w-pc: 69%;
    
    padding-bottom: $frontBizrecPb-sp;
    box-sizing: border-box;

    @include breakpoint-tb {
      padding-bottom: $frontBizrecPb-tb + $frontBizrecAdjustY-tb;
    }
    @include breakpoint-pc {
      padding-bottom: $frontBizrecPb-pc + $frontBizrecAdjustY-pc;
    }
    
    &_box {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      
      @include breakpoint-tb {
        transform: translateY(#{$frontBizrecPb-tb});
      }
      @include breakpoint-pc {
        transform: translateY(#{$frontBizrecPb-pc});
        width: $frontBizrecBox-w-pc;
      }
      @include breakpoint-narrow {
      }

      &::before {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
        width: 100vw;
        height: 100%;
        content: "";
        background-color: color-blue01(1);
      }
    }
    
    &_boxTtl {
      position: absolute;
      top: 0;
      z-index: 1;
      transform: translate(0, -85%);
      @include font-rbtsrf("r");
      font-size: 1.9rem;
      @include lineHeight(1, 1);
      
      @include breakpoint-tb {
        transform: translate(0, -50%);
        font-size: 2.4rem;
      }
      @include breakpoint-pc {
        font-size: 3rem;
      }

      &.-left {
        left: 0;
      }
      &.-right {
        right: 0;
      }
    }
    
    &_boxTxt {
      position: relative;
      z-index: 1;
    }
    
    &_img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% + 5vw);

      @include breakpoint-tb {
        max-width: 400px;
      }
      @include breakpoint-pc {
        position: relative;
        bottom: auto;
        right: auto;
        width: calc(100% - #{$frontBizrecBox-w-pc});
      }
      
      img {
        transform: translate(5vw, calc(100% - 30px));
        height: auto;

        @include breakpoint-tb {
          transform: translate(5vw, 100%);
        }
        @include breakpoint-pc {
          transform: translate(-8.5%, calc(#{$frontBizrecPb-pc} + #{$frontBizrecAdjustY-pc}));  //30px / 310px * 100% = 9.67741935483871%、9.6〜だと右に余白が出てしまうので、余白のでない8.5%に調整
          width: 129.032258064516129%;  //400px / 310px * 100%
        }
      }
    }
  }

  &_businessrecord {
    &_bg {
      width: 100%;
      height: 100%;
      padding-top: 45px;
      box-sizing: border-box;
      background-image: url("../images/front/businessrecord/businessrecord_bg.jpg");
      background-position: 61.25% top;
      background-size: cover;
      background-repeat: no-repeat;

      @include breakpoint-sp {
        background-position: 60% top;
      }
      @include breakpoint-tb {
        background-position: 57.5% top;
      }
      @include breakpoint-contentinnerhalf {
        background-position: 52.5% top;
      }
      @include breakpoint-w720 {
        background-position: 50% top;
      }
      @include breakpoint-pc {
        padding-top: 90px;
        background-position: 52.5% top;
      }
      @include breakpoint-narrowinner {
        background-position: 50% top;
      }
      @include breakpoint-narrow {
        background-position: 50% top;
      }
      @include breakpoint-contentinner {
        background-position: center top;
      }
      @include breakpoint-max {
        background-size: auto 100%;
        background-repeat: repeat-x;
      }
    }
    
    &_head {
      margin-bottom: 50px;

      @include breakpoint-tb {
        margin-bottom: 30px;
      }
      @include breakpoint-pc {
        margin-bottom: 30px;
      }

      p {
        padding: 4px 0;
        box-sizing: border-box;
      }
    }

    &_box {
      padding: calc(5vw - 7px) 5vw calc(5vw + 30px - 7px) 0;  //13.8px(本文フォントサイズ) * (2.1(line-height) - 1(文字自体の高さ)) / 2(上余白だけ・下余白だけ) = 7.59px
      
      @include breakpoint-tb {
        padding: calc(5vw + 40px - 7px) 5vw calc(5vw + 40px - 7px) 0;
      }
      @include breakpoint-pc {
        padding: 70px 8% 70px 0;  //80px / 1000px * 100% = 7.142857142857143%
      }
      @include breakpoint-narrow {
        padding: 70px 80px 70px 0;
      }
    }
  }

  &_tgFeatures {
    &_ttl {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      
      @include breakpoint-pc {
        left: 0;
      }
      @include breakpoint-narrow {
        left: -0.806451612903226vw;  //10px / 1240px * 100vw
      }
      @include breakpoint-narrowwrap {
        left: -40px;
      }

      &_inner {
        position: relative;
        transform: translate(0, calc(-100% - 30px));

        @include breakpoint-pc {
          transform: translate(0, -37%);  //60px / 160px * 100% = 37.5%、微調整で+0.5%
        }
      }
    }
    
    &_box {
      padding: calc(5vw - 7px) 5vw calc(5vw + 30px - 7px) 0;  //13.8px(本文フォントサイズ) * (2.1(line-height) - 1(文字自体の高さ)) / 2(上余白だけ・下余白だけ) = 7.59px
      background-color: color-blue01(1);
      
      @include breakpoint-tb {
        padding: calc(5vw + 40px - 7px) 5vw calc(5vw + 40px - 7px) 0;
      }
      @include breakpoint-pc {
        padding: 70px 8% 110px 70px;  //80px / 1000px * 100% = 7.142857142857143%
      }
      @include breakpoint-narrow {
        padding: 70px 80px 110px 70px;
      }
      
      &::after {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
        z-index: -1;
        width: 100vw;
        height: 80px;
        content: "";
        background-color: color-white01(1);
      }
    }
    
    &_boxTtl {
      @include breakpoint-pc {
        padding-left: 70px;
      }
    }
  }

  
  ///// ごあいさつ /////
  &_greetingsTtl {
    left: 0;
    margin-bottom: 30px;

    @include breakpoint-pc {
      left: 11.6%;  //130px / 1120px * 100% = 11.607142857142857%
      margin-bottom: 120px;
    }
    @include breakpoint-narrowwrap {
      left: 130px;
    }
    
    .p3-headline {
      @include breakpoint-pc {
        height: calc(4em + 44px + 20px); //safariでの表示崩れ防止のため、縦書き時は高さを(4em(4文字分) ＋ 44px(装飾画像の高さ) ＋ 20px(装飾画像とテキストの間余白)とする
      }
    }
    
    .p0-decoration {
      &.-umbrella03 {
        transform: translateY(5px);
        margin-right: 16px;  //20px * 0.8 = 16px
        margin-bottom: 0;
        
        @include breakpoint-pc {
          transform: translateY(0);
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  
  ///// 沿革 /////
  &_history {
    background-image: url("../images/front/history/history_bg.jpg");
    background-position: center top;
    background-size: 100% auto;
    background-repeat: no-repeat;

    @include breakpoint-max {
//      background-size: $max auto; //背景画像の左右がグラデーション処理される場合は、この行を有効にする
    }
  }
  
  
  ///// 製品開発 - 01 /////
  &_original {
    //見出しの下の文章(画像を配置する分の余白を確保する)
    &_headTxt {
      padding-top: 0;
      box-sizing: border-box;

      @include breakpoint-pc {
        padding-top: 11.693548387096774vw; //145px / 1240px * 100vw
        padding-left: 2.016129032258065vw; //25px / 1240px * 100vw
      }
      @include breakpoint-narrowwrap {
        padding-top: 145px;
        padding-left: 25px;
      }
    }
  }

  
  ///// 製品開発 - 02、03 /////
  &_original {
    &_featuresTtl {
      position: absolute;
      top: 0;
      transform: translate(0, -50%);
      
      &.-left {
        left: 0;
      }
      &.-right {
        right: 0;
      }
    }

    &_gridbox02, &_gridbox03 {
      position: relative;
      width: 84%; //840px / 1000px * 100%
      margin-top: -12.5vw; //150px / 1200px * 100vw

      @include breakpoint-pc {
        margin-top: -12.5vw; //150px / 1200px * 100vw
      }
      @include breakpoint-contentinner {
        margin-top: -150px;
      }
      
      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        width: calc(100% + 8px + 8px);
        height: calc(100% + 8px + 8px);
        content: "";
        background-color: color-white01(1);
        
        @include breakpoint-pc {
          width: calc(100% + 10px + 10px);
          height: calc(100% + 10px + 10px);
        }
      }
    }

    &_gridbox02 {
      margin-left: auto;
      margin-right: -5vw;

      @include breakpoint-pc {
        margin-right: -5vw;
      }
      @include breakpoint-contentinner {
        margin-right: -60px;
      }
    }

    &_gridbox03 {
      margin-left: -5vw;
      margin-right: auto;

      @include breakpoint-pc {
        margin-left: -5vw;
      }
      @include breakpoint-contentinner {
        margin-left: -60px;
      }
    }
  }
}