.p0-decoration {
  display: block;

  & img {
    display: block;
  }
  
  ///// 二本線 /////
  &.-border2 {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3px;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    
    &.-purple03_043 {
      border-top-color: color-purple03(0.43);
      border-bottom-color: color-purple03(0.43);
    }
    
    &.-w56 {
      width: 56px;
    }
  }



  ///// マーク装飾(お取引実績、つえがさの特徴の単一マーク) /////
  &.-figureBizrec01, &.-figureTgFeatures01 {
    position: absolute;
    bottom: -45vw;
    left: -7.5vw;
    transform: translate(0, 100%);
    width: 22vw; //176px / 800px * 100vw
    opacity: 0.24;
    
    @include breakpoint-tb {
      bottom: -22.5vw;
      left: -2.5vw;
      transform: translate(0, 50%);
    }
    @include breakpoint-contentinnerhalf {
      bottom: -110px;
      left: 7%;
    }
    @include breakpoint-w720 {
      left: 14.5%; //100px / 680px * 100%
    }
    @include breakpoint-pc {
      bottom: auto;
      top: 50%;
      left: -40px;
      width: 176px;
      transform: translate(-100%, -50%);
    }
    @include breakpoint-narrowwrap {
      bottom: auto;
      top: 50%;
      left: -40px;
      width: 176px;
      transform: translate(-100%, -50%);
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  ///// マーク装飾(お取引実績、つえがさの特徴の2個セットマーク) /////
  &.-figureBizrec02, &.-figureTgFeatures02 {
    position: absolute;
    top: 0;
    right: 14vw;
    transform: translate(100%, -62%); //40px / 129px * 100% = 31.0077519379845% * 2
    width: 17.375vw; //139px / 800px * 100vw
    
    @include breakpoint-tb {
      right: 14.5vw;
      transform: translate(100%, -31%); //40px / 129px * 100% = 31.0077519379845%
    }
    @include breakpoint-pc {
      right: 4.838709677419355vw;  //60px / 1240px * 100vw
      width: 139px;
    }
    @include breakpoint-narrowwrap {
      right: 60px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  ///// マーク装飾(会社概要) /////
  &.-figureCompany {
    width: 101px;
    
    img {
      width: 100%;
      height: auto;
    }
  }


  ///// マーク装飾(沿革) /////
  &.-figureHistory {
    display: none;
    position: absolute;
    top: 40px;  //50px * 0.8 = 40px
    right: 0;
    z-index: 1;
    transform: translate(50%, 0);
    width: 292px;  //366px * 0.8 = 292.8px
    opacity: 0.15;
    
    @include breakpoint-contentinnerhalf {
      display: block;
    }
    @include breakpoint-pc {
      top: 50px;
      width: 366px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  ///// マーク装飾(製品開発 - 02・03の単一マーク) /////
  &.-figureOriginal02_01, &.-figureOriginal03_01 {
    position: absolute;
    top: calc(100% + 20vw);  //160px / 800px * 100vw
    width: 22vw; //176px / 800px * 100vw
    opacity: 0.24;
    
    @include breakpoint-pc {
      top: 50%;
      width: 176px;
    }
    @include breakpoint-narrowwrap {
      width: 176px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  &.-figureOriginal02_01 {
    left: 0;
    transform: translate(-3.75vw, -50%);
    
    @include breakpoint-pc {
      left: -40px;
      transform: translate(-100%, -50%);
    }
    @include breakpoint-narrowwrap {
      left: -40px;
    }
  }
  &.-figureOriginal03_01 {
    right: 0;
    transform: translate(3.75vw, -50%);
    
    @include breakpoint-pc {
      right: 20px;
      transform: translate(100%, -50%);
    }
    @include breakpoint-narrowwrap {
      right: 20px;
    }
  }


  ///// マーク装飾(製品開発 - 02・03の2個セットマーク) /////
  &.-figureOriginal02_02, &.-figureOriginal03_02 {
    position: absolute;
    top: 0;
    width: 17.375vw; //139px / 800px * 100vw
    
    @include breakpoint-pc {
      width: 139px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  &.-figureOriginal02_02 {
    right: 0;
    transform: translate(25%, -44%);

    @include breakpoint-w720 {
      transform: translate(20%, -31%); //40px / 129px * 100% = 31.0077519379845%
    }
    @include breakpoint-pc {
      transform: translate(33%, -31%); //40px / 129px * 100% = 31.0077519379845%
    }
    @include breakpoint-contentinner {
      transform: translate(50%, -31%); //40px / 129px * 100% = 31.0077519379845%
    }
    @include breakpoint-content {
      transform: translate(100%, -31%); //40px / 129px * 100% = 31.0077519379845%
    }
  }
  &.-figureOriginal03_02 {
    left: 0;
    transform: translate(-25%, -44%) scaleX(-1); //scaleX(-1)で左右反転

    @include breakpoint-w720 {
      transform: translate(-20%, -31%) scaleX(-1); //40px / 129px * 100% = 31.0077519379845%、scaleX(-1)で左右反転
    }
    @include breakpoint-pc {
      transform: translate(-33%, -31%) scaleX(-1); //40px / 129px * 100% = 31.0077519379845%、scaleX(-1)で左右反転
    }
    @include breakpoint-contentinner {
      transform: translate(-50%, -31%) scaleX(-1); //40px / 129px * 100% = 31.0077519379845%、scaleX(-1)で左右反転
    }
    @include breakpoint-content {
      transform: translate(-100%, -31%) scaleX(-1); //40px / 129px * 100% = 31.0077519379845%、scaleX(-1)で左右反転
    }
  }
  
  ///// マーク装飾(お問い合わせフォーム下の単一マーク) /////
  &.-figureContact {
    position: relative;
    width: 81px; //101px * 0.8 = 80.8

    @include breakpoint-pc {
      width: 101px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  ///// 縦傘画像 /////
  &.-umbrella01 {
    display: block;
    width: 6px;  //7px * 0.8 = 5.6px
    height: 36px;  //44px * 0.8 = 35.2px
    
    @include breakpoint-pc {
      width: 7px;
      height: 44px;
    }

    img {
      width: 100%;
      height: auto;
    }
    
    //沿革
    &.-history {
      transform: translateY(4px); //5px * 0.8 = 4px
      margin-right: 3px;
      
      @include breakpoint-pc {
        transform: translateY(5px);
        margin-right: 4px;
      }
    }
    
    //お問い合わせサブタイトル、プライバシーポリシーサブタイトル
    &.-contactSubttl, &.-privacySubttl {
      transform: translateY(2px); //5px * 0.8 = 4px
      width: 4px;  //7px * 0.5 = 3.5px
      height: 22px;  //44px * 0.5 = 22px
      margin-left: 5px;
      margin-right: 0;
      
      @include breakpoint-tb {
        transform: translateY(3px);
        width: 6px;  //7px * 0.75 = 5.25px
        height: 33px;  //44px * 0.75 = 33px
        margin-left: 0;
        margin-right: 3px;
      }
      @include breakpoint-pc {
        transform: translateY(5px);
        width: 7px;
        height: 44px;
        margin-right: 4px;
      }
    }
  }


  ///// 横傘画像 /////
  &.-umbrella02 {
    display: block;
    width: 46px;  //57px * 0.8 = 45.6px
    height: 8px;  //9px * 0.8 = 7.2px
    
    @include breakpoint-pc {
      width: 57px;
      height: 9px;
    }
    
    //お取引実績
    &.-bizrec {
      display: inline-block;
      margin-right: 14px;

      @include breakpoint-pc {
        margin-right: 16px;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  ///// 斜め傘画像 /////
  &.-umbrella03 {
    display: inline-block;
    width: 32px;  //40px * 0.8 = 32px
    height: 36px;  //44px * 0.8 = 35.2px
    margin-bottom: 16px;  //20px * 0.8 = 16px
    
    @include breakpoint-pc {
      width: 40px;
      height: 44px;
      margin-bottom: 20px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}