.p0-separatebox {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;
  position: relative;
  width: 100%;

  &_item {
    position: relative;
    width: 100%;

    @include breakpoint-tb {
      width: 50%;
    }
  }
  
  ///// トップページ - 事業内容 /////
  &.-frontAbout {
    $separatebox-gap-x-cif: 5vw;
    $separatebox-gap-x-pc: 6.451612903225806vw;  //80px / 1240px * 100vw
    $separatebox-gap-x-nw: 80px;
    $separatebox-gap-y-sp: 7.5vw;

    gap: $separatebox-gap-y-sp 0;
    z-index: 2;
    width: 100%;
    max-width: $narrow;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-contentinnerhalf {
      @include flex-wrap-nowrap;
      gap: 0 $separatebox-gap-x-cif;
    }
    @include breakpoint-pc {
      gap: 0 $separatebox-gap-x-pc;
    }
    @include breakpoint-narrowwrap {
      gap: 0 $separatebox-gap-x-nw;
    }

    .p0-separatebox {
      &_item {
        display: block;
        width: 100%;
        box-sizing: border-box;
  
        @include breakpoint-contentinnerhalf {
          width: calc( (100% / 2) - (#{$separatebox-gap-x-cif} / 2 * 1) );
        }
        @include breakpoint-pc {
          width: calc( (100% / 2) - (#{$separatebox-gap-x-pc} / 2 * 1) );
        }
        @include breakpoint-narrowwrap {
          width: calc( (100% / 2) - (#{$separatebox-gap-x-nw} / 2 * 1) );
        }
      }
      
      &_img {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-center;
        @include flex-align-items-center;
        position: relative;
        width: 100%;
        
        img {
          width: 100%;
          height: auto;
        }
      }
      
      &_band {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        padding: 10px 10px;
        box-sizing: border-box;
        background-image: url("../images/front/service/service_band_bg.png");
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        @include breakpoint-pc {
          padding: 20px 20px;
        }
      }
    }
  }


  ///// トップページ - ラインナップ＆製品開発 /////
  &.-frontProduct {
    $separatebox-gap-x-pc: 0px;
    $separatebox-gap-y-sp: 20px;
    
    $productbox-w-sp: 100%;
    $productbox-w-pc: 75.4%; //845px / 1120px * 100% = 75.446428571428571%

    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-start;
//    @include flex-align-items-stretch;
    gap: $separatebox-gap-y-sp 0;
    
    @include breakpoint-pc {
      gap: 0 $separatebox-gap-x-pc;
    }

    &.-rev {
      @include flex-row-reverse;
    }
  
    .p0-separatebox {
      &_item {
        width: 100%;
        box-sizing: border-box;
    
        @include breakpoint-pc {
          width: 50%;
        }
        
        &.-ttlbox {
          width: 100%;
  
          @include breakpoint-pc {
            width: calc(100% - #{$separatebox-gap-x-pc} - #{$productbox-w-pc});
            padding: 0 20px;
          }
        }
        &.-productbox {
          width: 100%;
  
          @include breakpoint-pc {
            width: $productbox-w-pc;
            padding-top: 60px;
          }
        }
      }
    }
  }

}