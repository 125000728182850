// svg size settings
@mixin svgSize($svg_size_w, $svg_size_h) {
  width: $svg_size_w + px;
  height: $svg_size_h + px;
}


.icon-mail {
  @include flexbox;
  @include flex-justify-flex-start;
  @include flex-align-items-center;

  &:before {
    display: inline-block;
    width: 19px;
    height: 13px;
    content: "";
    margin-right: 10px;
    background-image: url("../images/common/mail.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

/*
@mixin icon-spot($status) {
  @if $status == 'maplink' {
    fill: color-wh(1);
    @include svgSize (12,16);
  } @else if $status == '' {
    
  }
}
*/


/*
// 外部リンクicon svg
@mixin icon-external() {
  fill: color-effect01(1);
  @include svgSize (14,14);
}
.externalIcon, %externalIcon {
  padding: 0 5px;
  @include icon-external();
}
*/
