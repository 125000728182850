.p1-drawer {
//  display: block;
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 105vh;
  overflow: scroll;
//  overflow: hidden;
  transform: translateY(-105vh);
  padding: ($header-height-sp + 24px) 5vw 30px;
  box-sizing: border-box;
  background-color: transparent;

  @media all and (max-height: 420px) {
    padding: ($header-height-sp + 12px) 5vw 15px;
  }
  @include breakpoint-pc {
    @include flex-direction-row;
    @include flex-justify-space-between;
    position: relative;
    height: $header-height-pc;
    overflow: hidden;
    transform: translateY(0) !important;
    padding: 0;
  }
  @include breakpoint-narrowinner {
    height: $header-height-ni;
  }
  @include breakpoint-narrow {
    height: $header-height-na;
  }
  @include breakpoint-content {
  }
  
  &.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn;
  }
  
  &.is-close {
    animation: 0.4s ease-in 0s 1 both drawerOut;
  }

  &-open {/* ハンバーガーアイコンの設置スペース */
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-center;
    position: fixed;
    top: 10px;
    right: 5vw;
    z-index: 1200;/* 重なり順を一番上に */
    height: 40px;
    width: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    mix-blend-mode: exclusion;

    @include breakpoint-pc {
      display: none;
    }

    /* ハンバーガーメニューのアイコン */
    & span, & span:before, & span:after {
      display: block;
      position: absolute;
      height: 3px;
      width: 25px;
      content: '';
      border-radius: 3px;
      background-color: color-yellow01(1);
      transition: 0.5s;
    }

    & span {
      &::before {
        bottom: 8px;
        transform-origin: top left;
      }
      &::after {
        top: 8px;
        transform-origin: bottom left;
      }
    }
    
    //オープン時(×ボタン)
    &.is-open {
      span {
        background-color: color-yellow01(0);
      }
      
      span::before {
        transform: translate(5px, 0) rotate(45deg);
        background-color: color-yellow01(1);
      }
      span::after {
        transform: translate(5px, 1px) rotate(-45deg);
        background-color: color-yellow01(1);
      }
    }
  }
}

