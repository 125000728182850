.p0-position {
  &.-static {
    position: static;
  }
  
  &.-relative {
    position: relative;
  }
  
  &.-absolute {
    position: absolute;
  }
  
  &.-fixed {
    position: fixed;
  }
  
  .-zim {
    z-index: -1;
  }

  @for $value from 0 through 10 {
    &.-zi {
      &#{ $value } {
        z-index: $value;
      }
    }
  }
  
  &.-hidden {
    overflow: hidden;
  }
}