//// フェードイン・アウト /////
//js制御のフェードイン・アウト　
.e-fadeIn1 {
  animation: 0.3s ease-in 0s 1 both fadeIn !important;
}
.e-fadeOut1 {
  animation: 0.3s ease-in 0s 1 both fadeOut !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none;
  }
  99% {
    opacity: 1;
    pointer-events: none;
  }
  100% {
    opacity: 1;
    pointer-events: auto;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    pointer-events: auto;
  }
  1% {
    opacity: 1;
    pointer-events: none;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}


//// ドロワーイン /////
@keyframes drawerIn {
  0% {
    transform: translateY(-105vh);
    background: color-blue02(0);
    opacity: 0;
  }
  1% {
    transform: translateY(0);
    background: color-blue02(0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    background: color-blue02(0.9);
    opacity: 1;
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: color-blue02(0.9);
    opacity: 1;
  }
  99% {
    transform: translateY(0);
    background: color-blue02(0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(-105vh);
    background: color-blue02(0);
    opacity: 0;
  }
}


// 右へスライド
@keyframes slideRight {
  0% {
    transform: translateX(-110%);
  }
  1% {
    transform: translateX(0%);
  }
  20% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(110%);
  }
}


//ページロード時のKV内アニメーション
//KV内コピーのフェードイン
$kvCatchcopyLoadDelay: 1s;
$kvCatchcopyLoadDuration: 0.5s;

.e-kvCatchcopyLoad {
  animation: $kvCatchcopyLoadDuration ease-in-out $kvCatchcopyLoadDelay 1 both fadeIn !important;
}


//KV内ロゴアニメーション
$kvLogoLoadDelay: $kvCatchcopyLoadDelay;
$kvLogoLoadDuration: $kvCatchcopyLoadDuration;

$kvLogoChangeImg1Stop: 1.5s;

$kvLogoChangeRectDelay: $kvLogoLoadDelay + $kvLogoLoadDuration + $kvLogoChangeImg1Stop;
$kvLogoChangeRectDuration: 1s;

$kvLogoChangeImg1Delay: $kvLogoLoadDelay + $kvLogoLoadDuration + $kvLogoChangeImg1Stop + ($kvLogoChangeRectDuration / 10 * 3.33333);
$kvLogoChangeImg1Duration: 0.000001s;

$kvLogoChangeImg2Delay: $kvLogoChangeImg1Delay + ($kvLogoChangeRectDuration / 10 * 3.333333);
$kvLogoChangeImg2Duration: 0.000001s;


.e-kvLogoLoad {
  position: relative;
  overflow: hidden;
  animation: $kvLogoLoadDuration ease-in-out $kvLogoLoadDelay 1 both fadeIn !important;
  opacity: 0;

  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    content: "";
    background-color: color-wh(1);
    animation: $kvLogoChangeRectDuration cubic-bezier(0.22, 0.61, 0.36, 1) $kvLogoChangeRectDelay 1 both kvLogoChangeRect;
  }
  
  .e-kvLogoChangeImg1 {
    opacity: 0;
    animation: $kvLogoChangeImg1Duration linear $kvLogoChangeImg1Delay 1 both kvLogoChangeImg1;
//    animation: $kvLogoChangeImg1Duration step-start $kvLogoChangeImg1Delay 1 both kvLogoChangeImg1; //step-startの場合はsafariで問題が発生するので、linearで極短時間の秒数を指定して切り替える形に変更した
  }
  .e-kvLogoChangeImg2 {
    opacity: 0;
    animation: $kvLogoChangeImg2Duration linear $kvLogoChangeImg2Delay 1 both kvLogoChangeImg2;
//    animation: $kvLogoChangeImg2Duration step-start $kvLogoChangeImg2Delay 1 both kvLogoChangeImg2; //step-startの場合はsafariで問題が発生するので、linearで極短時間の秒数を指定して切り替える形に変更した
  }
  
  /* 0.00s：全体非表示
     1.00s：全体フェードイン開始
     1.50s：全体フェードイン完了
     3.00s：rectのxは-100%
     3.24s：rectのxは0%
     3.96s：rectのxは0%
     4.20s：rectのxは100%
  */
}

@keyframes kvLogoChangeImg1 {
  0% {
    opacity : 1;
  }
  100% {
    opacity : 0;
  }
}

@keyframes kvLogoChangeRect {
  0% {
    transform: translateX(-100%);
  }
  36% {
    transform: translateX(0);
  }
  64% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes kvLogoChangeImg2 {
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
}


//コンテンツ内汎用ロゴアニメーション
.js-animeLogoEn {
  opacity: 0;
}

$animeLogoLoadDelay: 0s;  //kv以外のanimeLogoは、フェードインしないため、ディレイは0sにする
$animeLogoLoadDuration: 0s;  //kv以外のanimeLogoは、フェードインしないため、フェードインにかかる時間を0sにする

$animeLogoChangeImg1Stop: 1.5s;

$animeLogoChangeRectDelay: $animeLogoLoadDelay + $animeLogoLoadDuration + $animeLogoChangeImg1Stop;
$animeLogoChangeRectDuration: 1s;

$animeLogoChangeImg1Delay: $animeLogoLoadDelay + $animeLogoLoadDuration + $animeLogoChangeImg1Stop + ($animeLogoChangeRectDuration / 10 * 2);
$animeLogoChangeImg1Duration: 0.1s;

$animeLogoChangeImg2Delay: $animeLogoChangeImg1Delay + ($animeLogoChangeRectDuration / 10 * 2);
$animeLogoChangeImg2Duration: 0.1s;


.e-animeLogoChange {
  position: relative;
  overflow: hidden;
  
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: color-blue02(1);
    animation: $animeLogoChangeRectDuration cubic-bezier(0.22, 0.61, 0.36, 1) $animeLogoChangeRectDelay 1 both animeLogoChangeRect;
  }
  
  .e-animeLogoChangeImg1 {
    animation: $animeLogoChangeImg1Duration ease-in $animeLogoChangeImg1Delay 1 both animeLogoChangeImg1;
  }
  .e-animeLogoChangeImg2 {
    animation: $animeLogoChangeImg2Duration ease-in $animeLogoChangeImg2Delay 1 both animeLogoChangeImg2;
  }
  
  /* 0.00s：全体非表示
     1.00s：全体フェードイン開始
     1.50s：全体フェードイン完了
     3.00s：rectのxは-100%
     3.24s：rectのxは0%
     3.96s：rectのxは0%
     4.20s：rectのxは100%
  */
}

@keyframes animeLogoChangeImg1 {
  0% {
    opacity : 1;
  }
  100% {
    opacity : 0;
  }
}

@keyframes animeLogoChangeRect {
  0% {
    transform: translateX(-100%);
  }
  36% {
    transform: translateX(0);
  }
  64% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes animeLogoChangeImg2 {
  0% {
    opacity : 0;
  }
  100% {
    opacity : 1;
  }
}
