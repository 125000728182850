.p5-form {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  
  // フォーム状態による表示・非表示設定(_display)は、当scss下部の「.mw_wp_form_input」「.mw_wp_form_confirm」「.mw_wp_form_complete」内でそれぞれ設定
  &_display {
    
  }
  
  &_box {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    border-bottom: 1px solid color-purple02(1);

    @include breakpoint-pc {
    }
    
    &:first-of-type {
      border-top: 1px solid color-purple02(1);
    }

    &.-last {
      border-top-style: none !important;
      border-bottom-style: none;

      @include breakpoint-pc {
      }
      
      &.-agree {
        @include flex-justify-center;
        margin-top: 30px;
        margin-bottom: 10px;

        @include breakpoint-pc {
          margin-top: 60px;
          margin-bottom: 20px;
        }
        
        & .p5-form_errorbox {
          .error {
            width: 100%;
            text-align: center;
            background-color: transparent !important;
          }
        }
      }

      &.-privacy {
        @include flex-justify-center;
      }
    }
  }

  &_inputbox {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    width: 100%;

    @include breakpoint-contentinnerhalf {
      width: calc(100% - #{$contact-form-th-w-cif});
    }
    @include breakpoint-pc {
      width: calc(100% - #{$contact-form-th-w-pc});
    }
    @include breakpoint-narrow {
      width: calc(100% - #{$contact-form-th-w-na});
    }
    
    &.-agree {
      @include flexbox;
      @include flex-wrap-wrap;
      @include flex-direction-column;
      @include flex-justify-center;
      @include flex-align-items-center;
      gap: 0;

      @include breakpoint-pc {
      }
    }
  }
  
  &_ttlbox {
    width: 100%;

    @include breakpoint-contentinnerhalf {
      width: $contact-form-th-w-cif;
    }
    @include breakpoint-pc {
      width: $contact-form-th-w-pc;
    }
    @include breakpoint-narrow {
      width: $contact-form-th-w-na;
    }
  }
  
  &_ttl {
    @include font-sprmin("sb");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    @include lineHeight(1.8, 1.8);      
    padding: 12px 12px;  //15px * 0.8 = 12
    box-sizing: border-box;
    text-align: left;

    @include breakpoint-contentinnerhalf {
      text-align: center;
    }
    @include breakpoint-pc {
      padding: 15px 15px;
    }
  }
  
  &_input {
    position: relative;
    width: 100%;
    @include font-ntsan("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    @include lineHeight(1.8, 1.8);

    &.-txt, &.-txtarea {
      padding: 12px 12px; //左右paddingを、ttlboxにあわせる
      box-sizing: border-box;
      background-color: color-gray02(0.2);

      @include breakpoint-contentinnerhalf {
        padding: 12px 24px;  //15px * 0.8 = 12、30px * 0.8 = 24
      }
      @include breakpoint-pc {
        padding: 15px 30px;
      }
      
      &:focus {
//        background-color: color-purple02(0.2);
      }
      
      //オートコンプリート時の背景色は変更できないので、box-shadowで内側を塗りつぶすことで代替対応する
      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f2f2f2 inset;
      }
    }

    &.-txtarea {
      height: 180px;
    }
  }
  
  &_radio {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    gap: 0 24px;
    padding: 0 8px;
    box-sizing: border-box;

    @include breakpoint-pc {
      gap: 0 30px;
      padding: 0 10px;
    }
  }
  
  &_check {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    gap: 0 24px;
    padding: 0 8px;
    box-sizing: border-box;
    
    @include breakpoint-pc {
      gap: 0 30px;
      padding: 0 10px;
    }

    &.-agree {
      gap: 0;
      padding: 0;
      @include font-sprmin("sb");
      @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
      @include lineHeight(1, 1);
    }
    
    label {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-center;
    }
  }
  
  &_link {    
    a {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-center;
      padding-bottom: 5px;
      border-bottom: 1px solid color-gray03(1);
      box-sizing: border-box;
      @include font-ntsan("m");
      @include fontSize(1.2, 1); //1.2 * 0.8 = 0.96
      @include lineHeight(1, 1);
    }
  }
  
  &_errorbox {
    width: 100%;
    
    .error {
      display: block;
      padding: 12px 12px 12px 12px;
      background-color: color-gray02(0.2);

      @include breakpoint-contentinnerhalf {
        width: calc(100% - #{$contact-form-th-w-cif});
        margin-left: auto;
        margin-right: 0;
        padding: 10px 10px 10px 24px;
      }
      @include breakpoint-pc {
        width: calc(100% - #{$contact-form-th-w-pc});
        padding: 15px 15px 15px 30px;
      }
      @include breakpoint-narrow {
        width: calc(100% - #{$contact-form-th-w-na});
        padding: 15px 15px 15px 30px;
      }
    }
  }
}

.mwform-checkbox-field-text {
  vertical-align: 5px;
  padding-left: 5px;
}

.mwform-checkbox-field {
  line-height: 1;
}

.mw_wp_form .error {
  display: block;
  color: color-red01(1) !important;
  font-size: 90%;
  font-weight: 700;
}

.mw_wp_form_input {
  & .p5-form_display {
    &.-input {
      display: block;
    }
    &.-confirm, &.-complete {
      display: none;
    }
  }
  
  & .p0-flexbtns {
    gap: 0;
    
    @include breakpoint-pc {
      gap: 0;
    }
  }
}

.mw_wp_form_confirm {
  & .p5-form_display {
    &.-confirm {
      display: block;
    }
    &.-input, &.-complete {
      display: none;
    }
  }
  
  .p5-form_inputbox {
    padding: 0 12px 12px; //左右paddingを、ttlboxにあわせる
    box-sizing: border-box;

    @include breakpoint-contentinnerhalf {
      padding: 12px 24px;  //15px * 0.8 = 12、30px * 0.8 = 24
    }
    @include breakpoint-pc {
      padding: 15px 30px;
    }
  }
}

.mw_wp_form_complete {
  & .p5-form_display {
    &.-complete {
      display: block;
    }
    &.-input, &.-confirm {
      display: none;
    }
  }
}