.p2-ftnav {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-center;
  @include flex-align-items-center;
  @include font-ntsan("m");
  @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
  @include lineHeight(1, 1);

  @include breakpoint-contentinnerhalf {
    @include flex-justify-flex-end;
  }

  &_item {
    padding: 0 15px;
    border-left: 1px solid color-wh(1);

    @include breakpoint-pc {
      padding: 0 20px;
    }
    @include breakpoint-narrowinner {
      padding: 0 30px;
    }
    @include breakpoint-narrow {
      padding: 0 35px;
    }
    
    &:first-of-type {
      padding-left: 0;
      border-left-style: none;
    }
    
    &:last-of-type {
      padding-right: 0;
    }

    a {
      display: block;
      padding-bottom: 1px;
      box-sizing: border-box;
      @extend %hover;
    }
  }
}