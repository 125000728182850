.p3-box {
  position: relative;
  box-sizing: border-box;
  
  ///// 商品ラベル /////
  &.-frontLineupLabel {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px 10px;
    background-color: color-white01(1);
  }
  
  ///// 製品開発 - features /////
  &.-frontFeatures {
    position: relative;
    padding-top: 45px;
    padding-bottom: 18.333333333333333vw;  //220px / 1200px * 100vw

    @include breakpoint-pc {
      padding-top: 70px;
      padding-bottom: 18.333333333333333vw;  //220px / 1200px * 100vw
    }
    @include breakpoint-contentinner {
      padding-bottom: 220px;
    }

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 100%;
      content: "";
      background-color: color-blue01(1);
    }
  }
  
  ///// お問い合わせ , プラバシーポリシー - サブタイトル //////
  &.-contactSubttl, &.-privacySubttl {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-baseline;
    gap: 5px 14px;  //28px * 0.5 = 14px
    
    @include breakpoint-sp {
      gap: 5px 14px;  //28px * 0.5 = 14px
    }
    @include breakpoint-tb {
      gap: 5px 20px;  //28px * 0.7 = 19.6px
    }
    @include breakpoint-pc {
      gap: 0 28px;
    }

    .p3-box {
      &_item {
        &.-jp {
          width: 100%;
          
          @include breakpoint-tb {
            width: auto;
          }
        }
        &.-deco {
          
        }
        &.-en {
          
        }
      }
    }
  }
}