.p0-tbl {
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-pc {
  }

  ///// 会社概要(外側) /////
  &.-company {
    position: relative;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid color-purple02(1);
    border-collapse: separate;
    box-sizing: border-box;

    & tr {
      display: block;
      
      @include breakpoint-tb {
        display: table-row;
      }
    }

    & th, & td {
      display: block;
      padding: 10px 8px 10px 16px; //12px * 0.8 = 9.6、20px * 0.8 = 16px、25px * 0.8 = 20
      border-bottom: 1px solid color-purple02(1);
      box-sizing: border-box;

      @include breakpoint-tb {
        display: table-cell;
        padding: 10px 16px 10px 16px; //12px * 0.8 = 9.6、20px * 0.8 = 16px、25px * 0.8 = 20
      }
      @include breakpoint-pc {
        padding: 12px 20px 12px 20px;
      }
    }

    & th {
      padding-bottom: 0;
      border-bottom-style: none;
      font-weight: 600; //semi bold

      @include breakpoint-tb {
        padding-bottom: 10px;
        border-bottom-style: solid;
      }
      @include breakpoint-pc {
        padding-bottom: 12px;
      }

      & div {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-space-between;
        @include flex-align-items-start;
        width: 4.1em;
      }
    }
    
    & td {
    }
  }
  
  ///// 会社概要(テーブル内テーブル)(所在地など) /////
  &.-companyInner1 {
    border-collapse: collapse;
    width: 100%;
    
    & tr {
      display: table-row;

      &:last-of-type {
        & th, & td {
          padding: 0;
  
          @include breakpoint-pc {
            padding: 0;
          }
        }
      }
    }
    
    & th, & td {
      display: table-cell;
      padding: 0;
      border-bottom-style: none;
      box-sizing: border-box;

      @include breakpoint-pc {
        padding: 0;
      }
    }
    
    & th {
      transform: translateY(-0.1em);
      text-align: left;
    }
    
    //所在地
    &.-location {
      & th, & td {
        padding-bottom: 10px;

        @include breakpoint-pc {
          padding-bottom: 20px;
        }
      }

      & th {
        width: 4.8em; //4文字(4em) + 調整(0.1em) + 右余白(0.7em)
      }
    }
    
    //取引地域
    &.-area {
      & th {
        width: 4.1em; //3文字(3em) + 調整(0.1em) + 右余白(1em)
      }
    }
  }

  ///// 会社概要(テーブル内テーブル)(役員など) /////
  &.-companyInner2 {
    border-collapse: collapse;
    width: 100%;
    
    & tr {
      display: table-row;
    }
    
    & th, & td {
      display: table-cell;
      padding: 0;
      border-bottom-style: none;
      box-sizing: border-box;

      @include breakpoint-pc {
        padding: 0;
      }
    }
    
    & th {
      width: 6.1em; //5文字(5em) + 調整(0.1em) + 右余白(1em)

      & div {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-space-between;
        @include flex-align-items-start;
        width: 5.1em; //5文字(5em) + 調整(0.1em)
      }
    }
  }
}