.p1-contact {
  position: relative;
  width: $header-contact-width-sp;
  height: $header-height-sp;
  min-height: $header-height-sp;
  box-sizing: border-box;

  @include breakpoint-sp {
  }
  @include breakpoint-pc {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1001;
    width: $header-contact-width-pc;
    height: $header-height-pc;
  }
  @include breakpoint-narrowinner {
    width: $header-contact-width-ni;
    height: $header-height-ni;
  }
  @include breakpoint-narrow {
    width: $header-contact-width-na;
    height: $header-height-na;
  }
  
  &.-fixed {
    display: none;
    
    @include breakpoint-pc {
      display: block;
    }
  }
  
  &.-drawer {
    display: block;
    
    @include breakpoint-pc {
      display: none;
    }
  }

  &_item {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 0 8px; //10px * 0.8 = 8px
    width: 100%;
    height: 100%;
    background-color: color-blue03(1);

    @include breakpoint-pc {
      gap: 0 10px;
    }
  }
  
  &_icon {
    width: 22px;  //SP時、22px指定
    transform: translateY(1px);
    
    @include breakpoint-pc {
      width: 15px;  //19px * 0.8 = 15.2
    }
    @include breakpoint-narrowinner {
      width: 17px;  //19px * 0.9 = 17.1
    }
    @include breakpoint-narrow {
      width: 19px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &_txt {
    @include font-spratq();
    font-size: 2.2rem; //SP時、22px指定
    line-height: 1;
    color: color-wh(1);

    @include breakpoint-pc {
      font-size: 1.28rem; //1.6 * 0.8 = 1.28
    }
    @include breakpoint-narrowinner {
      font-size: 1.44rem;  //1.6 * 0.9 = 1.44
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
    }
  }
}
