.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-center;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @include breakpoint-pc {
    color: color-wh(1);
  }
  @include breakpoint-max {
//    width: 1120px;
  }

  &_content {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
//    padding: 0 5vw;
    box-sizing: border-box;

    @include breakpoint-pc {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include flex-align-items-stretch;
      position: relative;
      width: 100%;
      height: 100%;
      margin-bottom: 0;
      padding: 0;
    }
  }

  &_list {
    width: 100%;
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
//    @include flex-direction-column;
    gap: $header-gnav-gap-y-sp $header-gnav-gap-x-sp;
    padding-right: 0;
    box-sizing: border-box;

    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include flex-align-items-center;
      @include flex-align-content-center;
      gap: 0 $header-gnav-gap-x-pc;
      padding-right: $header-contact-width-pc;
    }
    @include breakpoint-narrowinner {
      gap: 0 $header-gnav-gap-x-ni;
      padding-right: $header-contact-width-ni;
    }
    @include breakpoint-narrow {
      padding-right: $header-contact-width-na;
    }
  }

  &_item {
    display: block;
    position: relative;
    width: 100%;
    box-sizing: border-box;
//    transition: all linear;

    @media (min-aspect-ratio: 1/1) {  //幅 / 高さ(幅1 : 高さ1の比率よりも幅広の場合
      width: calc(50% - (#{$header-gnav-gap-x-sp} / 2) );
    }
    @include breakpoint-pc {
      width: auto !important;
    }
  }

  &_itemInner {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    gap: 5px 5px;
    height: 100%;
    min-height: 41px;
    padding: 8px 0 8px 25px;
    box-sizing: border-box;
    @include font-sprmin("sb");
    font-size: 2rem; //SP時、20px指定値
    line-height: 1;
    color: color-wh(1);
    background-image: url("../images/common/deco/umbrella01_purple03.png");
    background-position: left center;
    background-size: auto 41px;
    background-repeat: no-repeat;

    @media all and (max-height: 420px) {
      min-height: 33px; //41px * 0.8 = 32.8px
      padding: 6px 0 6px 20px; //25px * 0.8 = 20px、8px * 0.8 = 6.4px
      font-size: 1.6rem;
      background-size: auto 33px; //41px * 0.8 = 32.8px
    }
    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-center;
      @include flex-align-items-center;
      gap: 0 0;
      min-height: auto;
      padding: 0;
      @include font-spratq();
      font-size: 1.28rem; //1.6 * 0.8 = 1.28
      text-align: center;
      color: inherit;
      background-image: none;
    }
    @include breakpoint-narrowinner {
      font-size: 1.44rem;  //1.6 * 0.9 = 1.44
    }
    @include breakpoint-narrow {
      font-size: 1.6rem;
    }

    &:hover, &:focus {
    }
  
    &.is-current {
    }
    
    &Txt {
      display: block;
      
      &.-en {
        display: block;
        @include font-cmrgrm("m");
        font-size: 1.5rem;
        @include lineHeight(1, 1);
        color: color-purple03(1);
        
        @media all and (max-height: 420px) {
          font-size: 1.2rem;  //1.5rem * 0.8 = 1.2rem
        }
        @include breakpoint-pc {
          display: none;
        }
      }
    }
  }
}


.l-header {
  &.js-isscroll {
    .p1-gnav {
      background: none;

      @include breakpoint-pc {
        border-style: none;
      }
    }
  }

  &.js-noscroll {
    .p1-gnav {
 
      @include breakpoint-pc {
      }
    }
  }
}
