@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&family=Crimson+Text:wght@400;600;700&family=Noto+Sans+JP:wght@400;500;700&family=Roboto+Serif:opsz@8..144&family=Shippori+Antique&family=Shippori+Mincho:wght@400;500;600;700;800&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

html {
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0; }

#defs {
  display: none; }

img {
  width: 100%; }

ul li {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

svg {
  display: block; }

.disp-block-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .disp-block-pc {
      display: block; } }

.disp-block-sptb {
  display: block; }
  @media all and (min-width: 800px) {
    .disp-block-sptb {
      display: none; } }

.disp-block-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-block-pctb {
      display: block; } }

.disp-block-sp {
  display: block; }
  @media all and (min-width: 480px) {
    .disp-block-sp {
      display: none; } }

.disp-block-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-block-pcsp {
      display: block; } }

.disp-block-sponly {
  display: block; }
  @media all and (min-width: 375px) {
    .disp-block-sponly {
      display: none; } }

.disp-inline-pc {
  display: none; }
  @media all and (min-width: 800px) {
    .disp-inline-pc {
      display: inline; } }

.disp-inline-sptb {
  display: inline; }
  @media all and (min-width: 800px) {
    .disp-inline-sptb {
      display: none; } }

.disp-inline-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-inline-pctb {
      display: inline; } }

.disp-inline-sp {
  display: inline; }
  @media all and (min-width: 480px) {
    .disp-inline-sp {
      display: none; } }

.disp-inline-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-inline-pcsp {
      display: inline; } }

.disp-inline-sponly {
  display: inline; }
  @media all and (min-width: 375px) {
    .disp-inline-sponly {
      display: none; } }

.icon-mail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .icon-mail:before {
    display: inline-block;
    width: 19px;
    height: 13px;
    content: "";
    margin-right: 10px;
    background-image: url("../images/common/mail.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }

/*
@mixin icon-spot($status) {
  @if $status == 'maplink' {
    fill: color-wh(1);
    @include svgSize (12,16);
  } @else if $status == '' {
    
  }
}
*/
/*
// 外部リンクicon svg
@mixin icon-external() {
  fill: color-effect01(1);
  @include svgSize (14,14);
}
.externalIcon, %externalIcon {
  padding: 0 5px;
  @include icon-external();
}
*/
@media all and (min-width: 375px) {
  .break.-minsp:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 480px) {
  .break.-mintb:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 600px) {
  .break.-mincih:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 800px) {
  .break.-minpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1000px) {
  .break.-minni:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1120px) {
  .break.-minnarrow:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1320px) {
  .break.-mincontent:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(1000px - 1px)) {
  .break.-maxni:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(800px - 1px)) {
  .break.-maxpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(600px - 1px)) {
  .break.-maxcih:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(480px - 1px)) {
  .break.-maxtb:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(375px - 1px)) {
  .break.-maxsp:after {
    content: "\a";
    white-space: pre; } }

.align-l {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-l {
      text-align: left !important; } }

.align-r {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-r {
      text-align: right !important; } }

.align-c {
  text-align: left !important; }
  @media all and (min-width: 800px) {
    .align-c {
      text-align: center !important; } }

.br {
  display: none; }
  @media all and (min-width: 800px) {
    .br {
      display: inline-block; } }

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/
.hover, .p2-ftnav_item a {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out; }
  .hover:hover, .hover:focus, .p2-ftnav_item a:hover, .p2-ftnav_item a:focus {
    filter: alpha(opacity=70);
    opacity: 0.7; }

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block; }
  @media all and (min-width: 800px) {
    .anchor {
      top: -72px; } }
  @media all and (min-width: 1320px) {
    .anchor {
      top: -80px; } }

body {
  color: #28304f;
  background: #f8f8f8;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400; }
  @media all and (min-width: 800px) {
    body {
      font-size: 1.5rem; } }

a {
  color: inherit;
  text-decoration: none; }

::selection {
  background: color-effect02(1); }

::-moz-selection {
  background: color-effect02(1); }

.js-tellink a {
  color: inherit; }

#luxy {
  background-color: #f8f8f8; }

.l-header {
  width: 100%;
  height: 56px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  padding: 0 5vw;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .l-header {
      height: 56px;
      padding: 0 15px 0 5vw;
      mix-blend-mode: exclusion; } }
  @media all and (min-width: 1000px) {
    .l-header {
      height: 64px;
      padding: 0 20px 0 5vw; } }
  @media all and (min-width: 1120px) {
    .l-header {
      height: 70px;
      padding: 0 60px; } }
  .l-header_inner {
    width: 100%;
    height: 100%;
    margin: 0 auto; }

.l-wrap {
  overflow: hidden; }

.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative; }
  @media all and (min-width: 800px) {
    .l-section {
      padding: 60px 0; } }
  .l-section.-visible {
    overflow: visible; }
  .l-section.-hidden {
    overflow: hidden; }
  .l-section.-pad0 {
    padding: 0; }
  .l-section.-pt0 {
    padding: 0 0 30px; }
    @media all and (min-width: 800px) {
      .l-section.-pt0 {
        padding: 0 0 60px; } }
  .l-section.-pb0 {
    padding: 30px 0 0; }
    @media all and (min-width: 800px) {
      .l-section.-pb0 {
        padding: 60px 0 0; } }
  .l-section.-smallSpace {
    padding: 15px 0; }
    @media all and (min-width: 800px) {
      .l-section.-smallSpace {
        padding: 30px 0; } }
    .l-section.-smallSpace.-pb0 {
      padding: 15px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-smallSpace.-pb0 {
          padding: 30px 0 0; } }
  .l-section.-largeSpace {
    padding: 45px 0; }
    @media all and (min-width: 800px) {
      .l-section.-largeSpace {
        padding: 50px 0; } }
    @media all and (min-width: 1000px) {
      .l-section.-largeSpace {
        padding: 80px 0; } }
    @media all and (min-width: 1120px) {
      .l-section.-largeSpace {
        padding: 90px 0; } }
    .l-section.-largeSpace.-pb0 {
      padding: 45px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-largeSpace.-pb0 {
          padding: 50px 0 0; } }
      @media all and (min-width: 1000px) {
        .l-section.-largeSpace.-pb0 {
          padding: 80px 0 0; } }
      @media all and (min-width: 1120px) {
        .l-section.-largeSpace.-pb0 {
          padding: 90px 0 0; } }
  .l-section.-hugeSpace {
    padding: 50px 0; }
    @media all and (min-width: 1000px) {
      .l-section.-hugeSpace {
        padding: 90px 0; } }
    @media all and (min-width: 1120px) {
      .l-section.-hugeSpace {
        padding: 120px 0; } }
    .l-section.-hugeSpace.-pb0 {
      padding: 50px 0 0; }
      @media all and (min-width: 1000px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 90px 0 0; } }
      @media all and (min-width: 1120px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 120px 0 0; } }
  .l-section.-lowerpage {
    padding: 40px 0; }
    @media all and (min-width: 800px) {
      .l-section.-lowerpage {
        padding: 80px 0; } }
    .l-section.-lowerpage.-pb0 {
      padding: 40px 0 0; }
      @media all and (min-width: 800px) {
        .l-section.-lowerpage.-pb0 {
          padding: 80px 0 0; } }
  .l-section:not(#hero) {
    margin-top: -1px; }
  .l-section_hidden {
    overflow: hidden; }
  .l-section_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .l-section_wrap.-max {
      width: 100%; }
      @media all and (min-width: 1620px) {
        .l-section_wrap.-max {
          width: 1620px; } }
  .l-section.-bgtype01 {
    background-color: #f8f8f8; }
  .l-section.-bgtype02 {
    background-color: white; }
  .l-section.-bgtype03 {
    background-color: #28304f; }
  .l-section.-bgtype04 {
    background-color: #1d2235; }
  .l-section_bg {
    position: relative;
    width: 100%; }
    .l-section_bg.-bgtype01 {
      background-color: #f8f8f8; }
    .l-section_bg.-bgtype02 {
      background-color: white; }
    .l-section_bg.-bgtype03 {
      background-color: #28304f; }
    .l-section_bg.-bgtype04 {
      background-color: #1d2235; }
  .l-section.-zi1 {
    z-index: 1; }
  .l-section.-zi2 {
    z-index: 2; }
  .l-section.-zi3 {
    z-index: 3; }
  .l-section.-zi4 {
    z-index: 4; }
  .l-section.-zi5 {
    z-index: 5; }
  .l-section.-zi6 {
    z-index: 6; }
  .l-section.-zi7 {
    z-index: 7; }
  .l-section.-zi8 {
    z-index: 8; }
  .l-section.-zi9 {
    z-index: 9; }
  .l-section.-zi10 {
    z-index: 10; }
  .l-section_content {
    position: relative;
    width: calc(100% - 5vw - 5vw);
    margin: 0 auto;
    overflow-wrap: anywhere; }
    @media all and (min-width: 800px) {
      .l-section_content {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1240px) {
      .l-section_content {
        width: 1120px; } }
    @media all and (min-width: 1120px) {
      .l-section_content.-narrow {
        width: 1000px; } }
    @media all and (min-width: 1320px) {
      .l-section_content.-narrow {
        width: 1000px; } }
    @media all and (min-width: 1120px) {
      .l-section_content.-content {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1320px) {
      .l-section_content.-content {
        width: 1200px; } }
    .l-section_content.-maxinner {
      width: calc(100% - 5vw - 5vw); }
      @media all and (min-width: 800px) {
        .l-section_content.-maxinner {
          width: calc(100% - 5vw - 5vw); } }
      @media all and (min-width: 1320px) {
        .l-section_content.-maxinner {
          width: calc(100% - 60px - 60px); } }
      @media all and (min-width: 1620px) {
        .l-section_content.-maxinner {
          width: 1500px; } }
    .l-section_content.-max {
      width: calc(100% - 5vw - 5vw); }
      @media all and (min-width: 800px) {
        .l-section_content.-max {
          width: calc(100% - 5vw - 5vw); } }
      @media all and (min-width: 1320px) {
        .l-section_content.-max {
          width: calc(100% - 60px - 60px); } }
      @media all and (min-width: 1620px) {
        .l-section_content.-max {
          width: calc(100% - 60px - 60px); } }
    .l-section_content.-full {
      width: 100%; }
      @media all and (min-width: 1320px) {
        .l-section_content.-full {
          width: 100%; } }
    .l-section_content.-visible {
      overflow: visible; }
    .l-section_content.-zi0 {
      z-index: 0; }
    .l-section_content.-zi1 {
      z-index: 1; }
    .l-section_content.-zi2 {
      z-index: 2; }
    .l-section_content.-zi3 {
      z-index: 3; }
    .l-section_content.-zi4 {
      z-index: 4; }
    .l-section_content.-zi5 {
      z-index: 5; }
    .l-section_content.-zi6 {
      z-index: 6; }
    .l-section_content.-zi7 {
      z-index: 7; }
    .l-section_content.-zi8 {
      z-index: 8; }
    .l-section_content.-zi9 {
      z-index: 9; }
    .l-section_content.-zi10 {
      z-index: 10; }

.l-footer {
  padding: 15px 0 12px;
  box-sizing: border-box;
  background-color: #28304f; }
  @media all and (min-width: 800px) {
    .l-footer {
      padding: 30px 0 25px; } }

.c-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row; }
  .c-flex.-jStart {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .c-flex.-jCenter {
    -webkit-justify-content: center;
    justify-content: center; }
  .c-flex.-jEnd {
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .c-flex.-aiCenter {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .c-flex.-rowTtl {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline; }
    .c-flex.-rowTtl.-history {
      gap: 0 20px; }
      @media all and (min-width: 800px) {
        .c-flex.-rowTtl.-history {
          gap: 0 25px; } }
  .c-flex.-bizrecHead {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 600px) {
      .c-flex.-bizrecHead {
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; } }
  .c-flex.-bizrecBody, .c-flex.-tgFeatures {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end; }
    @media all and (min-width: 800px) {
      .c-flex.-bizrecBody, .c-flex.-tgFeatures {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; } }
  .c-flex.-productTtl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    gap: 12px 0; }
    @media all and (min-width: 800px) {
      .c-flex.-productTtl {
        gap: 15px 0; } }
    .c-flex.-productTtl.-rev {
      -webkit-justify-content: flex-start;
      justify-content: flex-start; }
    .c-flex.-productTtl .c-flex_item {
      width: 100%; }
      @media all and (min-width: 800px) {
        .c-flex.-productTtl .c-flex_item {
          width: auto; } }
      .c-flex.-productTtl .c-flex_item:nth-child(1) {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 2;
        order: 2; }
        @media all and (min-width: 800px) {
          .c-flex.-productTtl .c-flex_item:nth-child(1) {
            -webkit-box-ordinal-group: 1;
            -ms-flex-order: 1;
            order: 1; } }
      .c-flex.-productTtl .c-flex_item:nth-child(2) {
        -webkit-box-ordinal-group: 1;
        -ms-flex-order: 1;
        order: 1; }
        @media all and (min-width: 800px) {
          .c-flex.-productTtl .c-flex_item:nth-child(2) {
            -webkit-box-ordinal-group: 2;
            -ms-flex-order: 2;
            order: 2; } }
      .c-flex.-productTtl .c-flex_item:nth-child(3) {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 3;
        order: 3; }
        @media all and (min-width: 800px) {
          .c-flex.-productTtl .c-flex_item:nth-child(3) {
            -webkit-box-ordinal-group: 3;
            -ms-flex-order: 3;
            order: 3; } }
  .c-flex.-frontOriginal01 .c-flex_item {
    position: relative;
    width: 100%; }
    @media all and (min-width: 480px) {
      .c-flex.-frontOriginal01 .c-flex_item {
        width: 50%; } }
  .c-flex.-frontOriginal01Tg {
    gap: 35px 0; }
    @media all and (min-width: 800px) {
      .c-flex.-frontOriginal01Tg {
        gap: 0 0; } }
    .c-flex.-frontOriginal01Tg .c-flex_item {
      position: relative; }
      .c-flex.-frontOriginal01Tg .c-flex_item.-txt {
        width: 100%; }
        @media all and (min-width: 800px) {
          .c-flex.-frontOriginal01Tg .c-flex_item.-txt {
            width: 68.75%; } }
      .c-flex.-frontOriginal01Tg .c-flex_item.-img {
        width: 100%; }
        @media all and (min-width: 800px) {
          .c-flex.-frontOriginal01Tg .c-flex_item.-img {
            width: calc(100% - 68.75%); } }

.c-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px 8px; }
  @media all and (min-width: 480px) {
    .c-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 10px 10px; } }
  .c-grid_item {
    position: relative; }
    .c-grid_item.-col13 {
      grid-column-start: 1;
      grid-column-end: 3; }
    .c-grid_item.-col24 {
      grid-column-start: 2;
      grid-column-end: 4; }
    .c-grid_item.-row13 {
      grid-row-start: 1;
      grid-row-end: 3; }
    .c-grid_item.-tbCol24SpCol13 {
      grid-column-start: 1;
      grid-column-end: 3; }
      @media all and (min-width: 480px) {
        .c-grid_item.-tbCol24SpCol13 {
          grid-column-start: 2;
          grid-column-end: 4; } }
  .c-grid.-col4 {
    grid-template-columns: repeat(1, 1fr);
    gap: 7.4vw 0;
    width: 100%; }
    @media all and (min-width: 375px) {
      .c-grid.-col4 {
        grid-template-columns: repeat(2, 1fr);
        gap: 3.2vw 3vw; } }
    @media all and (min-width: 800px) {
      .c-grid.-col4 {
        grid-template-columns: repeat(4, 1fr);
        gap: 0 3.2vw; } }
    @media all and (min-width: 1240px) {
      .c-grid.-col4 {
        gap: 0 40px; } }
    .c-grid.-col4 .c-grid_item {
      width: 100%;
      box-sizing: border-box; }
  .c-grid.-bizrec {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px 0; }
    @media all and (min-width: 600px) {
      .c-grid.-bizrec {
        grid-template-columns: 170px auto;
        gap: 0 40px; } }
    @media all and (min-width: 800px) {
      .c-grid.-bizrec {
        grid-template-columns: 210px auto; } }
    @media all and (min-width: 1000px) {
      .c-grid.-bizrec {
        grid-template-columns: repeat(2, 1fr); } }
    .c-grid.-bizrec .c-grid_item.-body {
      box-sizing: border-box; }

.c-philosophy {
  position: relative;
  margin-bottom: calc(60px + 5vw); }
  @media all and (min-width: 800px) {
    .c-philosophy {
      margin-bottom: calc(120px + 4.83871vw); } }
  @media all and (min-width: 1240px) {
    .c-philosophy {
      margin-bottom: calc(120px + 60px); } }
  .c-philosophy:last-of-type {
    margin-bottom: 0; }
    @media all and (min-width: 800px) {
      .c-philosophy:last-of-type {
        margin-bottom: 0; } }
    @media all and (min-width: 1620px) {
      .c-philosophy:last-of-type {
        margin-bottom: 0; } }
  .c-philosophy.-rev .c-philosophy_ttl {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    margin-right: auto;
    margin-left: -27px; }
    @media all and (min-width: 800px) {
      .c-philosophy.-rev .c-philosophy_ttl {
        margin-right: auto;
        margin-left: -34px; } }
    @media all and (min-width: 1320px) {
      .c-philosophy.-rev .c-philosophy_ttl {
        margin-right: auto; } }
    .c-philosophy.-rev .c-philosophy_ttl h3::after {
      right: auto;
      left: -68px;
      transform: translate(-100%, -50%); }
      @media all and (min-width: 800px) {
        .c-philosophy.-rev .c-philosophy_ttl h3::after {
          left: -87px; } }
  @media all and (min-width: 800px) {
    .c-philosophy.-rev .c-philosophy_body {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; } }
  .c-philosophy.-rev .c-philosophy_body::before {
    left: auto;
    right: -5vw;
    transform: translateX(-5vw); }
    @media all and (min-width: 800px) {
      .c-philosophy.-rev .c-philosophy_body::before {
        right: -5vw;
        transform: translateX(-5vw); } }
    @media all and (min-width: 1320px) {
      .c-philosophy.-rev .c-philosophy_body::before {
        transform: translateX(-60px); } }
    @media all and (min-width: 1240px) {
      .c-philosophy.-rev .c-philosophy_body::before {
        right: calc((100vw - 1120px) / 2 * -1); } }
    @media all and (min-width: 1620px) {
      .c-philosophy.-rev .c-philosophy_body::before {
        right: -250px; } }
  .c-philosophy.-rev .c-philosophy_txt {
    padding-left: 0;
    padding-right: 5vw; }
    @media all and (min-width: 800px) {
      .c-philosophy.-rev .c-philosophy_txt {
        padding-left: 0;
        padding-right: 0; } }
  .c-philosophy.-rev .c-philosophy_img {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .c-philosophy_head {
    margin-bottom: 30px; }
    @media all and (min-width: 800px) {
      .c-philosophy_head {
        margin-bottom: 60px; } }
  .c-philosophy_body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative; }
    .c-philosophy_body::before {
      display: block;
      position: absolute;
      top: calc(5vw + 0.806451612903226vw);
      left: -5vw;
      transform: translateX(5vw);
      z-index: 0;
      width: 100vw;
      height: calc(100% - 0.806451612903226vw);
      content: "";
      background-color: white; }
      @media all and (min-width: 800px) {
        .c-philosophy_body::before {
          top: calc(4.83871vw + 0.806451612903226vw);
          left: -5vw;
          transform: translateX(5vw);
          width: 100vw;
          height: calc(100% - 0.806451612903226vw); } }
      @media all and (min-width: 1320px) {
        .c-philosophy_body::before {
          transform: translateX(60px);
          width: 100vw; } }
      @media all and (min-width: 1240px) {
        .c-philosophy_body::before {
          top: calc(60px + 10px);
          left: calc((100vw - 1120px) / 2 * -1);
          height: calc(100% - 10px); } }
      @media all and (min-width: 1620px) {
        .c-philosophy_body::before {
          left: -250px; } }
  .c-philosophy_ttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 0 12px;
    position: relative;
    z-index: 1;
    margin-right: -2.5vw;
    padding: 0 5vw;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .c-philosophy_ttl {
        gap: 0 16px;
        margin-right: -2.5vw;
        padding: 0 5vw; } }
    @media all and (min-width: 1320px) {
      .c-philosophy_ttl {
        margin-right: -30px;
        padding: 0 60px; } }
    .c-philosophy_ttl::before {
      display: block;
      position: relative;
      transform: translateY(-4px);
      width: 54px;
      height: 54px;
      content: "";
      background-image: url("../images/common/deco/figure_purple03.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
      @media all and (min-width: 800px) {
        .c-philosophy_ttl::before {
          transform: translateY(-5px);
          width: 68px;
          height: 68px; } }
    .c-philosophy_ttl h3 {
      position: relative; }
      .c-philosophy_ttl h3::after {
        display: block;
        position: absolute;
        top: 24.5px;
        right: -1.25vw;
        transform: translate(100%, -50%);
        width: 100vw;
        height: 1px;
        content: "";
        background-color: #9783b5; }
        @media all and (min-width: 800px) {
          .c-philosophy_ttl h3::after {
            top: 31.5px; } }
        @media all and (min-width: 1200px) {
          .c-philosophy_ttl h3::after {
            right: -15px; } }
  .c-philosophy_txt {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-top: 5vw;
    padding-top: 0;
    padding-left: 5vw;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .c-philosophy_txt {
        width: calc(500 / 1120 * 100%);
        margin-top: 4.83871vw;
        padding-top: 4.62962962962963vw;
        padding-left: 0; } }
    @media all and (min-width: 1620px) {
      .c-philosophy_txt {
        margin-top: 60px;
        padding-top: 75px; } }
  .c-philosophy_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    z-index: 1;
    width: 100%; }
    @media all and (min-width: 800px) {
      .c-philosophy_img {
        width: calc(560 / 1120 * 100%); } }
    .c-philosophy_img img {
      width: calc(100% + 5vw);
      height: auto; }
      @media all and (min-width: 800px) {
        .c-philosophy_img img {
          width: calc(100% + 5vw); } }
      @media all and (min-width: 1240px) {
        .c-philosophy_img img {
          width: calc(100% + calc((100vw - 1120px) / 2)); } }
      @media all and (min-width: 1620px) {
        .c-philosophy_img img {
          width: calc(100% + 250px); } }

.c-history {
  position: relative; }
  .c-history_era {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative; }
    .c-history_era:first-of-type .c-history_body {
      padding-top: 5vw; }
      @media all and (min-width: 800px) {
        .c-history_era:first-of-type .c-history_body {
          padding-top: 5vw; } }
      @media all and (min-width: 1240px) {
        .c-history_era:first-of-type .c-history_body {
          padding-top: 60px; } }
    .c-history_era:first-of-type .c-history_nav {
      transform: translateY(0);
      padding-top: 20px; }
      @media all and (min-width: 800px) {
        .c-history_era:first-of-type .c-history_nav {
          transform: translateY(0);
          padding-top: 40px; } }
    .c-history_era:last-of-type .c-history_body {
      padding-bottom: 5vw; }
      @media all and (min-width: 800px) {
        .c-history_era:last-of-type .c-history_body {
          padding-bottom: 5vw; } }
      @media all and (min-width: 1240px) {
        .c-history_era:last-of-type .c-history_body {
          padding-bottom: 60px; } }
  .c-history_nav {
    display: none;
    position: relative;
    z-index: 2;
    transform: translateY(-16px);
    box-sizing: border-box; }
    @media all and (min-width: 600px) {
      .c-history_nav {
        display: block;
        width: 150px; } }
    @media all and (min-width: 800px) {
      .c-history_nav {
        transform: translateY(-20px);
        width: 189px; } }
    @media all and (min-width: 1240px) {
      .c-history_nav {
        width: 270px; } }
    .c-history_nav_inner {
      position: relative; }
      .c-history_nav_inner.-absolute {
        position: absolute;
        top: 20px !important;
        left: 0;
        width: 100%;
        height: 100%; }
        @media all and (min-width: 800px) {
          .c-history_nav_inner.-absolute {
            top: 40px !important; } }
    .c-history_nav_item {
      position: relative;
      padding: 12px 0;
      box-sizing: border-box;
      color: white; }
      @media all and (min-width: 800px) {
        .c-history_nav_item {
          padding: 15px 0; } }
      .c-history_nav_item.-active {
        padding-left: 34px;
        box-sizing: border-box;
        color: white; }
        @media all and (min-width: 800px) {
          .c-history_nav_item.-active {
            padding-left: 42px; } }
        .c-history_nav_item.-active::before {
          display: block;
          position: absolute;
          top: 50%;
          left: 3px;
          transform: translateY(-50%);
          width: 26px;
          height: 26px;
          content: "";
          background-image: url("../images/common/deco/figure_purple03.png");
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat; }
          @media all and (min-width: 800px) {
            .c-history_nav_item.-active::before {
              left: 4px;
              width: 32px;
              height: 32px; } }
  .c-history_body {
    position: relative;
    width: 100%;
    padding: 0 0 0 5vw;
    box-sizing: border-box;
    background-color: white; }
    @media all and (min-width: 600px) {
      .c-history_body {
        width: calc(100% - 150px); } }
    @media all and (min-width: 800px) {
      .c-history_body {
        width: calc(100% - 189px);
        padding: 0 0 0 5vw; } }
    @media all and (min-width: 1240px) {
      .c-history_body {
        width: calc(100% - 270px);
        padding: 0 0 0 60px; } }
    .c-history_body::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100vw;
      height: 100%;
      content: "";
      background-color: white; }
    .c-history_body_inner {
      position: relative;
      z-index: 1; }
    .c-history_body_ttlbox {
      padding: 20px 10px 16px 10px;
      border-bottom: 1px solid #28304f;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .c-history_body_ttlbox {
          padding: 25px 10px 25px 10px; } }
      .c-history_body_ttlbox.js-slideBtn {
        pointer-events: auto;
        cursor: pointer; }
        @media all and (min-width: 600px) {
          .c-history_body_ttlbox.js-slideBtn {
            pointer-events: none;
            cursor: text; } }
    .c-history_body .js-slidePanel.no-active {
      display: none; }
      @media all and (min-width: 600px) {
        .c-history_body .js-slidePanel.no-active {
          display: block; } }
    .c-history_body .js-slidePanel.is-active {
      display: block; }
      @media all and (min-width: 600px) {
        .c-history_body .js-slidePanel.is-active {
          display: block; } }
    .c-history_body_rowbox {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      gap: 25px 0;
      padding: 24px 10px 24px 10px;
      border-bottom: 1px solid #d7dae3;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .c-history_body_rowbox {
          -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
          gap: 0 6.4%;
          padding: 30px 10px 30px 10px; } }
      @media all and (min-width: 1240px) {
        .c-history_body_rowbox {
          gap: 0 50px; } }
      .c-history_body_rowbox:last-of-type {
        margin-bottom: 10px;
        border-bottom-style: none; }
        @media all and (min-width: 800px) {
          .c-history_body_rowbox:last-of-type {
            margin-bottom: 20px; } }
    .c-history_body_txtbox {
      width: 100%; }
    .c-history_body_imgbox {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 100%; }
      @media all and (min-width: 800px) {
        .c-history_body_imgbox {
          width: 48.7%; } }
      @media all and (min-width: 1240px) {
        .c-history_body_imgbox {
          width: 380px; } }
    .c-history_body_dt {
      margin-bottom: 6px; }
      @media all and (min-width: 800px) {
        .c-history_body_dt {
          margin-bottom: 12px; } }
    .c-history_body_ttl {
      margin-bottom: 15px; }
      @media all and (min-width: 800px) {
        .c-history_body_ttl {
          margin-bottom: 30px; } }

.p0-position.-static {
  position: static; }

.p0-position.-relative {
  position: relative; }

.p0-position.-absolute {
  position: absolute; }

.p0-position.-fixed {
  position: fixed; }

.p0-position .-zim {
  z-index: -1; }

.p0-position.-zi0 {
  z-index: 0; }

.p0-position.-zi1 {
  z-index: 1; }

.p0-position.-zi2 {
  z-index: 2; }

.p0-position.-zi3 {
  z-index: 3; }

.p0-position.-zi4 {
  z-index: 4; }

.p0-position.-zi5 {
  z-index: 5; }

.p0-position.-zi6 {
  z-index: 6; }

.p0-position.-zi7 {
  z-index: 7; }

.p0-position.-zi8 {
  z-index: 8; }

.p0-position.-zi9 {
  z-index: 9; }

.p0-position.-zi10 {
  z-index: 10; }

.p0-position.-hidden {
  overflow: hidden; }

.p0-btn1 {
  position: relative;
  width: 720px;
  max-width: 100%;
  box-sizing: border-box;
  font-family: 'Shippori Antique', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.44rem;
  text-align: center;
  line-height: 1.25;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 1.25vw;
  border-radius: 1.25vw; }
  @media all and (min-width: 800px) {
    .p0-btn1 {
      line-height: 1.25; } }
  @media all and (min-width: 800px) {
    .p0-btn1 {
      border-radius: 10px; } }
  @media all and (min-width: 800px) {
    .p0-btn1 {
      border-radius: 10px; } }
  @media all and (min-width: 375px) {
    .p0-btn1 {
      font-size: 1.68rem; } }
  @media all and (min-width: 480px) {
    .p0-btn1 {
      font-size: 1.92rem; } }
  @media all and (min-width: 800px) {
    .p0-btn1 {
      font-size: 2.4rem; } }
  .p0-btn1.-type01 {
    color: #333333;
    background-color: #f4f4f4; }
  .p0-btn1_inner {
    display: block;
    position: relative;
    padding: 18px 12px 20px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p0-btn1_inner {
        padding: 22px 15px 25px; } }
    .p0-btn1_inner:hover .p0-btn1_border::after {
      animation: 0.5s ease-in 0s 1 both slideRight; }
  .p0-btn1_txt {
    margin-bottom: 10px; }
    @media all and (min-width: 800px) {
      .p0-btn1_txt {
        margin-bottom: 12px; } }
  .p0-btn1_border {
    display: block;
    position: relative;
    overflow: hidden;
    width: 248px;
    max-width: 100%;
    height: 5px;
    margin-left: auto;
    margin-right: auto;
    background: #4150b1;
    background: -moz-linear-gradient(90deg, #f6bf75 0%, #d77185 35%, #8766ac 65%, #4150b1 100%);
    background: -webkit-linear-gradient(90deg, #f6bf75 0%, #d77185 35%, #8766ac 65%, #4150b1 100%);
    background: -o-linear-gradient(90deg, #f6bf75 0%, #d77185 35%, #8766ac 65%, #4150b1 100%);
    background: -ms-linear-gradient(90deg, #f6bf75 0%, #d77185 35%, #8766ac 65%, #4150b1 100%);
    background: linear-gradient(90deg, #f6bf75 0%, #d77185 35%, #8766ac 65%, #4150b1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6bf75', endColorstr='#4150b1',GradientType=1 ); }
    @media all and (min-width: 375px) {
      .p0-btn1_border {
        width: 288px; } }
    @media all and (min-width: 480px) {
      .p0-btn1_border {
        width: 330px; } }
    @media all and (min-width: 800px) {
      .p0-btn1_border {
        width: 412px; } }
    .p0-btn1_border::after {
      display: block;
      position: absolute;
      top: -2px;
      left: -2px;
      transform: translateX(-110%);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      content: "";
      background-color: #f4f4f4; }

.p0-btn2 {
  overflow: hidden;
  width: 220px;
  border: 1px solid #28304f;
  box-sizing: border-box;
  border-radius: 100vh;
  font-family: 'Shippori Antique', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.44rem;
  line-height: 1; }
  @media all and (min-width: 800px) {
    .p0-btn2 {
      font-size: 1.8rem; } }
  @media all and (min-width: 800px) {
    .p0-btn2 {
      line-height: 1; } }
  .p0-btn2_inner, .p0-btn2_btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 11px;
    width: 100%;
    height: 100%;
    padding: 12px 13px 14px;
    box-sizing: border-box;
    color: #28304f;
    background-color: #dde2f6;
    -webkit-transition: color .15s linear, background-color .15s linear;
    transition: color .15s linear, background-color .15s linear; }
    @media all and (min-width: 800px) {
      .p0-btn2_inner, .p0-btn2_btn {
        gap: 0 14px;
        padding: 15px 16px 17px; } }
    .p0-btn2_inner::before, .p0-btn2_btn::before {
      display: block;
      position: relative;
      width: 0;
      height: 10px;
      content: "";
      margin-right: -1px;
      pointer-events: none; }
    .p0-btn2_inner::after, .p0-btn2_btn::after {
      display: block;
      position: relative;
      width: 10px;
      height: 10px;
      content: "";
      margin-top: 1px;
      background-image: url("../images/common/icon/tri_right_blue02.png");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
      -webkit-transition: background-image .15s linear;
      transition: background-image .15s linear; }
    .p0-btn2_inner:hover, .p0-btn2_btn:hover {
      color: white;
      background-color: #28304f;
      -webkit-transition: color .15s linear, background-color .15s linear;
      transition: color .15s linear, background-color .15s linear; }
      .p0-btn2_inner:hover::after, .p0-btn2_btn:hover::after {
        background-image: url("../images/common/icon/tri_right_wh.png");
        -webkit-transition: background-image .15s linear;
        transition: background-image .15s linear; }

.p0-flexbtns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  gap: 16px 16px; }
  @media all and (min-width: 800px) {
    .p0-flexbtns {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      gap: 20px 20px; } }
  .p0-flexbtns.-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .p0-flexbtns.-center {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 800px) {
      .p0-flexbtns.-center {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }
  .p0-flexbtns.-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }

.p0-tbl {
  width: 100%;
  box-sizing: border-box; }
  .p0-tbl.-company {
    position: relative;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid #9783b5;
    border-collapse: separate;
    box-sizing: border-box; }
    .p0-tbl.-company tr {
      display: block; }
      @media all and (min-width: 480px) {
        .p0-tbl.-company tr {
          display: table-row; } }
    .p0-tbl.-company th, .p0-tbl.-company td {
      display: block;
      padding: 10px 8px 10px 16px;
      border-bottom: 1px solid #9783b5;
      box-sizing: border-box; }
      @media all and (min-width: 480px) {
        .p0-tbl.-company th, .p0-tbl.-company td {
          display: table-cell;
          padding: 10px 16px 10px 16px; } }
      @media all and (min-width: 800px) {
        .p0-tbl.-company th, .p0-tbl.-company td {
          padding: 12px 20px 12px 20px; } }
    .p0-tbl.-company th {
      padding-bottom: 0;
      border-bottom-style: none;
      font-weight: 600; }
      @media all and (min-width: 480px) {
        .p0-tbl.-company th {
          padding-bottom: 10px;
          border-bottom-style: solid; } }
      @media all and (min-width: 800px) {
        .p0-tbl.-company th {
          padding-bottom: 12px; } }
      .p0-tbl.-company th div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 4.1em; }
  .p0-tbl.-companyInner1 {
    border-collapse: collapse;
    width: 100%; }
    .p0-tbl.-companyInner1 tr {
      display: table-row; }
      .p0-tbl.-companyInner1 tr:last-of-type th, .p0-tbl.-companyInner1 tr:last-of-type td {
        padding: 0; }
        @media all and (min-width: 800px) {
          .p0-tbl.-companyInner1 tr:last-of-type th, .p0-tbl.-companyInner1 tr:last-of-type td {
            padding: 0; } }
    .p0-tbl.-companyInner1 th, .p0-tbl.-companyInner1 td {
      display: table-cell;
      padding: 0;
      border-bottom-style: none;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-tbl.-companyInner1 th, .p0-tbl.-companyInner1 td {
          padding: 0; } }
    .p0-tbl.-companyInner1 th {
      transform: translateY(-0.1em);
      text-align: left; }
    .p0-tbl.-companyInner1.-location th, .p0-tbl.-companyInner1.-location td {
      padding-bottom: 10px; }
      @media all and (min-width: 800px) {
        .p0-tbl.-companyInner1.-location th, .p0-tbl.-companyInner1.-location td {
          padding-bottom: 20px; } }
    .p0-tbl.-companyInner1.-location th {
      width: 4.8em; }
    .p0-tbl.-companyInner1.-area th {
      width: 4.1em; }
  .p0-tbl.-companyInner2 {
    border-collapse: collapse;
    width: 100%; }
    .p0-tbl.-companyInner2 tr {
      display: table-row; }
    .p0-tbl.-companyInner2 th, .p0-tbl.-companyInner2 td {
      display: table-cell;
      padding: 0;
      border-bottom-style: none;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-tbl.-companyInner2 th, .p0-tbl.-companyInner2 td {
          padding: 0; } }
    .p0-tbl.-companyInner2 th {
      width: 6.1em; }
      .p0-tbl.-companyInner2 th div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        width: 5.1em; }

.p0-hero {
  /* トップページ */
  /* 下層ページ */ }
  .p0-hero.-front {
    position: relative;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box; }
    .p0-hero.-front.-scene01 {
      height: 120vh;
      background-image: url("../images/front/kv/scene01_bg_sp.jpg");
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat; }
      @media all and (min-width: 800px) {
        .p0-hero.-front.-scene01 {
          height: 120vh;
          background-image: url("../images/front/kv/scene01_bg_pc.jpg");
          background-position: center bottom; } }
      @media (min-aspect-ratio: 10 / 25) {
        .p0-hero.-front.-scene01 {
          background-size: auto 100%; } }
      @media (min-aspect-ratio: 10 / 20) {
        .p0-hero.-front.-scene01 {
          background-size: auto 110%; } }
      @media (min-aspect-ratio: 10 / 15) {
        .p0-hero.-front.-scene01 {
          background-size: auto 120%; } }
      @media (min-aspect-ratio: 10 / 10) {
        .p0-hero.-front.-scene01 {
          background-size: cover; } }
    .p0-hero.-front.-scene02 .p0-hero_content, .p0-hero.-front.-scene03 .p0-hero_content {
      width: calc(100% - 5vw - 5vw);
      margin: 0 auto; }
      @media all and (min-width: 800px) {
        .p0-hero.-front.-scene02 .p0-hero_content, .p0-hero.-front.-scene03 .p0-hero_content {
          width: calc(100% - 5vw - 5vw); } }
      @media all and (min-width: 1120px) {
        .p0-hero.-front.-scene02 .p0-hero_content, .p0-hero.-front.-scene03 .p0-hero_content {
          width: 1000px; } }
      @media all and (min-width: 1320px) {
        .p0-hero.-front.-scene02 .p0-hero_content, .p0-hero.-front.-scene03 .p0-hero_content {
          width: 1000px; } }
    .p0-hero.-front.-scene02 {
      height: auto; }
      @media all and (min-width: 800px) {
        .p0-hero.-front.-scene02 {
          height: auto; } }
    .p0-hero.-front .p0-hero_content {
      position: relative;
      width: 100%;
      height: 100vh;
      box-sizing: border-box; }
      .p0-hero.-front .p0-hero_content.-relative, .p0-hero.-front .p0-hero_content.-relativeDummy {
        padding-top: 36vh !important;
        box-sizing: border-box;
        transform: none !important;
        height: auto; }
        @media (min-aspect-ratio: 8 / 6) {
          .p0-hero.-front .p0-hero_content.-relative, .p0-hero.-front .p0-hero_content.-relativeDummy {
            padding-top: 30vh !important; } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 600px) {
    .p0-hero.-front .p0-hero_content.-relative, .p0-hero.-front .p0-hero_content.-relativeDummy {
      padding-top: 32vh !important; } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 700px) {
    .p0-hero.-front .p0-hero_content.-relative, .p0-hero.-front .p0-hero_content.-relativeDummy {
      padding-top: 34vh !important; } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 800px) {
    .p0-hero.-front .p0-hero_content.-relative, .p0-hero.-front .p0-hero_content.-relativeDummy {
      padding-top: 36vh !important; } }
      .p0-hero.-front .p0-hero_content.-relativeDummy {
        position: absolute;
        left: -100vw;
        width: 1px;
        opacity: 0; }
    .p0-hero.-front .p0-hero_catchcopy {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      z-index: 1;
      box-sizing: border-box;
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600;
      font-size: 12.333333333333333vw;
      text-align: center;
      line-height: 1.4; }
      @media all and (min-width: 480px) {
        .p0-hero.-front .p0-hero_catchcopy {
          font-size: 5.92rem; } }
      @media all and (min-width: 800px) {
        .p0-hero.-front .p0-hero_catchcopy {
          font-size: 7.4rem; } }
      @media all and (max-height: 480px) {
        .p0-hero.-front .p0-hero_catchcopy {
          font-size: 4rem; } }
      .p0-hero.-front .p0-hero_catchcopy .delimiter {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 1em; }
      .p0-hero.-front .p0-hero_catchcopy_txt {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        position: relative;
        box-sizing: border-box; }
        .p0-hero.-front .p0-hero_catchcopy_txt.-scene01 {
          margin-bottom: 28px;
          color: white; }
          @media all and (min-width: 800px) {
            .p0-hero.-front .p0-hero_catchcopy_txt.-scene01 {
              margin-bottom: 55px; } }
        .p0-hero.-front .p0-hero_catchcopy_txt.-scene02 {
          margin-bottom: 35px;
          color: #28304f; }
          @media all and (min-width: 800px) {
            .p0-hero.-front .p0-hero_catchcopy_txt.-scene02 {
              margin-bottom: 70px; } }
        .p0-hero.-front .p0-hero_catchcopy_txt.-scene03 {
          margin-bottom: 35px;
          color: #28304f; }
          @media all and (min-width: 800px) {
            .p0-hero.-front .p0-hero_catchcopy_txt.-scene03 {
              margin-bottom: 70px; } }
    .p0-hero.-front .p0-hero_logo {
      position: relative;
      width: 189px;
      height: 60px;
      margin: 0 auto; }
      .p0-hero.-front .p0-hero_logo_jp, .p0-hero.-front .p0-hero_logo_en {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto; }
  .p0-hero.-lowerpage {
    position: relative;
    width: 100%;
    height: 290px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 0;
    box-sizing: border-box;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
    @media all and (min-width: 800px) {
      .p0-hero.-lowerpage {
        height: 360px;
        margin-bottom: 40px;
        padding: 0; } }
    .p0-hero.-lowerpage .p0-hero_content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-line-pack: center;
      align-content: center;
      height: 100%; }
    .p0-hero.-lowerpage .p0-hero_headline {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600;
      font-size: 4rem;
      line-height: 1;
      color: white; }
      @media all and (min-width: 800px) {
        .p0-hero.-lowerpage .p0-hero_headline {
          font-size: 5rem; } }
      @media all and (min-width: 800px) {
        .p0-hero.-lowerpage .p0-hero_headline {
          line-height: 1; } }
  .p0-hero.-contact {
    background-image: url("../images/contact/kv_pc.jpg"); }
  .p0-hero.-privacy {
    background-image: url("../images/privacy/kv_pc.jpg"); }
  .p0-hero.-error {
    background-image: url("../images/error/kv_pc.jpg"); }

.js-heroScene01, .js-heroScene02, .js-heroScene03 {
  display: block;
  position: relative;
  width: 100%;
  text-align: center !important; }

.js-heroScene01 {
  top: 36vh; }
  @media (min-aspect-ratio: 8 / 6) {
    .js-heroScene01 {
      top: 30vh; } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 600px) {
    .js-heroScene01 {
      top: 32vh; } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 700px) {
    .js-heroScene01 {
      top: 34vh; } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 800px) {
    .js-heroScene01 {
      top: 36vh; } }

.js-heroScene02 {
  top: calc(36vh - 120vh); }
  @media (min-aspect-ratio: 8 / 6) {
    .js-heroScene02 {
      top: calc(30vh - 120vh); } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 600px) {
    .js-heroScene02 {
      top: calc(32vh - 120vh); } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 700px) {
    .js-heroScene02 {
      top: calc(34vh - 120vh); } }
  @media all and (min-aspect-ratio: 8 / 6) and (min-height: 800px) {
    .js-heroScene02 {
      top: calc(36vh - 120vh); } }
  @media all and (min-width: 800px) {
    .js-heroScene02 {
      top: calc(36vh - 120vh); } }
  @media all and (min-width: 800px) and (min-aspect-ratio: 8 / 6) {
    .js-heroScene02 {
      top: calc(30vh - 120vh); } }
  @media all and (min-width: 800px) and (min-aspect-ratio: 8 / 6) and (min-height: 600px) {
    .js-heroScene02 {
      top: calc(32vh - 120vh); } }
  @media all and (min-width: 800px) and (min-aspect-ratio: 8 / 6) and (min-height: 700px) {
    .js-heroScene02 {
      top: calc(34vh - 120vh); } }
  @media all and (min-width: 800px) and (min-aspect-ratio: 8 / 6) and (min-height: 800px) {
    .js-heroScene02 {
      top: calc(36vh - 120vh); } }
  .js-heroScene02.-relative {
    position: relative;
    top: 0 !important;
    transform: none !important;
    height: auto; }

.p0-separatebox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  width: 100%; }
  .p0-separatebox_item {
    position: relative;
    width: 100%; }
    @media all and (min-width: 480px) {
      .p0-separatebox_item {
        width: 50%; } }
  .p0-separatebox.-frontAbout {
    gap: 7.5vw 0;
    z-index: 2;
    width: 100%;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 600px) {
      .p0-separatebox.-frontAbout {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        gap: 0 5vw; } }
    @media all and (min-width: 800px) {
      .p0-separatebox.-frontAbout {
        gap: 0 6.45161vw; } }
    @media all and (min-width: 1240px) {
      .p0-separatebox.-frontAbout {
        gap: 0 80px; } }
    .p0-separatebox.-frontAbout .p0-separatebox_item {
      display: block;
      width: 100%;
      box-sizing: border-box; }
      @media all and (min-width: 600px) {
        .p0-separatebox.-frontAbout .p0-separatebox_item {
          width: calc( (100% / 2) - (5vw / 2 * 1)); } }
      @media all and (min-width: 800px) {
        .p0-separatebox.-frontAbout .p0-separatebox_item {
          width: calc( (100% / 2) - (6.45161vw / 2 * 1)); } }
      @media all and (min-width: 1240px) {
        .p0-separatebox.-frontAbout .p0-separatebox_item {
          width: calc( (100% / 2) - (80px / 2 * 1)); } }
    .p0-separatebox.-frontAbout .p0-separatebox_img {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      width: 100%; }
      .p0-separatebox.-frontAbout .p0-separatebox_img img {
        width: 100%;
        height: auto; }
    .p0-separatebox.-frontAbout .p0-separatebox_band {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      padding: 10px 10px;
      box-sizing: border-box;
      background-image: url("../images/front/service/service_band_bg.png");
      background-position: center center;
      background-size: 100% 100%;
      background-repeat: no-repeat; }
      @media all and (min-width: 800px) {
        .p0-separatebox.-frontAbout .p0-separatebox_band {
          padding: 20px 20px; } }
  .p0-separatebox.-frontProduct {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    gap: 20px 0; }
    @media all and (min-width: 800px) {
      .p0-separatebox.-frontProduct {
        gap: 0 0px; } }
    .p0-separatebox.-frontProduct.-rev {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }
    .p0-separatebox.-frontProduct .p0-separatebox_item {
      width: 100%;
      box-sizing: border-box; }
      @media all and (min-width: 800px) {
        .p0-separatebox.-frontProduct .p0-separatebox_item {
          width: 50%; } }
      .p0-separatebox.-frontProduct .p0-separatebox_item.-ttlbox {
        width: 100%; }
        @media all and (min-width: 800px) {
          .p0-separatebox.-frontProduct .p0-separatebox_item.-ttlbox {
            width: calc(100% - 0px - 75.4%);
            padding: 0 20px; } }
      .p0-separatebox.-frontProduct .p0-separatebox_item.-productbox {
        width: 100%; }
        @media all and (min-width: 800px) {
          .p0-separatebox.-frontProduct .p0-separatebox_item.-productbox {
            width: 75.4%;
            padding-top: 60px; } }

.p0-decoration {
  display: block; }
  .p0-decoration img {
    display: block; }
  .p0-decoration.-border2 {
    margin-left: auto;
    margin-right: auto;
    padding-top: 3px;
    box-sizing: border-box;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; }
    .p0-decoration.-border2.-purple03_043 {
      border-top-color: rgba(186, 166, 217, 0.43);
      border-bottom-color: rgba(186, 166, 217, 0.43); }
    .p0-decoration.-border2.-w56 {
      width: 56px; }
  .p0-decoration.-figureBizrec01, .p0-decoration.-figureTgFeatures01 {
    position: absolute;
    bottom: -45vw;
    left: -7.5vw;
    transform: translate(0, 100%);
    width: 22vw;
    opacity: 0.24; }
    @media all and (min-width: 480px) {
      .p0-decoration.-figureBizrec01, .p0-decoration.-figureTgFeatures01 {
        bottom: -22.5vw;
        left: -2.5vw;
        transform: translate(0, 50%); } }
    @media all and (min-width: 600px) {
      .p0-decoration.-figureBizrec01, .p0-decoration.-figureTgFeatures01 {
        bottom: -110px;
        left: 7%; } }
    @media all and (min-width: 720px) {
      .p0-decoration.-figureBizrec01, .p0-decoration.-figureTgFeatures01 {
        left: 14.5%; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureBizrec01, .p0-decoration.-figureTgFeatures01 {
        bottom: auto;
        top: 50%;
        left: -40px;
        width: 176px;
        transform: translate(-100%, -50%); } }
    @media all and (min-width: 1240px) {
      .p0-decoration.-figureBizrec01, .p0-decoration.-figureTgFeatures01 {
        bottom: auto;
        top: 50%;
        left: -40px;
        width: 176px;
        transform: translate(-100%, -50%); } }
    .p0-decoration.-figureBizrec01 img, .p0-decoration.-figureTgFeatures01 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-figureBizrec02, .p0-decoration.-figureTgFeatures02 {
    position: absolute;
    top: 0;
    right: 14vw;
    transform: translate(100%, -62%);
    width: 17.375vw; }
    @media all and (min-width: 480px) {
      .p0-decoration.-figureBizrec02, .p0-decoration.-figureTgFeatures02 {
        right: 14.5vw;
        transform: translate(100%, -31%); } }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureBizrec02, .p0-decoration.-figureTgFeatures02 {
        right: 4.838709677419355vw;
        width: 139px; } }
    @media all and (min-width: 1240px) {
      .p0-decoration.-figureBizrec02, .p0-decoration.-figureTgFeatures02 {
        right: 60px; } }
    .p0-decoration.-figureBizrec02 img, .p0-decoration.-figureTgFeatures02 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-figureCompany {
    width: 101px; }
    .p0-decoration.-figureCompany img {
      width: 100%;
      height: auto; }
  .p0-decoration.-figureHistory {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: 1;
    transform: translate(50%, 0);
    width: 292px;
    opacity: 0.15; }
    @media all and (min-width: 600px) {
      .p0-decoration.-figureHistory {
        display: block; } }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureHistory {
        top: 50px;
        width: 366px; } }
    .p0-decoration.-figureHistory img {
      width: 100%;
      height: auto; }
  .p0-decoration.-figureOriginal02_01, .p0-decoration.-figureOriginal03_01 {
    position: absolute;
    top: calc(100% + 20vw);
    width: 22vw;
    opacity: 0.24; }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureOriginal02_01, .p0-decoration.-figureOriginal03_01 {
        top: 50%;
        width: 176px; } }
    @media all and (min-width: 1240px) {
      .p0-decoration.-figureOriginal02_01, .p0-decoration.-figureOriginal03_01 {
        width: 176px; } }
    .p0-decoration.-figureOriginal02_01 img, .p0-decoration.-figureOriginal03_01 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-figureOriginal02_01 {
    left: 0;
    transform: translate(-3.75vw, -50%); }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureOriginal02_01 {
        left: -40px;
        transform: translate(-100%, -50%); } }
    @media all and (min-width: 1240px) {
      .p0-decoration.-figureOriginal02_01 {
        left: -40px; } }
  .p0-decoration.-figureOriginal03_01 {
    right: 0;
    transform: translate(3.75vw, -50%); }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureOriginal03_01 {
        right: 20px;
        transform: translate(100%, -50%); } }
    @media all and (min-width: 1240px) {
      .p0-decoration.-figureOriginal03_01 {
        right: 20px; } }
  .p0-decoration.-figureOriginal02_02, .p0-decoration.-figureOriginal03_02 {
    position: absolute;
    top: 0;
    width: 17.375vw; }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureOriginal02_02, .p0-decoration.-figureOriginal03_02 {
        width: 139px; } }
    .p0-decoration.-figureOriginal02_02 img, .p0-decoration.-figureOriginal03_02 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-figureOriginal02_02 {
    right: 0;
    transform: translate(25%, -44%); }
    @media all and (min-width: 720px) {
      .p0-decoration.-figureOriginal02_02 {
        transform: translate(20%, -31%); } }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureOriginal02_02 {
        transform: translate(33%, -31%); } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-figureOriginal02_02 {
        transform: translate(50%, -31%); } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-figureOriginal02_02 {
        transform: translate(100%, -31%); } }
  .p0-decoration.-figureOriginal03_02 {
    left: 0;
    transform: translate(-25%, -44%) scaleX(-1); }
    @media all and (min-width: 720px) {
      .p0-decoration.-figureOriginal03_02 {
        transform: translate(-20%, -31%) scaleX(-1); } }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureOriginal03_02 {
        transform: translate(-33%, -31%) scaleX(-1); } }
    @media all and (min-width: 1200px) {
      .p0-decoration.-figureOriginal03_02 {
        transform: translate(-50%, -31%) scaleX(-1); } }
    @media all and (min-width: 1320px) {
      .p0-decoration.-figureOriginal03_02 {
        transform: translate(-100%, -31%) scaleX(-1); } }
  .p0-decoration.-figureContact {
    position: relative;
    width: 81px; }
    @media all and (min-width: 800px) {
      .p0-decoration.-figureContact {
        width: 101px; } }
    .p0-decoration.-figureContact img {
      width: 100%;
      height: auto; }
  .p0-decoration.-umbrella01 {
    display: block;
    width: 6px;
    height: 36px; }
    @media all and (min-width: 800px) {
      .p0-decoration.-umbrella01 {
        width: 7px;
        height: 44px; } }
    .p0-decoration.-umbrella01 img {
      width: 100%;
      height: auto; }
    .p0-decoration.-umbrella01.-history {
      transform: translateY(4px);
      margin-right: 3px; }
      @media all and (min-width: 800px) {
        .p0-decoration.-umbrella01.-history {
          transform: translateY(5px);
          margin-right: 4px; } }
    .p0-decoration.-umbrella01.-contactSubttl, .p0-decoration.-umbrella01.-privacySubttl {
      transform: translateY(2px);
      width: 4px;
      height: 22px;
      margin-left: 5px;
      margin-right: 0; }
      @media all and (min-width: 480px) {
        .p0-decoration.-umbrella01.-contactSubttl, .p0-decoration.-umbrella01.-privacySubttl {
          transform: translateY(3px);
          width: 6px;
          height: 33px;
          margin-left: 0;
          margin-right: 3px; } }
      @media all and (min-width: 800px) {
        .p0-decoration.-umbrella01.-contactSubttl, .p0-decoration.-umbrella01.-privacySubttl {
          transform: translateY(5px);
          width: 7px;
          height: 44px;
          margin-right: 4px; } }
  .p0-decoration.-umbrella02 {
    display: block;
    width: 46px;
    height: 8px; }
    @media all and (min-width: 800px) {
      .p0-decoration.-umbrella02 {
        width: 57px;
        height: 9px; } }
    .p0-decoration.-umbrella02.-bizrec {
      display: inline-block;
      margin-right: 14px; }
      @media all and (min-width: 800px) {
        .p0-decoration.-umbrella02.-bizrec {
          margin-right: 16px; } }
    .p0-decoration.-umbrella02 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-umbrella03 {
    display: inline-block;
    width: 32px;
    height: 36px;
    margin-bottom: 16px; }
    @media all and (min-width: 800px) {
      .p0-decoration.-umbrella03 {
        width: 40px;
        height: 44px;
        margin-bottom: 20px; } }
    .p0-decoration.-umbrella03 img {
      width: 100%;
      height: auto; }

.p0-front_scene03 {
  padding-top: 60px;
  padding-bottom: 60px; }
  @media all and (min-width: 800px) {
    .p0-front_scene03 {
      padding-top: 440px;
      padding-bottom: 120px; } }

.p0-front_about {
  position: relative; }
  .p0-front_about_service {
    position: relative;
    padding-bottom: 7.258064516129032vw;
    box-sizing: border-box; }
    @media all and (min-width: 1240px) {
      .p0-front_about_service {
        padding-bottom: 90px; } }
    .p0-front_about_service::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: calc(100% - 24.166666666666667vw);
      content: "";
      background-color: #28304f; }
      @media all and (min-width: 600px) {
        .p0-front_about_service::before {
          height: calc(100% - 11.290322580645161vw); } }
      @media all and (min-width: 1240px) {
        .p0-front_about_service::before {
          height: calc(100% - 140px); } }
      @media all and (min-width: 1360px) {
        .p0-front_about_service::before {
          width: calc(100vw - 60px - 60px); } }

.p0-front_philosophy {
  margin-top: -1px; }

.p0-front_businessrecord, .p0-front_tgFeatures {
  padding-bottom: calc(62.5vw - 30px);
  box-sizing: border-box; }
  @media all and (min-width: 480px) {
    .p0-front_businessrecord, .p0-front_tgFeatures {
      padding-bottom: 267px; } }
  @media all and (min-width: 800px) {
    .p0-front_businessrecord, .p0-front_tgFeatures {
      padding-bottom: 80px; } }
  .p0-front_businessrecord_box, .p0-front_tgFeatures_box {
    position: relative;
    width: 100%;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .p0-front_businessrecord_box, .p0-front_tgFeatures_box {
        transform: translateY(40px); } }
    @media all and (min-width: 800px) {
      .p0-front_businessrecord_box, .p0-front_tgFeatures_box {
        transform: translateY(40px);
        width: 69%; } }
    .p0-front_businessrecord_box::before, .p0-front_tgFeatures_box::before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      width: 100vw;
      height: 100%;
      content: "";
      background-color: #1d2235; }
  .p0-front_businessrecord_boxTtl, .p0-front_tgFeatures_boxTtl {
    position: absolute;
    top: 0;
    z-index: 1;
    transform: translate(0, -85%);
    font-family: 'Roboto Serif', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.9rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p0-front_businessrecord_boxTtl, .p0-front_tgFeatures_boxTtl {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p0-front_businessrecord_boxTtl, .p0-front_tgFeatures_boxTtl {
        transform: translate(0, -50%);
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p0-front_businessrecord_boxTtl, .p0-front_tgFeatures_boxTtl {
        font-size: 3rem; } }
    .p0-front_businessrecord_boxTtl.-left, .p0-front_tgFeatures_boxTtl.-left {
      left: 0; }
    .p0-front_businessrecord_boxTtl.-right, .p0-front_tgFeatures_boxTtl.-right {
      right: 0; }
  .p0-front_businessrecord_boxTxt, .p0-front_tgFeatures_boxTxt {
    position: relative;
    z-index: 1; }
  .p0-front_businessrecord_img, .p0-front_tgFeatures_img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% + 5vw); }
    @media all and (min-width: 480px) {
      .p0-front_businessrecord_img, .p0-front_tgFeatures_img {
        max-width: 400px; } }
    @media all and (min-width: 800px) {
      .p0-front_businessrecord_img, .p0-front_tgFeatures_img {
        position: relative;
        bottom: auto;
        right: auto;
        width: calc(100% - 69%); } }
    .p0-front_businessrecord_img img, .p0-front_tgFeatures_img img {
      transform: translate(5vw, calc(100% - 30px));
      height: auto; }
      @media all and (min-width: 480px) {
        .p0-front_businessrecord_img img, .p0-front_tgFeatures_img img {
          transform: translate(5vw, 100%); } }
      @media all and (min-width: 800px) {
        .p0-front_businessrecord_img img, .p0-front_tgFeatures_img img {
          transform: translate(-8.5%, calc(40px + 40px));
          width: 129.032258064516129%; } }

.p0-front_businessrecord_bg {
  width: 100%;
  height: 100%;
  padding-top: 45px;
  box-sizing: border-box;
  background-image: url("../images/front/businessrecord/businessrecord_bg.jpg");
  background-position: 61.25% top;
  background-size: cover;
  background-repeat: no-repeat; }
  @media all and (min-width: 375px) {
    .p0-front_businessrecord_bg {
      background-position: 60% top; } }
  @media all and (min-width: 480px) {
    .p0-front_businessrecord_bg {
      background-position: 57.5% top; } }
  @media all and (min-width: 600px) {
    .p0-front_businessrecord_bg {
      background-position: 52.5% top; } }
  @media all and (min-width: 720px) {
    .p0-front_businessrecord_bg {
      background-position: 50% top; } }
  @media all and (min-width: 800px) {
    .p0-front_businessrecord_bg {
      padding-top: 90px;
      background-position: 52.5% top; } }
  @media all and (min-width: 1000px) {
    .p0-front_businessrecord_bg {
      background-position: 50% top; } }
  @media all and (min-width: 1120px) {
    .p0-front_businessrecord_bg {
      background-position: 50% top; } }
  @media all and (min-width: 1200px) {
    .p0-front_businessrecord_bg {
      background-position: center top; } }
  @media all and (min-width: 1620px) {
    .p0-front_businessrecord_bg {
      background-size: auto 100%;
      background-repeat: repeat-x; } }

.p0-front_businessrecord_head {
  margin-bottom: 50px; }
  @media all and (min-width: 480px) {
    .p0-front_businessrecord_head {
      margin-bottom: 30px; } }
  @media all and (min-width: 800px) {
    .p0-front_businessrecord_head {
      margin-bottom: 30px; } }
  .p0-front_businessrecord_head p {
    padding: 4px 0;
    box-sizing: border-box; }

.p0-front_businessrecord_box {
  padding: calc(5vw - 7px) 5vw calc(5vw + 30px - 7px) 0; }
  @media all and (min-width: 480px) {
    .p0-front_businessrecord_box {
      padding: calc(5vw + 40px - 7px) 5vw calc(5vw + 40px - 7px) 0; } }
  @media all and (min-width: 800px) {
    .p0-front_businessrecord_box {
      padding: 70px 8% 70px 0; } }
  @media all and (min-width: 1120px) {
    .p0-front_businessrecord_box {
      padding: 70px 80px 70px 0; } }

.p0-front_tgFeatures_ttl {
  display: flex;
  position: absolute;
  top: 0;
  left: 0; }
  @media all and (min-width: 800px) {
    .p0-front_tgFeatures_ttl {
      left: 0; } }
  @media all and (min-width: 1120px) {
    .p0-front_tgFeatures_ttl {
      left: -0.806451612903226vw; } }
  @media all and (min-width: 1240px) {
    .p0-front_tgFeatures_ttl {
      left: -40px; } }
  .p0-front_tgFeatures_ttl_inner {
    position: relative;
    transform: translate(0, calc(-100% - 30px)); }
    @media all and (min-width: 800px) {
      .p0-front_tgFeatures_ttl_inner {
        transform: translate(0, -37%); } }

.p0-front_tgFeatures_box {
  padding: calc(5vw - 7px) 5vw calc(5vw + 30px - 7px) 0;
  background-color: #1d2235; }
  @media all and (min-width: 480px) {
    .p0-front_tgFeatures_box {
      padding: calc(5vw + 40px - 7px) 5vw calc(5vw + 40px - 7px) 0; } }
  @media all and (min-width: 800px) {
    .p0-front_tgFeatures_box {
      padding: 70px 8% 110px 70px; } }
  @media all and (min-width: 1120px) {
    .p0-front_tgFeatures_box {
      padding: 70px 80px 110px 70px; } }
  .p0-front_tgFeatures_box::after {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: -1;
    width: 100vw;
    height: 80px;
    content: "";
    background-color: #f8f8f8; }

@media all and (min-width: 800px) {
  .p0-front_tgFeatures_boxTtl {
    padding-left: 70px; } }

.p0-front_greetingsTtl {
  left: 0;
  margin-bottom: 30px; }
  @media all and (min-width: 800px) {
    .p0-front_greetingsTtl {
      left: 11.6%;
      margin-bottom: 120px; } }
  @media all and (min-width: 1240px) {
    .p0-front_greetingsTtl {
      left: 130px; } }
  @media all and (min-width: 800px) {
    .p0-front_greetingsTtl .p3-headline {
      height: calc(4em + 44px + 20px); } }
  .p0-front_greetingsTtl .p0-decoration.-umbrella03 {
    transform: translateY(5px);
    margin-right: 16px;
    margin-bottom: 0; }
    @media all and (min-width: 800px) {
      .p0-front_greetingsTtl .p0-decoration.-umbrella03 {
        transform: translateY(0);
        margin-right: 0;
        margin-bottom: 20px; } }

.p0-front_history {
  background-image: url("../images/front/history/history_bg.jpg");
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat; }

.p0-front_original_headTxt {
  padding-top: 0;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .p0-front_original_headTxt {
      padding-top: 11.693548387096774vw;
      padding-left: 2.016129032258065vw; } }
  @media all and (min-width: 1240px) {
    .p0-front_original_headTxt {
      padding-top: 145px;
      padding-left: 25px; } }

.p0-front_original_featuresTtl {
  position: absolute;
  top: 0;
  transform: translate(0, -50%); }
  .p0-front_original_featuresTtl.-left {
    left: 0; }
  .p0-front_original_featuresTtl.-right {
    right: 0; }

.p0-front_original_gridbox02, .p0-front_original_gridbox03 {
  position: relative;
  width: 84%;
  margin-top: -12.5vw; }
  @media all and (min-width: 800px) {
    .p0-front_original_gridbox02, .p0-front_original_gridbox03 {
      margin-top: -12.5vw; } }
  @media all and (min-width: 1200px) {
    .p0-front_original_gridbox02, .p0-front_original_gridbox03 {
      margin-top: -150px; } }
  .p0-front_original_gridbox02::before, .p0-front_original_gridbox03::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    width: calc(100% + 8px + 8px);
    height: calc(100% + 8px + 8px);
    content: "";
    background-color: #f8f8f8; }
    @media all and (min-width: 800px) {
      .p0-front_original_gridbox02::before, .p0-front_original_gridbox03::before {
        width: calc(100% + 10px + 10px);
        height: calc(100% + 10px + 10px); } }

.p0-front_original_gridbox02 {
  margin-left: auto;
  margin-right: -5vw; }
  @media all and (min-width: 800px) {
    .p0-front_original_gridbox02 {
      margin-right: -5vw; } }
  @media all and (min-width: 1200px) {
    .p0-front_original_gridbox02 {
      margin-right: -60px; } }

.p0-front_original_gridbox03 {
  margin-left: -5vw;
  margin-right: auto; }
  @media all and (min-width: 800px) {
    .p0-front_original_gridbox03 {
      margin-left: -5vw; } }
  @media all and (min-width: 1200px) {
    .p0-front_original_gridbox03 {
      margin-left: -60px; } }

.p1-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 5vw;
  z-index: 1200;
  height: 56px;
  box-sizing: border-box;
  mix-blend-mode: exclusion; }
  @media all and (min-width: 800px) {
    .p1-logo {
      height: 56px; } }
  @media all and (min-width: 1000px) {
    .p1-logo {
      height: 64px; } }
  @media all and (min-width: 1120px) {
    .p1-logo {
      left: 60px;
      height: 70px; } }
  .p1-logo_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: auto; }
  .p1-logo_img {
    display: block;
    width: 96px;
    height: auto; }
    @media all and (min-width: 800px) {
      .p1-logo_img {
        width: 96px; } }
    @media all and (min-width: 1000px) {
      .p1-logo_img {
        width: 108px; } }
    @media all and (min-width: 1120px) {
      .p1-logo_img {
        width: 120px; } }

.p1-drawer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  min-height: 105vh;
  overflow: scroll;
  transform: translateY(-105vh);
  padding: 80px 5vw 30px;
  box-sizing: border-box;
  background-color: transparent; }
  @media all and (max-height: 420px) {
    .p1-drawer {
      padding: 68px 5vw 15px; } }
  @media all and (min-width: 800px) {
    .p1-drawer {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      position: relative;
      height: 56px;
      overflow: hidden;
      transform: translateY(0) !important;
      padding: 0; } }
  @media all and (min-width: 1000px) {
    .p1-drawer {
      height: 64px; } }
  @media all and (min-width: 1120px) {
    .p1-drawer {
      height: 70px; } }
  .p1-drawer.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn; }
  .p1-drawer.is-close {
    animation: 0.4s ease-in 0s 1 both drawerOut; }
  .p1-drawer-open {
    /* ハンバーガーアイコンの設置スペース */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 10px;
    right: 5vw;
    z-index: 1200;
    /* 重なり順を一番上に */
    height: 40px;
    width: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
    mix-blend-mode: exclusion;
    /* ハンバーガーメニューのアイコン */ }
    @media all and (min-width: 800px) {
      .p1-drawer-open {
        display: none; } }
    .p1-drawer-open span, .p1-drawer-open span:before, .p1-drawer-open span:after {
      display: block;
      position: absolute;
      height: 3px;
      width: 25px;
      content: '';
      border-radius: 3px;
      background-color: #f1d979;
      transition: 0.5s; }
    .p1-drawer-open span::before {
      bottom: 8px;
      transform-origin: top left; }
    .p1-drawer-open span::after {
      top: 8px;
      transform-origin: bottom left; }
    .p1-drawer-open.is-open span {
      background-color: rgba(241, 217, 121, 0); }
    .p1-drawer-open.is-open span::before {
      transform: translate(5px, 0) rotate(45deg);
      background-color: #f1d979; }
    .p1-drawer-open.is-open span::after {
      transform: translate(5px, 1px) rotate(-45deg);
      background-color: #f1d979; }

.p1-gnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%; }
  @media all and (min-width: 800px) {
    .p1-gnav {
      color: white; } }
  .p1-gnav_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p1-gnav_content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        padding: 0; } }
  .p1-gnav_list {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 10px 10px;
    padding-right: 0;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p1-gnav_list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        gap: 0 15px;
        padding-right: 130px; } }
    @media all and (min-width: 1000px) {
      .p1-gnav_list {
        gap: 0 20px;
        padding-right: 160px; } }
    @media all and (min-width: 1120px) {
      .p1-gnav_list {
        padding-right: 200px; } }
  .p1-gnav_item {
    display: block;
    position: relative;
    width: 100%;
    box-sizing: border-box; }
    @media (min-aspect-ratio: 1 / 1) {
      .p1-gnav_item {
        width: calc(50% - (10px / 2)); } }
    @media all and (min-width: 800px) {
      .p1-gnav_item {
        width: auto !important; } }
  .p1-gnav_itemInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    gap: 5px 5px;
    height: 100%;
    min-height: 41px;
    padding: 8px 0 8px 25px;
    box-sizing: border-box;
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 2rem;
    line-height: 1;
    color: white;
    background-image: url("../images/common/deco/umbrella01_purple03.png");
    background-position: left center;
    background-size: auto 41px;
    background-repeat: no-repeat; }
    @media all and (max-height: 420px) {
      .p1-gnav_itemInner {
        min-height: 33px;
        padding: 6px 0 6px 20px;
        font-size: 1.6rem;
        background-size: auto 33px; } }
    @media all and (min-width: 800px) {
      .p1-gnav_itemInner {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 0 0;
        min-height: auto;
        padding: 0;
        font-family: 'Shippori Antique', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 1.28rem;
        text-align: center;
        color: inherit;
        background-image: none; } }
    @media all and (min-width: 1000px) {
      .p1-gnav_itemInner {
        font-size: 1.44rem; } }
    @media all and (min-width: 1120px) {
      .p1-gnav_itemInner {
        font-size: 1.6rem; } }
    .p1-gnav_itemInnerTxt {
      display: block; }
      .p1-gnav_itemInnerTxt.-en {
        display: block;
        font-family: 'Cormorant Garamond', serif;
        font-style: normal;
        font-weight: 500;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1;
        color: #baa6d9; }
        @media all and (min-width: 800px) {
          .p1-gnav_itemInnerTxt.-en {
            line-height: 1; } }
        @media all and (max-height: 420px) {
          .p1-gnav_itemInnerTxt.-en {
            font-size: 1.2rem; } }
        @media all and (min-width: 800px) {
          .p1-gnav_itemInnerTxt.-en {
            display: none; } }

.l-header.js-isscroll .p1-gnav {
  background: none; }
  @media all and (min-width: 800px) {
    .l-header.js-isscroll .p1-gnav {
      border-style: none; } }

.p1-contact {
  position: relative;
  width: 100%;
  height: 56px;
  min-height: 56px;
  box-sizing: border-box; }
  @media all and (min-width: 800px) {
    .p1-contact {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 1001;
      width: 130px;
      height: 56px; } }
  @media all and (min-width: 1000px) {
    .p1-contact {
      width: 160px;
      height: 64px; } }
  @media all and (min-width: 1120px) {
    .p1-contact {
      width: 200px;
      height: 70px; } }
  .p1-contact.-fixed {
    display: none; }
    @media all and (min-width: 800px) {
      .p1-contact.-fixed {
        display: block; } }
  .p1-contact.-drawer {
    display: block; }
    @media all and (min-width: 800px) {
      .p1-contact.-drawer {
        display: none; } }
  .p1-contact_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 8px;
    width: 100%;
    height: 100%;
    background-color: #334179; }
    @media all and (min-width: 800px) {
      .p1-contact_item {
        gap: 0 10px; } }
  .p1-contact_icon {
    width: 22px;
    transform: translateY(1px); }
    @media all and (min-width: 800px) {
      .p1-contact_icon {
        width: 15px; } }
    @media all and (min-width: 1000px) {
      .p1-contact_icon {
        width: 17px; } }
    @media all and (min-width: 1120px) {
      .p1-contact_icon {
        width: 19px; } }
    .p1-contact_icon img {
      width: 100%;
      height: auto; }
  .p1-contact_txt {
    font-family: 'Shippori Antique', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 1;
    color: white; }
    @media all and (min-width: 800px) {
      .p1-contact_txt {
        font-size: 1.28rem; } }
    @media all and (min-width: 1000px) {
      .p1-contact_txt {
        font-size: 1.44rem; } }
    @media all and (min-width: 1120px) {
      .p1-contact_txt {
        font-size: 1.6rem; } }

.p2-copyright {
  font-family: 'Crimson Text', serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 600;
  color: white;
  font-size: 1.04rem;
  text-align: center;
  line-height: 1; }
  @media all and (min-width: 800px) {
    .p2-copyright {
      font-size: 1.3rem; } }
  @media all and (min-width: 600px) {
    .p2-copyright {
      text-align: right; } }

.p2-ftinfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 20px 0;
  color: white; }
  @media all and (min-width: 600px) {
    .p2-ftinfo {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      gap: 0 0; } }
  .p2-ftinfo_item {
    width: 100%;
    box-sizing: border-box; }
    @media all and (min-width: 600px) {
      .p2-ftinfo_item {
        width: 50%; } }
    .p2-ftinfo_item.-item1 {
      display: block; }
    .p2-ftinfo_item.-item2 {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      gap: 15px 0; }
      @media all and (min-width: 800px) {
        .p2-ftinfo_item.-item2 {
          gap: 10px 0; } }
  .p2-ftinfo_txtbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 600px) {
      .p2-ftinfo_txtbox {
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start; } }
  .p2-ftinfo_txt.-name {
    margin-bottom: 6px;
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1.28rem; }
    @media all and (min-width: 800px) {
      .p2-ftinfo_txt.-name {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p2-ftinfo_txt.-name {
        margin-bottom: 12px; } }
  .p2-ftinfo_txt.-address, .p2-ftinfo_txt.-tel {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1.04rem;
    line-height: 1.6; }
    @media all and (min-width: 800px) {
      .p2-ftinfo_txt.-address, .p2-ftinfo_txt.-tel {
        font-size: 1.3rem; } }
    @media all and (min-width: 800px) {
      .p2-ftinfo_txt.-address, .p2-ftinfo_txt.-tel {
        line-height: 1.6; } }

.p2-ftnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 500;
  font-size: 1.04rem;
  line-height: 1; }
  @media all and (min-width: 800px) {
    .p2-ftnav {
      font-size: 1.3rem; } }
  @media all and (min-width: 800px) {
    .p2-ftnav {
      line-height: 1; } }
  @media all and (min-width: 600px) {
    .p2-ftnav {
      -webkit-justify-content: flex-end;
      justify-content: flex-end; } }
  .p2-ftnav_item {
    padding: 0 15px;
    border-left: 1px solid white; }
    @media all and (min-width: 800px) {
      .p2-ftnav_item {
        padding: 0 20px; } }
    @media all and (min-width: 1000px) {
      .p2-ftnav_item {
        padding: 0 30px; } }
    @media all and (min-width: 1120px) {
      .p2-ftnav_item {
        padding: 0 35px; } }
    .p2-ftnav_item:first-of-type {
      padding-left: 0;
      border-left-style: none; }
    .p2-ftnav_item:last-of-type {
      padding-right: 0; }
    .p2-ftnav_item a {
      display: block;
      padding-bottom: 1px;
      box-sizing: border-box; }

.p2-ftlogo {
  width: 65px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px; }
  @media all and (min-width: 600px) {
    .p2-ftlogo {
      margin-left: 0; } }
  @media all and (min-width: 800px) {
    .p2-ftlogo {
      margin-bottom: 20px; } }
  .p2-ftlogo img {
    width: 100%;
    height: auto; }

.p3-headline.-absolute {
  position: absolute; }

.p3-headline.-relative {
  position: relative; }

.p3-headline.-left {
  text-align: left; }

.p3-headline.-center {
  text-align: center; }

.p3-headline.-right {
  text-align: right; }

.p3-headline.-justify {
  text-align: justify; }

.p3-headline.-wh {
  color: white; }

.p3-headline.-bk {
  color: black; }

.p3-headline.-white01 {
  color: #f8f8f8; }

.p3-headline.-white02 {
  color: #f4f4f4; }

.p3-headline.-black01 {
  color: #333333; }

.p3-headline.-gray01 {
  color: #d7dae3; }

.p3-headline.-gray02 {
  color: #dbdbdb; }

.p3-headline.-gray03 {
  color: #a6a7b0; }

.p3-headline.-blue01 {
  color: #1d2235; }

.p3-headline.-blue02 {
  color: #28304f; }

.p3-headline.-blue03 {
  color: #334179; }

.p3-headline.-blue04 {
  color: #4150b1; }

.p3-headline.-blue05 {
  color: #dde2f6; }

.p3-headline.-purple01 {
  color: #8766ac; }

.p3-headline.-purple02 {
  color: #9783b5; }

.p3-headline.-purple03 {
  color: #baa6d9; }

.p3-headline.-orange01 {
  color: #f6bf75; }

.p3-headline.-red01 {
  color: #d77185; }

.p3-headline.-yellow01 {
  color: #f1d979; }

.p3-headline.-lsm050 {
  letter-spacing: -0.5em; }

.p3-headline.-lsm025 {
  letter-spacing: -0.25em; }

.p3-headline.-ls008 {
  letter-spacing: 0.08em; }

.p3-headline.-ls010 {
  letter-spacing: 0.1em; }

.p3-headline.-ls025 {
  letter-spacing: 0.25em; }

.p3-headline.-col2 div {
  column-count: 1; }
  @media all and (min-width: 800px) {
    .p3-headline.-col2 div {
      column-count: 2;
      column-gap: 5vw; } }
  @media all and (min-width: 1200px) {
    .p3-headline.-col2 div {
      column-gap: 60px; } }

.p3-headline.-tategaki {
  writing-mode: vertical-rl;
  text-align: left;
  text-orientation: "upright"; }
  .p3-headline.-tategaki .combine {
    text-combine-upright: all; }
  .p3-headline.-tategaki.-mixed {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: "mixed"; }
    .p3-headline.-tategaki.-mixed .combine {
      text-combine-upright: all; }
  .p3-headline.-tategaki.-sideways {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: "sideways"; }
    .p3-headline.-tategaki.-sideways .combine {
      text-combine-upright: all; }
  .p3-headline.-tategaki.-center {
    margin-left: auto;
    margin-right: auto; }

@media all and (min-width: 800px) {
  .p3-headline.-pcTate_spYoko {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: "upright"; }
    .p3-headline.-pcTate_spYoko .combine {
      text-combine-upright: all; } }

@media all and (min-width: 800px) {
  .p3-headline.-pcTate_spYoko.-mixed {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: "mixed"; }
    .p3-headline.-pcTate_spYoko.-mixed .combine {
      text-combine-upright: all; } }

@media all and (min-width: 800px) {
  .p3-headline.-pcTate_spYoko.-sideways {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: "sideways"; }
    .p3-headline.-pcTate_spYoko.-sideways .combine {
      text-combine-upright: all; } }

.p3-headline.-annotation {
  font-size: 50%;
  vertical-align: super; }

.p3-headline.-ttl01 {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 3.22rem;
  line-height: 1.5; }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl01 {
      line-height: 1.5; } }
  .p3-headline.-ttl01.-medium {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500; }
  .p3-headline.-ttl01.-semibold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600; }
  .p3-headline.-ttl01.-bold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700; }
  .p3-headline.-ttl01.-extrabold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 800; }
  .p3-headline.-ttl01.-lh100 {
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl01.-lh100 {
        line-height: 1; } }
  .p3-headline.-ttl01.-lh140 {
    line-height: 1.4; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl01.-lh140 {
        line-height: 1.4; } }
  @media all and (min-width: 600px) {
    .p3-headline.-ttl01 {
      font-size: 3.68rem; } }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl01 {
      font-size: 4.6rem; } }

.p3-headline.-ttl02 {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 2.52rem;
  line-height: 1.5; }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl02 {
      line-height: 1.5; } }
  .p3-headline.-ttl02.-medium {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500; }
  .p3-headline.-ttl02.-semibold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600; }
  .p3-headline.-ttl02.-bold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700; }
  .p3-headline.-ttl02.-extrabold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 800; }
  .p3-headline.-ttl02.-lh100 {
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl02.-lh100 {
        line-height: 1; } }
  @media all and (min-width: 600px) {
    .p3-headline.-ttl02 {
      font-size: 2.88rem; } }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl02 {
      font-size: 3.6rem; } }

.p3-headline.-ttl03 {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 2.24rem;
  line-height: 1.5; }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl03 {
      line-height: 1.5; } }
  .p3-headline.-ttl03.-medium {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500; }
  .p3-headline.-ttl03.-semibold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600; }
  .p3-headline.-ttl03.-bold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700; }
  .p3-headline.-ttl03.-extrabold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 800; }
  .p3-headline.-ttl03.-lh100 {
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl03.-lh100 {
        line-height: 1; } }
  @media all and (min-width: 600px) {
    .p3-headline.-ttl03 {
      font-size: 2.56rem; } }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl03 {
      font-size: 3.2rem; } }

.p3-headline.-ttl04 {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 1.92rem;
  line-height: 1.5; }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl04 {
      font-size: 2.4rem; } }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl04 {
      line-height: 1.5; } }
  .p3-headline.-ttl04.-medium {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500; }
  .p3-headline.-ttl04.-semibold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600; }
  .p3-headline.-ttl04.-bold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700; }
  .p3-headline.-ttl04.-extrabold {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 800; }
  .p3-headline.-ttl04.-lh100 {
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl04.-lh100 {
        line-height: 1; } }

.p3-headline.-ttl71 {
  font-family: 'Crimson Text', serif;
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  font-size: 1.92rem;
  line-height: 1; }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl71 {
      font-size: 2.4rem; } }
  @media all and (min-width: 800px) {
    .p3-headline.-ttl71 {
      line-height: 1; } }
  .p3-headline.-ttl71.-semibold {
    font-family: 'Crimson Text', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600; }
  .p3-headline.-ttl71.-bold {
    font-family: 'Crimson Text', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 700; }
  .p3-headline.-ttl71.-lh100 {
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-headline.-ttl71.-lh100 {
        line-height: 1; } }

.p3-box {
  position: relative;
  box-sizing: border-box; }
  .p3-box.-frontLineupLabel {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 3px 10px;
    background-color: #f8f8f8; }
  .p3-box.-frontFeatures {
    position: relative;
    padding-top: 45px;
    padding-bottom: 18.333333333333333vw; }
    @media all and (min-width: 800px) {
      .p3-box.-frontFeatures {
        padding-top: 70px;
        padding-bottom: 18.333333333333333vw; } }
    @media all and (min-width: 1200px) {
      .p3-box.-frontFeatures {
        padding-bottom: 220px; } }
    .p3-box.-frontFeatures::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 100%;
      content: "";
      background-color: #1d2235; }
  .p3-box.-contactSubttl, .p3-box.-privacySubttl {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    gap: 5px 14px; }
    @media all and (min-width: 375px) {
      .p3-box.-contactSubttl, .p3-box.-privacySubttl {
        gap: 5px 14px; } }
    @media all and (min-width: 480px) {
      .p3-box.-contactSubttl, .p3-box.-privacySubttl {
        gap: 5px 20px; } }
    @media all and (min-width: 800px) {
      .p3-box.-contactSubttl, .p3-box.-privacySubttl {
        gap: 0 28px; } }
    .p3-box.-contactSubttl .p3-box_item.-jp, .p3-box.-privacySubttl .p3-box_item.-jp {
      width: 100%; }
      @media all and (min-width: 480px) {
        .p3-box.-contactSubttl .p3-box_item.-jp, .p3-box.-privacySubttl .p3-box_item.-jp {
          width: auto; } }

.p3-text {
  /*
  ///// リンク /////
  &.-link01 {
    color: color-txt08(1);
    text-decoration: underline;
  }
*/
  /*
  &.-list02 {
    padding-left: 5px;
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "◎";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "※";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list04 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-ntsan("r");
    @include fontSize(1.5, 1.4);
    color: color-txt01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }
*/ }
  .p3-text.-absolute {
    position: absolute; }
  .p3-text.-relative {
    position: relative; }
  .p3-text.-left {
    text-align: left; }
  .p3-text.-center {
    text-align: center; }
  .p3-text.-right {
    text-align: right; }
  .p3-text.-justify {
    text-align: justify; }
  .p3-text.-wh {
    color: white; }
  .p3-text.-bk {
    color: black; }
  .p3-text.-white01 {
    color: #f8f8f8; }
  .p3-text.-white02 {
    color: #f4f4f4; }
  .p3-text.-black01 {
    color: #333333; }
  .p3-text.-gray01 {
    color: #d7dae3; }
  .p3-text.-gray02 {
    color: #dbdbdb; }
  .p3-text.-gray03 {
    color: #a6a7b0; }
  .p3-text.-blue01 {
    color: #1d2235; }
  .p3-text.-blue02 {
    color: #28304f; }
  .p3-text.-blue03 {
    color: #334179; }
  .p3-text.-blue04 {
    color: #4150b1; }
  .p3-text.-blue05 {
    color: #dde2f6; }
  .p3-text.-purple01 {
    color: #8766ac; }
  .p3-text.-purple02 {
    color: #9783b5; }
  .p3-text.-purple03 {
    color: #baa6d9; }
  .p3-text.-orange01 {
    color: #f6bf75; }
  .p3-text.-red01 {
    color: #d77185; }
  .p3-text.-yellow01 {
    color: #f1d979; }
  .p3-text.-exclusion {
    mix-blend-mode: exclusion; }
  .p3-text.-fs060per {
    font-size: 60%; }
  .p3-text.-lsm050 {
    letter-spacing: -0.5em; }
  .p3-text.-lsm025 {
    letter-spacing: -0.25em; }
  .p3-text.-ls008 {
    letter-spacing: 0.08em; }
  .p3-text.-ls010 {
    letter-spacing: 0.1em; }
  .p3-text.-ls025 {
    letter-spacing: 0.25em; }
  .p3-text.-col2 div {
    column-count: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-col2 div {
        column-count: 2;
        column-gap: 5vw; } }
    @media all and (min-width: 1200px) {
      .p3-text.-col2 div {
        column-gap: 60px; } }
  .p3-text.-tategaki {
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: "upright"; }
    .p3-text.-tategaki .combine {
      text-combine-upright: all; }
    .p3-text.-tategaki.-mixed {
      writing-mode: vertical-rl;
      text-align: left;
      text-orientation: "mixed"; }
      .p3-text.-tategaki.-mixed .combine {
        text-combine-upright: all; }
    .p3-text.-tategaki.-sideways {
      writing-mode: vertical-rl;
      text-align: left;
      text-orientation: "sideways"; }
      .p3-text.-tategaki.-sideways .combine {
        text-combine-upright: all; }
    .p3-text.-tategaki.-center {
      margin-left: auto;
      margin-right: auto; }
  @media all and (min-width: 800px) {
    .p3-text.-pcTate_spYoko {
      writing-mode: vertical-rl;
      text-align: left;
      text-orientation: "upright"; }
      .p3-text.-pcTate_spYoko .combine {
        text-combine-upright: all; } }
  @media all and (min-width: 800px) {
    .p3-text.-pcTate_spYoko.-mixed {
      writing-mode: vertical-rl;
      text-align: left;
      text-orientation: "mixed"; }
      .p3-text.-pcTate_spYoko.-mixed .combine {
        text-combine-upright: all; } }
  @media all and (min-width: 800px) {
    .p3-text.-pcTate_spYoko.-sideways {
      writing-mode: vertical-rl;
      text-align: left;
      text-orientation: "sideways"; }
      .p3-text.-pcTate_spYoko.-sideways .combine {
        text-combine-upright: all; } }
  .p3-text.-tateOff {
    writing-mode: horizontal-tb;
    text-align: inherit;
    text-orientation: inherit; }
    .p3-text.-tateOff .combine {
      text-combine-upright: none; }
  .p3-text.-annotation {
    font-size: 50%;
    vertical-align: super; }
  .p3-text.-t01 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p3-text.-t01 {
        font-size: 1rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t01 {
        line-height: 1.8; } }
    .p3-text.-t01.-regular {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 400; }
    .p3-text.-t01.-medium {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t01.-bold {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t01.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t01.-lh100 {
          line-height: 1; } }
  .p3-text.-t02 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.04rem;
    line-height: 2.3; }
    @media all and (min-width: 800px) {
      .p3-text.-t02 {
        font-size: 1.3rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t02 {
        line-height: 2.3; } }
    .p3-text.-t02.-medium {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t02.-bold {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t02.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t02.-lh100 {
          line-height: 1; } }
    .p3-text.-t02.-lh140 {
      line-height: 1.4; }
      @media all and (min-width: 800px) {
        .p3-text.-t02.-lh140 {
          line-height: 1.4; } }
    .p3-text.-t02.-lh180 {
      line-height: 1.8; }
      @media all and (min-width: 800px) {
        .p3-text.-t02.-lh180 {
          line-height: 1.8; } }
  .p3-text.-t03 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t03 {
        font-size: 1.5rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t03 {
        line-height: 2; } }
    .p3-text.-t03.-medium {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t03.-bold {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t03.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t03.-lh100 {
          line-height: 1; } }
  .p3-text.-t04 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 2.4; }
    @media all and (min-width: 800px) {
      .p3-text.-t04 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t04 {
        line-height: 2.4; } }
    .p3-text.-t04.-medium {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t04.-bold {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t04.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t04.-lh100 {
          line-height: 1; } }
    .p3-text.-t04.-lh180 {
      line-height: 1.8; }
      @media all and (min-width: 800px) {
        .p3-text.-t04.-lh180 {
          line-height: 1.8; } }
  .p3-text.-t05 {
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t05 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t05 {
        line-height: 2; } }
    .p3-text.-t05.-medium {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t05.-bold {
      font-family: 'Noto Sans JP', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t05.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t05.-lh100 {
          line-height: 1; } }
  .p3-text.-t21 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 2.1; }
    @media all and (min-width: 800px) {
      .p3-text.-t21 {
        font-size: 1.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t21 {
        line-height: 2.1; } }
    .p3-text.-t21.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t21.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t21.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t21.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t21.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t21.-lh100 {
          line-height: 1; } }
  .p3-text.-t22 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t22 {
        font-size: 1.5rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t22 {
        line-height: 2; } }
    .p3-text.-t22.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t22.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t22.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t22.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t22.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t22.-lh100 {
          line-height: 1; } }
  .p3-text.-t23 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p3-text.-t23 {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t23 {
        line-height: 1.8; } }
    .p3-text.-t23.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t23.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t23.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t23.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t23.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t23.-lh100 {
          line-height: 1; } }
  .p3-text.-t24 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.36rem;
    line-height: 2.3; }
    @media all and (min-width: 800px) {
      .p3-text.-t24 {
        font-size: 1.7rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t24 {
        line-height: 2.3; } }
    .p3-text.-t24.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t24.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t24.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t24.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t24.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t24.-lh100 {
          line-height: 1; } }
    .p3-text.-t24.-lh180 {
      line-height: 1.8; }
      @media all and (min-width: 800px) {
        .p3-text.-t24.-lh180 {
          line-height: 1.8; } }
    .p3-text.-t24.-lh200 {
      line-height: 2; }
      @media all and (min-width: 800px) {
        .p3-text.-t24.-lh200 {
          line-height: 2; } }
    .p3-text.-t24.-lh210 {
      line-height: 2.1; }
      @media all and (min-width: 800px) {
        .p3-text.-t24.-lh210 {
          line-height: 2.1; } }
  .p3-text.-t25 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t25 {
        font-size: 2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t25 {
        line-height: 2; } }
    .p3-text.-t25.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t25.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t25.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t25.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t25.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t25.-lh100 {
          line-height: 1; } }
    .p3-text.-t25.-lh210 {
      line-height: 2.1; }
      @media all and (min-width: 800px) {
        .p3-text.-t25.-lh210 {
          line-height: 2.1; } }
  .p3-text.-t26 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.92rem;
    line-height: 2.3; }
    @media all and (min-width: 800px) {
      .p3-text.-t26 {
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t26 {
        line-height: 2.3; } }
    .p3-text.-t26.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t26.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t26.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t26.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t26.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t26.-lh100 {
          line-height: 1; } }
    .p3-text.-t26.-lh130 {
      line-height: 1.3; }
      @media all and (min-width: 800px) {
        .p3-text.-t26.-lh130 {
          line-height: 1.3; } }
    .p3-text.-t26.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 800px) {
        .p3-text.-t26.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t26.-tgFeatures {
      font-size: 1.92rem; }
      @media all and (min-width: 800px) {
        .p3-text.-t26.-tgFeatures {
          font-size: 2.16rem; } }
      @media all and (min-width: 1120px) {
        .p3-text.-t26.-tgFeatures {
          font-size: 2.4rem; } }
  .p3-text.-t27 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 2.1rem;
    line-height: 2.1; }
    @media all and (min-width: 800px) {
      .p3-text.-t27 {
        line-height: 2.1; } }
    .p3-text.-t27.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t27.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t27.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t27.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t27.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t27.-lh100 {
          line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t27 {
        font-size: 2.24rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t27 {
        font-size: 2.8rem; } }
  .p3-text.-t28 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 2.175rem;
    line-height: 1.9; }
    @media all and (min-width: 800px) {
      .p3-text.-t28 {
        line-height: 1.9; } }
    .p3-text.-t28.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t28.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t28.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t28.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t28.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t28.-lh100 {
          line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t28 {
        font-size: 2.32rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t28 {
        font-size: 2.9rem; } }
  .p3-text.-t29 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 2.24rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t29 {
        line-height: 2; } }
    .p3-text.-t29.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t29.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t29.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t29.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t29.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t29.-lh100 {
          line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t29 {
        font-size: 2.56rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t29 {
        font-size: 3.2rem; } }
  .p3-text.-t30 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 2.52rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t30 {
        line-height: 2; } }
    .p3-text.-t30.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t30.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t30.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t30.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t30.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t30.-lh100 {
          line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t30 {
        font-size: 2.88rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t30 {
        font-size: 3.6rem; } }
  .p3-text.-t31 {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 3.22rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t31 {
        line-height: 2; } }
    .p3-text.-t31.-medium {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t31.-semibold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t31.-bold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t31.-extrabold {
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
    .p3-text.-t31.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t31.-lh100 {
          line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t31 {
        font-size: 3.68rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t31 {
        font-size: 4.6rem; } }
  .p3-text.-t21.-yakuhanmp, .p3-text.-t22.-yakuhanmp, .p3-text.-t23.-yakuhanmp, .p3-text.-t24.-yakuhanmp, .p3-text.-t25.-yakuhanmp, .p3-text.-t26.-yakuhanmp, .p3-text.-t27.-yakuhanmp, .p3-text.-t28.-yakuhanmp, .p3-text.-t29.-yakuhanmp, .p3-text.-t30.-yakuhanmp, .p3-text.-t31.-yakuhanmp, .p3-text.-t32.-yakuhanmp, .p3-text.-t33.-yakuhanmp, .p3-text.-t34.-yakuhanmp, .p3-text.-t35.-yakuhanmp, .p3-text.-t36.-yakuhanmp, .p3-text.-t37.-yakuhanmp, .p3-text.-t38.-yakuhanmp, .p3-text.-t39.-yakuhanmp {
    font-family: YakuHanMP, 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400; }
    .p3-text.-t21.-yakuhanmp.-medium, .p3-text.-t22.-yakuhanmp.-medium, .p3-text.-t23.-yakuhanmp.-medium, .p3-text.-t24.-yakuhanmp.-medium, .p3-text.-t25.-yakuhanmp.-medium, .p3-text.-t26.-yakuhanmp.-medium, .p3-text.-t27.-yakuhanmp.-medium, .p3-text.-t28.-yakuhanmp.-medium, .p3-text.-t29.-yakuhanmp.-medium, .p3-text.-t30.-yakuhanmp.-medium, .p3-text.-t31.-yakuhanmp.-medium, .p3-text.-t32.-yakuhanmp.-medium, .p3-text.-t33.-yakuhanmp.-medium, .p3-text.-t34.-yakuhanmp.-medium, .p3-text.-t35.-yakuhanmp.-medium, .p3-text.-t36.-yakuhanmp.-medium, .p3-text.-t37.-yakuhanmp.-medium, .p3-text.-t38.-yakuhanmp.-medium, .p3-text.-t39.-yakuhanmp.-medium {
      font-family: YakuHanMP, 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 500; }
    .p3-text.-t21.-yakuhanmp.-semibold, .p3-text.-t22.-yakuhanmp.-semibold, .p3-text.-t23.-yakuhanmp.-semibold, .p3-text.-t24.-yakuhanmp.-semibold, .p3-text.-t25.-yakuhanmp.-semibold, .p3-text.-t26.-yakuhanmp.-semibold, .p3-text.-t27.-yakuhanmp.-semibold, .p3-text.-t28.-yakuhanmp.-semibold, .p3-text.-t29.-yakuhanmp.-semibold, .p3-text.-t30.-yakuhanmp.-semibold, .p3-text.-t31.-yakuhanmp.-semibold, .p3-text.-t32.-yakuhanmp.-semibold, .p3-text.-t33.-yakuhanmp.-semibold, .p3-text.-t34.-yakuhanmp.-semibold, .p3-text.-t35.-yakuhanmp.-semibold, .p3-text.-t36.-yakuhanmp.-semibold, .p3-text.-t37.-yakuhanmp.-semibold, .p3-text.-t38.-yakuhanmp.-semibold, .p3-text.-t39.-yakuhanmp.-semibold {
      font-family: YakuHanMP, 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t21.-yakuhanmp.-bold, .p3-text.-t22.-yakuhanmp.-bold, .p3-text.-t23.-yakuhanmp.-bold, .p3-text.-t24.-yakuhanmp.-bold, .p3-text.-t25.-yakuhanmp.-bold, .p3-text.-t26.-yakuhanmp.-bold, .p3-text.-t27.-yakuhanmp.-bold, .p3-text.-t28.-yakuhanmp.-bold, .p3-text.-t29.-yakuhanmp.-bold, .p3-text.-t30.-yakuhanmp.-bold, .p3-text.-t31.-yakuhanmp.-bold, .p3-text.-t32.-yakuhanmp.-bold, .p3-text.-t33.-yakuhanmp.-bold, .p3-text.-t34.-yakuhanmp.-bold, .p3-text.-t35.-yakuhanmp.-bold, .p3-text.-t36.-yakuhanmp.-bold, .p3-text.-t37.-yakuhanmp.-bold, .p3-text.-t38.-yakuhanmp.-bold, .p3-text.-t39.-yakuhanmp.-bold {
      font-family: YakuHanMP, 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t21.-yakuhanmp.-extrabold, .p3-text.-t22.-yakuhanmp.-extrabold, .p3-text.-t23.-yakuhanmp.-extrabold, .p3-text.-t24.-yakuhanmp.-extrabold, .p3-text.-t25.-yakuhanmp.-extrabold, .p3-text.-t26.-yakuhanmp.-extrabold, .p3-text.-t27.-yakuhanmp.-extrabold, .p3-text.-t28.-yakuhanmp.-extrabold, .p3-text.-t29.-yakuhanmp.-extrabold, .p3-text.-t30.-yakuhanmp.-extrabold, .p3-text.-t31.-yakuhanmp.-extrabold, .p3-text.-t32.-yakuhanmp.-extrabold, .p3-text.-t33.-yakuhanmp.-extrabold, .p3-text.-t34.-yakuhanmp.-extrabold, .p3-text.-t35.-yakuhanmp.-extrabold, .p3-text.-t36.-yakuhanmp.-extrabold, .p3-text.-t37.-yakuhanmp.-extrabold, .p3-text.-t38.-yakuhanmp.-extrabold, .p3-text.-t39.-yakuhanmp.-extrabold {
      font-family: YakuHanMP, 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 800; }
  .p3-text.-t41 {
    font-family: 'Shippori Antique', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.92rem;
    line-height: 2; }
    @media all and (min-width: 800px) {
      .p3-text.-t41 {
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t41 {
        line-height: 2; } }
    .p3-text.-t41.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t41.-lh100 {
          line-height: 1; } }
  .p3-text.-t51 {
    font-family: 'Cormorant Garamond', serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t51 {
        font-size: 2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t51 {
        line-height: 1; } }
    .p3-text.-t51.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t51.-lh100 {
          line-height: 1; } }
  .p3-text.-t52 {
    font-family: 'Cormorant Garamond', serif;
    font-style: normal;
    font-weight: 500;
    font-weight: 500;
    font-size: 1.92rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t52 {
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t52 {
        line-height: 1; } }
    .p3-text.-t52.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t52.-lh100 {
          line-height: 1; } }
  .p3-text.-t61 {
    font-family: 'Roboto Serif', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 2.25rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t61 {
        line-height: 1; } }
    .p3-text.-t61.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t61.-lh100 {
          line-height: 1; } }
    @media all and (min-width: 600px) {
      .p3-text.-t61 {
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t61 {
        font-size: 3rem; } }
  .p3-text.-t71 {
    font-family: 'Crimson Text', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t71 {
        font-size: 2rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t71 {
        line-height: 1; } }
    .p3-text.-t71.-semibold {
      font-family: 'Crimson Text', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t71.-bold {
      font-family: 'Crimson Text', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t71.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t71.-lh100 {
          line-height: 1; } }
  .p3-text.-t72 {
    font-family: 'Crimson Text', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.92rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p3-text.-t72 {
        font-size: 2.4rem; } }
    @media all and (min-width: 800px) {
      .p3-text.-t72 {
        line-height: 1; } }
    .p3-text.-t72.-semibold {
      font-family: 'Crimson Text', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600; }
    .p3-text.-t72.-bold {
      font-family: 'Crimson Text', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 700; }
    .p3-text.-t72.-lh100 {
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p3-text.-t72.-lh100 {
          line-height: 1; } }
  .p3-text.-list01 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box; }
    .p3-text.-list01 li {
      list-style-type: inherit; }

.p3-img.-animeLogo {
  position: relative;
  width: 189px;
  height: 62px;
  margin-left: auto;
  margin-right: auto; }
  @media all and (min-width: 800px) {
    .p3-img.-animeLogo {
      width: 189px;
      height: 62px; } }
  .p3-img.-animeLogo img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto; }

.p3-img.-frontScene02 {
  display: block;
  position: absolute;
  top: -3.5vh;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 104px;
  height: 80px; }
  @media all and (min-width: 800px) and (min-height: 600px) {
    .p3-img.-frontScene02 {
      top: -7vh;
      width: 156px;
      height: 120px; } }
  @media all and (min-width: 800px) and (min-height: 1000px) {
    .p3-img.-frontScene02 {
      top: -70px;
      width: 209px;
      height: 160px; } }
  .p3-img.-frontScene02 img {
    width: 100%;
    height: auto; }

.p3-img.-frontScene0301 {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -25%);
  width: 87.5vw;
  height: 43.839285714285714vw; }
  @media all and (min-width: 1120px) {
    .p3-img.-frontScene0301 {
      top: 0;
      width: 980px;
      height: 491px; } }
  .p3-img.-frontScene0301 img {
    width: 100%;
    height: auto; }

.p3-img.-frontScene0302Wrap {
  display: block;
  position: relative;
  margin-top: -22vh; }
  @media all and (min-width: 800px) {
    .p3-img.-frontScene0302Wrap {
      margin-top: 0; } }
  .p3-img.-frontScene0302Wrap::before, .p3-img.-frontScene0302Wrap::after {
    display: block;
    position: relative;
    content: ""; }

.p3-img.-frontScene0302 {
  height: calc(300px * 5); }
  @media all and (min-width: 800px) {
    .p3-img.-frontScene0302 {
      height: calc(610px * 5); } }
  .p3-img.-frontScene0302 div {
    display: block;
    position: relative;
    width: 49vw;
    height: 75vh;
    top: 12.5vh;
    margin: auto;
    background-image: url("../images/front/kv/scene03_img02_sp.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-transition: background-image .3s linear;
    transition: background-image .3s linear; }
    @media all and (min-width: 800px) {
      .p3-img.-frontScene0302 div {
        width: 35vw;
        background-image: url("../images/front/kv/scene03_img02.jpg"); } }

.p3-img.-frontLineupSubttl {
  width: 101px;
  height: 24px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontLineupSubttl {
      width: 101px;
      height: 30px; } }
  .p3-img.-frontLineupSubttl img {
    width: 100%;
    height: auto; }

.p3-img.-frontLineupImg {
  width: 100%;
  height: 100%; }
  .p3-img.-frontLineupImg img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p3-img.-frontLineupRegular {
  position: absolute;
  bottom: -26px;
  right: 0;
  transform: translate(0, 0);
  width: 93px;
  height: 51px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontLineupRegular {
      bottom: -3.225806451612903vw;
      right: -3.225806451612903vw;
      transform: translate(0, 100%);
      width: 15vw;
      height: 8.225806451612903vw; } }
  @media all and (min-width: 1240px) {
    .p3-img.-frontLineupRegular {
      bottom: -40px;
      right: -40px;
      width: 186px;
      height: 102px; } }
  .p3-img.-frontLineupRegular img {
    width: 100%;
    height: auto; }

.p3-img.-frontLineupSeasonal {
  position: absolute;
  bottom: -31px;
  right: 0;
  transform: translate(0, 0);
  width: 80px;
  height: 108px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontLineupSeasonal {
      right: auto;
      bottom: -0.806451612903226vw;
      left: -0.806451612903226vw;
      transform: translate(0, 100%);
      width: 12.82258064516129vw;
      height: 17.338709677419355vw; } }
  @media all and (min-width: 1240px) {
    .p3-img.-frontLineupSeasonal {
      bottom: -10px;
      left: -10px;
      width: 159px;
      height: 215px; } }
  .p3-img.-frontLineupSeasonal img {
    width: 100%;
    height: auto; }

.p3-img.-frontLineupOriginal {
  position: absolute;
  bottom: -31px;
  right: 0;
  transform: translate(0, 0);
  width: 71px;
  height: 73px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontLineupOriginal {
      bottom: -0.806451612903226vw;
      right: -2.419354838709677vw;
      transform: translate(0, 100%);
      width: 11.451612903225806vw;
      height: 11.774193548387097vw; } }
  @media all and (min-width: 1240px) {
    .p3-img.-frontLineupOriginal {
      bottom: -10px;
      right: -30px;
      width: 142px;
      height: 146px; } }
  .p3-img.-frontLineupOriginal img {
    width: 100%;
    height: auto; }

.p3-img.-frontGreetings {
  position: relative;
  margin-bottom: 15px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontGreetings {
      position: absolute;
      top: 30px;
      right: 0;
      width: 71.4%;
      margin-bottom: 0; } }

.p3-img.-frontOriginalTkBg {
  position: relative;
  width: 100%;
  height: 100%; }
  .p3-img.-frontOriginalTkBg img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p3-img.-frontOriginalTkTxt {
  display: block;
  position: absolute;
  width: 25%;
  height: 24.13793%; }
  .p3-img.-frontOriginalTkTxt.-tsue {
    bottom: 5vw;
    left: 5vw; }
    @media all and (min-width: 480px) {
      .p3-img.-frontOriginalTkTxt.-tsue {
        bottom: 10px;
        left: 10px; } }
    @media all and (min-width: 800px) {
      .p3-img.-frontOriginalTkTxt.-tsue {
        bottom: 20px;
        left: 20px; } }
  .p3-img.-frontOriginalTkTxt.-kasa {
    top: 5vw;
    right: 5vw; }
    @media all and (min-width: 480px) {
      .p3-img.-frontOriginalTkTxt.-kasa {
        top: 10px;
        right: 10px; } }
    @media all and (min-width: 800px) {
      .p3-img.-frontOriginalTkTxt.-kasa {
        top: 20px;
        right: 20px; } }

.p3-img.-frontOriginalTg01 {
  position: relative;
  width: 78px;
  margin-top: 31px;
  margin-left: auto;
  margin-right: auto; }
  @media all and (min-width: 800px) {
    .p3-img.-frontOriginalTg01 {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 97px;
      margin-top: 0;
      margin-left: 0; } }
  @media all and (min-width: 1120px) {
    .p3-img.-frontOriginalTg01 {
      top: 95px; } }
  .p3-img.-frontOriginalTg01 img {
    width: 100%;
    height: auto; }

.p3-img.-frontOriginalTg01Txt {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(100%);
  width: 62px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontOriginalTg01Txt {
      left: auto;
      top: 15px;
      right: 15px;
      transform: translateX(0);
      width: 77px; } }
  .p3-img.-frontOriginalTg01Txt img {
    width: 100%;
    height: auto; }

.p3-img.-frontTsuegasaFeatures {
  width: 100%; }
  .p3-img.-frontTsuegasaFeatures img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p3-img.-frontTsuegasaVariation {
  position: absolute;
  bottom: -26px;
  right: 0;
  transform: translate(0, 0);
  width: 61px;
  height: 57px; }
  @media all and (min-width: 800px) {
    .p3-img.-frontTsuegasaVariation {
      bottom: 0;
      right: 2.419354838709677vw;
      transform: translate(0, 100%);
      width: 9.838709677419355vw;
      height: 9.112903225806452vw; } }
  @media all and (min-width: 1240px) {
    .p3-img.-frontTsuegasaVariation {
      bottom: 0;
      right: 30px;
      width: 122px;
      height: 113px; } }
  .p3-img.-frontTsuegasaVariation img {
    width: 100%;
    height: auto; }

.p3-img.-frontOriginalImg {
  width: 100%;
  height: 100%; }
  .p3-img.-frontOriginalImg img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.p3-img.-frontGreetingsFull, .p3-img.-frontHistoryFull {
  position: relative;
  width: 100%;
  height: 100vh;
  /*
    //スマホ時、縦長画像導入前 2024/5/8以前
    .luxy-fixed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      object-fit: cover;

      @media (min-aspect-ratio: 4/10) {  //幅4 : 高さ10の比率より「幅がひろい」時に適用
        height: 190vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 7/10) {  //幅7 : 高さ10の比率より「幅がひろい」時に適用
        height: 180vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 10/10) {  //幅10 : 高さ10の比率より「幅がひろい」時に適用
        height: 170vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 13/10) {  //幅13 : 高さ10の比率より「幅がひろい」時に適用
        height: 160vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 16/10) {  //幅16 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
    }
*/ }
  @media all and (min-width: 1620px) {
    .p3-img.-frontGreetingsFull, .p3-img.-frontHistoryFull {
      height: 100vh; } }
  .p3-img.-frontGreetingsFull .luxy-fixed, .p3-img.-frontHistoryFull .luxy-fixed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 150vh;
    object-fit: cover; }
    @media (min-aspect-ratio: 4 / 10) {
      .p3-img.-frontGreetingsFull .luxy-fixed, .p3-img.-frontHistoryFull .luxy-fixed {
        height: 150vh; } }
    @media (min-aspect-ratio: 7 / 10) {
      .p3-img.-frontGreetingsFull .luxy-fixed, .p3-img.-frontHistoryFull .luxy-fixed {
        height: 150vh; } }
    @media (min-aspect-ratio: 10 / 10) {
      .p3-img.-frontGreetingsFull .luxy-fixed, .p3-img.-frontHistoryFull .luxy-fixed {
        height: 150vh; } }
    @media (min-aspect-ratio: 13 / 10) {
      .p3-img.-frontGreetingsFull .luxy-fixed, .p3-img.-frontHistoryFull .luxy-fixed {
        height: 150vh; } }
    @media (min-aspect-ratio: 16 / 10) {
      .p3-img.-frontGreetingsFull .luxy-fixed, .p3-img.-frontHistoryFull .luxy-fixed {
        height: 150vh; } }

.p3-shadow {
  /*
  &.-shadow01 {
    -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
  }

  &.-shadow02 {
    -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
  }
*/ }
  .p3-shadow.-box01 {
    -moz-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.21) inset, 2px 2px 2px white inset, 3px 9px 10px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.21) inset, 2px 2px 2px white inset, 3px 9px 10px rgba(0, 0, 0, 0.06);
    -ms-box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.21) inset, 2px 2px 2px white inset, 3px 9px 10px rgba(0, 0, 0, 0.06);
    box-shadow: -2px -2px 2px rgba(0, 0, 0, 0.21) inset, 2px 2px 2px white inset, 3px 9px 10px rgba(0, 0, 0, 0.06); }

.p5-form {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; }
  .p5-form_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    border-bottom: 1px solid #9783b5; }
    .p5-form_box:first-of-type {
      border-top: 1px solid #9783b5; }
    .p5-form_box.-last {
      border-top-style: none !important;
      border-bottom-style: none; }
      .p5-form_box.-last.-agree {
        -webkit-justify-content: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 10px; }
        @media all and (min-width: 800px) {
          .p5-form_box.-last.-agree {
            margin-top: 60px;
            margin-bottom: 20px; } }
        .p5-form_box.-last.-agree .p5-form_errorbox .error {
          width: 100%;
          text-align: center;
          background-color: transparent !important; }
      .p5-form_box.-last.-privacy {
        -webkit-justify-content: center;
        justify-content: center; }
  .p5-form_inputbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    width: 100%; }
    @media all and (min-width: 600px) {
      .p5-form_inputbox {
        width: calc(100% - 150px); } }
    @media all and (min-width: 800px) {
      .p5-form_inputbox {
        width: calc(100% - 220px); } }
    @media all and (min-width: 1120px) {
      .p5-form_inputbox {
        width: calc(100% - 250px); } }
    .p5-form_inputbox.-agree {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 0; }
  .p5-form_ttlbox {
    width: 100%; }
    @media all and (min-width: 600px) {
      .p5-form_ttlbox {
        width: 150px; } }
    @media all and (min-width: 800px) {
      .p5-form_ttlbox {
        width: 220px; } }
    @media all and (min-width: 1120px) {
      .p5-form_ttlbox {
        width: 250px; } }
  .p5-form_ttl {
    font-family: 'Shippori Mincho', serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 600;
    font-size: 1.28rem;
    line-height: 1.8;
    padding: 12px 12px;
    box-sizing: border-box;
    text-align: left; }
    @media all and (min-width: 800px) {
      .p5-form_ttl {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p5-form_ttl {
        line-height: 1.8; } }
    @media all and (min-width: 600px) {
      .p5-form_ttl {
        text-align: center; } }
    @media all and (min-width: 800px) {
      .p5-form_ttl {
        padding: 15px 15px; } }
  .p5-form_input {
    position: relative;
    width: 100%;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 1.8; }
    @media all and (min-width: 800px) {
      .p5-form_input {
        font-size: 1.6rem; } }
    @media all and (min-width: 800px) {
      .p5-form_input {
        line-height: 1.8; } }
    .p5-form_input.-txt, .p5-form_input.-txtarea {
      padding: 12px 12px;
      box-sizing: border-box;
      background-color: rgba(219, 219, 219, 0.2); }
      @media all and (min-width: 600px) {
        .p5-form_input.-txt, .p5-form_input.-txtarea {
          padding: 12px 24px; } }
      @media all and (min-width: 800px) {
        .p5-form_input.-txt, .p5-form_input.-txtarea {
          padding: 15px 30px; } }
      .p5-form_input.-txt:-webkit-autofill, .p5-form_input.-txtarea:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #f2f2f2 inset; }
    .p5-form_input.-txtarea {
      height: 180px; }
  .p5-form_radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 24px;
    padding: 0 8px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p5-form_radio {
        gap: 0 30px;
        padding: 0 10px; } }
  .p5-form_check {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 0 24px;
    padding: 0 8px;
    box-sizing: border-box; }
    @media all and (min-width: 800px) {
      .p5-form_check {
        gap: 0 30px;
        padding: 0 10px; } }
    .p5-form_check.-agree {
      gap: 0;
      padding: 0;
      font-family: 'Shippori Mincho', serif;
      font-style: normal;
      font-weight: 400;
      font-weight: 600;
      font-size: 1.28rem;
      line-height: 1; }
      @media all and (min-width: 800px) {
        .p5-form_check.-agree {
          font-size: 1.6rem; } }
      @media all and (min-width: 800px) {
        .p5-form_check.-agree {
          line-height: 1; } }
    .p5-form_check label {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
  .p5-form_link a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #a6a7b0;
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1; }
    @media all and (min-width: 800px) {
      .p5-form_link a {
        font-size: 1.2rem; } }
    @media all and (min-width: 800px) {
      .p5-form_link a {
        line-height: 1; } }
  .p5-form_errorbox {
    width: 100%; }
    .p5-form_errorbox .error {
      display: block;
      padding: 12px 12px 12px 12px;
      background-color: rgba(219, 219, 219, 0.2); }
      @media all and (min-width: 600px) {
        .p5-form_errorbox .error {
          width: calc(100% - 150px);
          margin-left: auto;
          margin-right: 0;
          padding: 10px 10px 10px 24px; } }
      @media all and (min-width: 800px) {
        .p5-form_errorbox .error {
          width: calc(100% - 220px);
          padding: 15px 15px 15px 30px; } }
      @media all and (min-width: 1120px) {
        .p5-form_errorbox .error {
          width: calc(100% - 250px);
          padding: 15px 15px 15px 30px; } }

.mwform-checkbox-field-text {
  vertical-align: 5px;
  padding-left: 5px; }

.mwform-checkbox-field {
  line-height: 1; }

.mw_wp_form .error {
  display: block;
  color: #d77185 !important;
  font-size: 90%;
  font-weight: 700; }

.mw_wp_form_input .p5-form_display.-input {
  display: block; }

.mw_wp_form_input .p5-form_display.-confirm, .mw_wp_form_input .p5-form_display.-complete {
  display: none; }

.mw_wp_form_input .p0-flexbtns {
  gap: 0; }
  @media all and (min-width: 800px) {
    .mw_wp_form_input .p0-flexbtns {
      gap: 0; } }

.mw_wp_form_confirm .p5-form_display.-confirm {
  display: block; }

.mw_wp_form_confirm .p5-form_display.-input, .mw_wp_form_confirm .p5-form_display.-complete {
  display: none; }

.mw_wp_form_confirm .p5-form_inputbox {
  padding: 0 12px 12px;
  box-sizing: border-box; }
  @media all and (min-width: 600px) {
    .mw_wp_form_confirm .p5-form_inputbox {
      padding: 12px 24px; } }
  @media all and (min-width: 800px) {
    .mw_wp_form_confirm .p5-form_inputbox {
      padding: 15px 30px; } }

.mw_wp_form_complete .p5-form_display.-complete {
  display: block; }

.mw_wp_form_complete .p5-form_display.-input, .mw_wp_form_complete .p5-form_display.-confirm {
  display: none; }

.u-m0 {
  margin: 0px !important; }

.u-mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-mt0 {
  margin-top: 0px !important; }

.u-mb0 {
  margin-bottom: 0px !important; }

.u-ml0 {
  margin-left: 0px !important; }

.u-mr0 {
  margin-right: 0px !important; }

.u-p0 {
  padding: 0px !important; }

.u-pt0 {
  padding-top: 0px !important; }

.u-pb0 {
  padding-bottom: 0px !important; }

.u-pl0 {
  padding-left: 0px !important; }

.u-pr0 {
  padding-right: 0px !important; }

.u-m5 {
  margin: 5px !important; }

.u-mtb5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.u-mlr5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-ml5 {
  margin-left: 5px !important; }

.u-mr5 {
  margin-right: 5px !important; }

.u-p5 {
  padding: 5px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-pl5 {
  padding-left: 5px !important; }

.u-pr5 {
  padding-right: 5px !important; }

.u-m10 {
  margin: 10px !important; }

.u-mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.u-mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-ml10 {
  margin-left: 10px !important; }

.u-mr10 {
  margin-right: 10px !important; }

.u-p10 {
  padding: 10px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-pl10 {
  padding-left: 10px !important; }

.u-pr10 {
  padding-right: 10px !important; }

.u-m15 {
  margin: 15px !important; }

.u-mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.u-mlr15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-ml15 {
  margin-left: 15px !important; }

.u-mr15 {
  margin-right: 15px !important; }

.u-p15 {
  padding: 15px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-pl15 {
  padding-left: 15px !important; }

.u-pr15 {
  padding-right: 15px !important; }

.u-m20 {
  margin: 20px !important; }

.u-mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-ml20 {
  margin-left: 20px !important; }

.u-mr20 {
  margin-right: 20px !important; }

.u-p20 {
  padding: 20px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-pl20 {
  padding-left: 20px !important; }

.u-pr20 {
  padding-right: 20px !important; }

.u-m25 {
  margin: 25px !important; }

.u-mtb25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.u-mlr25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-ml25 {
  margin-left: 25px !important; }

.u-mr25 {
  margin-right: 25px !important; }

.u-p25 {
  padding: 25px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-pl25 {
  padding-left: 25px !important; }

.u-pr25 {
  padding-right: 25px !important; }

.u-m30 {
  margin: 30px !important; }

.u-mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.u-mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-ml30 {
  margin-left: 30px !important; }

.u-mr30 {
  margin-right: 30px !important; }

.u-p30 {
  padding: 30px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-pl30 {
  padding-left: 30px !important; }

.u-pr30 {
  padding-right: 30px !important; }

.u-m35 {
  margin: 35px !important; }

.u-mtb35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.u-mlr35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-ml35 {
  margin-left: 35px !important; }

.u-mr35 {
  margin-right: 35px !important; }

.u-p35 {
  padding: 35px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-pl35 {
  padding-left: 35px !important; }

.u-pr35 {
  padding-right: 35px !important; }

.u-m40 {
  margin: 40px !important; }

.u-mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.u-mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-ml40 {
  margin-left: 40px !important; }

.u-mr40 {
  margin-right: 40px !important; }

.u-p40 {
  padding: 40px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-pl40 {
  padding-left: 40px !important; }

.u-pr40 {
  padding-right: 40px !important; }

.u-m45 {
  margin: 45px !important; }

.u-mtb45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.u-mlr45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-ml45 {
  margin-left: 45px !important; }

.u-mr45 {
  margin-right: 45px !important; }

.u-p45 {
  padding: 45px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-pl45 {
  padding-left: 45px !important; }

.u-pr45 {
  padding-right: 45px !important; }

.u-m50 {
  margin: 50px !important; }

.u-mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.u-mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-ml50 {
  margin-left: 50px !important; }

.u-mr50 {
  margin-right: 50px !important; }

.u-p50 {
  padding: 50px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-pl50 {
  padding-left: 50px !important; }

.u-pr50 {
  padding-right: 50px !important; }

.u-m55 {
  margin: 55px !important; }

.u-mtb55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.u-mlr55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-ml55 {
  margin-left: 55px !important; }

.u-mr55 {
  margin-right: 55px !important; }

.u-p55 {
  padding: 55px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-pl55 {
  padding-left: 55px !important; }

.u-pr55 {
  padding-right: 55px !important; }

.u-m60 {
  margin: 60px !important; }

.u-mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-ml60 {
  margin-left: 60px !important; }

.u-mr60 {
  margin-right: 60px !important; }

.u-p60 {
  padding: 60px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-pl60 {
  padding-left: 60px !important; }

.u-pr60 {
  padding-right: 60px !important; }

.u-m65 {
  margin: 65px !important; }

.u-mtb65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.u-mlr65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mb65 {
  margin-bottom: 65px !important; }

.u-ml65 {
  margin-left: 65px !important; }

.u-mr65 {
  margin-right: 65px !important; }

.u-p65 {
  padding: 65px !important; }

.u-pt65 {
  padding-top: 65px !important; }

.u-pb65 {
  padding-bottom: 65px !important; }

.u-pl65 {
  padding-left: 65px !important; }

.u-pr65 {
  padding-right: 65px !important; }

.u-m70 {
  margin: 70px !important; }

.u-mtb70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.u-mlr70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mb70 {
  margin-bottom: 70px !important; }

.u-ml70 {
  margin-left: 70px !important; }

.u-mr70 {
  margin-right: 70px !important; }

.u-p70 {
  padding: 70px !important; }

.u-pt70 {
  padding-top: 70px !important; }

.u-pb70 {
  padding-bottom: 70px !important; }

.u-pl70 {
  padding-left: 70px !important; }

.u-pr70 {
  padding-right: 70px !important; }

.u-m75 {
  margin: 75px !important; }

.u-mtb75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.u-mlr75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mb75 {
  margin-bottom: 75px !important; }

.u-ml75 {
  margin-left: 75px !important; }

.u-mr75 {
  margin-right: 75px !important; }

.u-p75 {
  padding: 75px !important; }

.u-pt75 {
  padding-top: 75px !important; }

.u-pb75 {
  padding-bottom: 75px !important; }

.u-pl75 {
  padding-left: 75px !important; }

.u-pr75 {
  padding-right: 75px !important; }

.u-m80 {
  margin: 80px !important; }

.u-mtb80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.u-mlr80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mb80 {
  margin-bottom: 80px !important; }

.u-ml80 {
  margin-left: 80px !important; }

.u-mr80 {
  margin-right: 80px !important; }

.u-p80 {
  padding: 80px !important; }

.u-pt80 {
  padding-top: 80px !important; }

.u-pb80 {
  padding-bottom: 80px !important; }

.u-pl80 {
  padding-left: 80px !important; }

.u-pr80 {
  padding-right: 80px !important; }

.u-m85 {
  margin: 85px !important; }

.u-mtb85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.u-mlr85 {
  margin-left: 85px !important;
  margin-right: 85px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mb85 {
  margin-bottom: 85px !important; }

.u-ml85 {
  margin-left: 85px !important; }

.u-mr85 {
  margin-right: 85px !important; }

.u-p85 {
  padding: 85px !important; }

.u-pt85 {
  padding-top: 85px !important; }

.u-pb85 {
  padding-bottom: 85px !important; }

.u-pl85 {
  padding-left: 85px !important; }

.u-pr85 {
  padding-right: 85px !important; }

.u-m90 {
  margin: 90px !important; }

.u-mtb90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.u-mlr90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mb90 {
  margin-bottom: 90px !important; }

.u-ml90 {
  margin-left: 90px !important; }

.u-mr90 {
  margin-right: 90px !important; }

.u-p90 {
  padding: 90px !important; }

.u-pt90 {
  padding-top: 90px !important; }

.u-pb90 {
  padding-bottom: 90px !important; }

.u-pl90 {
  padding-left: 90px !important; }

.u-pr90 {
  padding-right: 90px !important; }

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important; }
  @media all and (min-width: 800px) {
    .u-m-pcAuto-spLeft {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important; }
  @media all and (min-width: 800px) {
    .u-m-pcAuto-spRight {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-mt5_half {
  margin-top: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt5_half {
      margin-top: 5px !important; } }

.u-mb5_half {
  margin-bottom: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb5_half {
      margin-bottom: 5px !important; } }

.u-ml5_half {
  margin-left: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml5_half {
      margin-left: 5px !important; } }

.u-mr5_half {
  margin-right: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr5_half {
      margin-right: 5px !important; } }

.u-pt5_half {
  padding-top: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt5_half {
      padding-top: 5px !important; } }

.u-pb5_half {
  padding-bottom: 2.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb5_half {
      padding-bottom: 5px !important; } }

.u-mt10_half {
  margin-top: 5px !important; }
  @media all and (min-width: 800px) {
    .u-mt10_half {
      margin-top: 10px !important; } }

.u-mb10_half {
  margin-bottom: 5px !important; }
  @media all and (min-width: 800px) {
    .u-mb10_half {
      margin-bottom: 10px !important; } }

.u-ml10_half {
  margin-left: 5px !important; }
  @media all and (min-width: 800px) {
    .u-ml10_half {
      margin-left: 10px !important; } }

.u-mr10_half {
  margin-right: 5px !important; }
  @media all and (min-width: 800px) {
    .u-mr10_half {
      margin-right: 10px !important; } }

.u-pt10_half {
  padding-top: 5px !important; }
  @media all and (min-width: 800px) {
    .u-pt10_half {
      padding-top: 10px !important; } }

.u-pb10_half {
  padding-bottom: 5px !important; }
  @media all and (min-width: 800px) {
    .u-pb10_half {
      padding-bottom: 10px !important; } }

.u-mt15_half {
  margin-top: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt15_half {
      margin-top: 15px !important; } }

.u-mb15_half {
  margin-bottom: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb15_half {
      margin-bottom: 15px !important; } }

.u-ml15_half {
  margin-left: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml15_half {
      margin-left: 15px !important; } }

.u-mr15_half {
  margin-right: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr15_half {
      margin-right: 15px !important; } }

.u-pt15_half {
  padding-top: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt15_half {
      padding-top: 15px !important; } }

.u-pb15_half {
  padding-bottom: 7.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb15_half {
      padding-bottom: 15px !important; } }

.u-mt20_half {
  margin-top: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mt20_half {
      margin-top: 20px !important; } }

.u-mb20_half {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb20_half {
      margin-bottom: 20px !important; } }

.u-ml20_half {
  margin-left: 10px !important; }
  @media all and (min-width: 800px) {
    .u-ml20_half {
      margin-left: 20px !important; } }

.u-mr20_half {
  margin-right: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mr20_half {
      margin-right: 20px !important; } }

.u-pt20_half {
  padding-top: 10px !important; }
  @media all and (min-width: 800px) {
    .u-pt20_half {
      padding-top: 20px !important; } }

.u-pb20_half {
  padding-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-pb20_half {
      padding-bottom: 20px !important; } }

.u-mt25_half {
  margin-top: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt25_half {
      margin-top: 25px !important; } }

.u-mb25_half {
  margin-bottom: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb25_half {
      margin-bottom: 25px !important; } }

.u-ml25_half {
  margin-left: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml25_half {
      margin-left: 25px !important; } }

.u-mr25_half {
  margin-right: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr25_half {
      margin-right: 25px !important; } }

.u-pt25_half {
  padding-top: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt25_half {
      padding-top: 25px !important; } }

.u-pb25_half {
  padding-bottom: 12.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb25_half {
      padding-bottom: 25px !important; } }

.u-mt30_half {
  margin-top: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mt30_half {
      margin-top: 30px !important; } }

.u-mb30_half {
  margin-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mb30_half {
      margin-bottom: 30px !important; } }

.u-ml30_half {
  margin-left: 15px !important; }
  @media all and (min-width: 800px) {
    .u-ml30_half {
      margin-left: 30px !important; } }

.u-mr30_half {
  margin-right: 15px !important; }
  @media all and (min-width: 800px) {
    .u-mr30_half {
      margin-right: 30px !important; } }

.u-pt30_half {
  padding-top: 15px !important; }
  @media all and (min-width: 800px) {
    .u-pt30_half {
      padding-top: 30px !important; } }

.u-pb30_half {
  padding-bottom: 15px !important; }
  @media all and (min-width: 800px) {
    .u-pb30_half {
      padding-bottom: 30px !important; } }

.u-mt35_half {
  margin-top: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt35_half {
      margin-top: 35px !important; } }

.u-mb35_half {
  margin-bottom: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb35_half {
      margin-bottom: 35px !important; } }

.u-ml35_half {
  margin-left: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml35_half {
      margin-left: 35px !important; } }

.u-mr35_half {
  margin-right: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr35_half {
      margin-right: 35px !important; } }

.u-pt35_half {
  padding-top: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt35_half {
      padding-top: 35px !important; } }

.u-pb35_half {
  padding-bottom: 17.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb35_half {
      padding-bottom: 35px !important; } }

.u-mt40_half {
  margin-top: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mt40_half {
      margin-top: 40px !important; } }

.u-mb40_half {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb40_half {
      margin-bottom: 40px !important; } }

.u-ml40_half {
  margin-left: 20px !important; }
  @media all and (min-width: 800px) {
    .u-ml40_half {
      margin-left: 40px !important; } }

.u-mr40_half {
  margin-right: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mr40_half {
      margin-right: 40px !important; } }

.u-pt40_half {
  padding-top: 20px !important; }
  @media all and (min-width: 800px) {
    .u-pt40_half {
      padding-top: 40px !important; } }

.u-pb40_half {
  padding-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-pb40_half {
      padding-bottom: 40px !important; } }

.u-mt45_half {
  margin-top: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt45_half {
      margin-top: 45px !important; } }

.u-mb45_half {
  margin-bottom: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb45_half {
      margin-bottom: 45px !important; } }

.u-ml45_half {
  margin-left: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml45_half {
      margin-left: 45px !important; } }

.u-mr45_half {
  margin-right: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr45_half {
      margin-right: 45px !important; } }

.u-pt45_half {
  padding-top: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt45_half {
      padding-top: 45px !important; } }

.u-pb45_half {
  padding-bottom: 22.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb45_half {
      padding-bottom: 45px !important; } }

.u-mt50_half {
  margin-top: 25px !important; }
  @media all and (min-width: 800px) {
    .u-mt50_half {
      margin-top: 50px !important; } }

.u-mb50_half {
  margin-bottom: 25px !important; }
  @media all and (min-width: 800px) {
    .u-mb50_half {
      margin-bottom: 50px !important; } }

.u-ml50_half {
  margin-left: 25px !important; }
  @media all and (min-width: 800px) {
    .u-ml50_half {
      margin-left: 50px !important; } }

.u-mr50_half {
  margin-right: 25px !important; }
  @media all and (min-width: 800px) {
    .u-mr50_half {
      margin-right: 50px !important; } }

.u-pt50_half {
  padding-top: 25px !important; }
  @media all and (min-width: 800px) {
    .u-pt50_half {
      padding-top: 50px !important; } }

.u-pb50_half {
  padding-bottom: 25px !important; }
  @media all and (min-width: 800px) {
    .u-pb50_half {
      padding-bottom: 50px !important; } }

.u-mt55_half {
  margin-top: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt55_half {
      margin-top: 55px !important; } }

.u-mb55_half {
  margin-bottom: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb55_half {
      margin-bottom: 55px !important; } }

.u-ml55_half {
  margin-left: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml55_half {
      margin-left: 55px !important; } }

.u-mr55_half {
  margin-right: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr55_half {
      margin-right: 55px !important; } }

.u-pt55_half {
  padding-top: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt55_half {
      padding-top: 55px !important; } }

.u-pb55_half {
  padding-bottom: 27.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb55_half {
      padding-bottom: 55px !important; } }

.u-mt60_half {
  margin-top: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mt60_half {
      margin-top: 60px !important; } }

.u-mb60_half {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_half {
      margin-bottom: 60px !important; } }

.u-ml60_half {
  margin-left: 30px !important; }
  @media all and (min-width: 800px) {
    .u-ml60_half {
      margin-left: 60px !important; } }

.u-mr60_half {
  margin-right: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mr60_half {
      margin-right: 60px !important; } }

.u-pt60_half {
  padding-top: 30px !important; }
  @media all and (min-width: 800px) {
    .u-pt60_half {
      padding-top: 60px !important; } }

.u-pb60_half {
  padding-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-pb60_half {
      padding-bottom: 60px !important; } }

.u-mt65_half {
  margin-top: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt65_half {
      margin-top: 65px !important; } }

.u-mb65_half {
  margin-bottom: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb65_half {
      margin-bottom: 65px !important; } }

.u-ml65_half {
  margin-left: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml65_half {
      margin-left: 65px !important; } }

.u-mr65_half {
  margin-right: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr65_half {
      margin-right: 65px !important; } }

.u-pt65_half {
  padding-top: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt65_half {
      padding-top: 65px !important; } }

.u-pb65_half {
  padding-bottom: 32.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb65_half {
      padding-bottom: 65px !important; } }

.u-mt70_half {
  margin-top: 35px !important; }
  @media all and (min-width: 800px) {
    .u-mt70_half {
      margin-top: 70px !important; } }

.u-mb70_half {
  margin-bottom: 35px !important; }
  @media all and (min-width: 800px) {
    .u-mb70_half {
      margin-bottom: 70px !important; } }

.u-ml70_half {
  margin-left: 35px !important; }
  @media all and (min-width: 800px) {
    .u-ml70_half {
      margin-left: 70px !important; } }

.u-mr70_half {
  margin-right: 35px !important; }
  @media all and (min-width: 800px) {
    .u-mr70_half {
      margin-right: 70px !important; } }

.u-pt70_half {
  padding-top: 35px !important; }
  @media all and (min-width: 800px) {
    .u-pt70_half {
      padding-top: 70px !important; } }

.u-pb70_half {
  padding-bottom: 35px !important; }
  @media all and (min-width: 800px) {
    .u-pb70_half {
      padding-bottom: 70px !important; } }

.u-mt75_half {
  margin-top: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt75_half {
      margin-top: 75px !important; } }

.u-mb75_half {
  margin-bottom: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb75_half {
      margin-bottom: 75px !important; } }

.u-ml75_half {
  margin-left: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml75_half {
      margin-left: 75px !important; } }

.u-mr75_half {
  margin-right: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr75_half {
      margin-right: 75px !important; } }

.u-pt75_half {
  padding-top: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt75_half {
      padding-top: 75px !important; } }

.u-pb75_half {
  padding-bottom: 37.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb75_half {
      padding-bottom: 75px !important; } }

.u-mt80_half {
  margin-top: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mt80_half {
      margin-top: 80px !important; } }

.u-mb80_half {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb80_half {
      margin-bottom: 80px !important; } }

.u-ml80_half {
  margin-left: 40px !important; }
  @media all and (min-width: 800px) {
    .u-ml80_half {
      margin-left: 80px !important; } }

.u-mr80_half {
  margin-right: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mr80_half {
      margin-right: 80px !important; } }

.u-pt80_half {
  padding-top: 40px !important; }
  @media all and (min-width: 800px) {
    .u-pt80_half {
      padding-top: 80px !important; } }

.u-pb80_half {
  padding-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-pb80_half {
      padding-bottom: 80px !important; } }

.u-mt85_half {
  margin-top: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt85_half {
      margin-top: 85px !important; } }

.u-mb85_half {
  margin-bottom: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb85_half {
      margin-bottom: 85px !important; } }

.u-ml85_half {
  margin-left: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml85_half {
      margin-left: 85px !important; } }

.u-mr85_half {
  margin-right: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr85_half {
      margin-right: 85px !important; } }

.u-pt85_half {
  padding-top: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt85_half {
      padding-top: 85px !important; } }

.u-pb85_half {
  padding-bottom: 42.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb85_half {
      padding-bottom: 85px !important; } }

.u-mt90_half {
  margin-top: 45px !important; }
  @media all and (min-width: 800px) {
    .u-mt90_half {
      margin-top: 90px !important; } }

.u-mb90_half {
  margin-bottom: 45px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_half {
      margin-bottom: 90px !important; } }

.u-ml90_half {
  margin-left: 45px !important; }
  @media all and (min-width: 800px) {
    .u-ml90_half {
      margin-left: 90px !important; } }

.u-mr90_half {
  margin-right: 45px !important; }
  @media all and (min-width: 800px) {
    .u-mr90_half {
      margin-right: 90px !important; } }

.u-pt90_half {
  padding-top: 45px !important; }
  @media all and (min-width: 800px) {
    .u-pt90_half {
      padding-top: 90px !important; } }

.u-pb90_half {
  padding-bottom: 45px !important; }
  @media all and (min-width: 800px) {
    .u-pb90_half {
      padding-bottom: 90px !important; } }

.u-mt95_half {
  margin-top: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt95_half {
      margin-top: 95px !important; } }

.u-mb95_half {
  margin-bottom: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb95_half {
      margin-bottom: 95px !important; } }

.u-ml95_half {
  margin-left: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml95_half {
      margin-left: 95px !important; } }

.u-mr95_half {
  margin-right: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr95_half {
      margin-right: 95px !important; } }

.u-pt95_half {
  padding-top: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt95_half {
      padding-top: 95px !important; } }

.u-pb95_half {
  padding-bottom: 47.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb95_half {
      padding-bottom: 95px !important; } }

.u-mt100_half {
  margin-top: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mt100_half {
      margin-top: 100px !important; } }

.u-mb100_half {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb100_half {
      margin-bottom: 100px !important; } }

.u-ml100_half {
  margin-left: 50px !important; }
  @media all and (min-width: 800px) {
    .u-ml100_half {
      margin-left: 100px !important; } }

.u-mr100_half {
  margin-right: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mr100_half {
      margin-right: 100px !important; } }

.u-pt100_half {
  padding-top: 50px !important; }
  @media all and (min-width: 800px) {
    .u-pt100_half {
      padding-top: 100px !important; } }

.u-pb100_half {
  padding-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-pb100_half {
      padding-bottom: 100px !important; } }

.u-mt105_half {
  margin-top: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt105_half {
      margin-top: 105px !important; } }

.u-mb105_half {
  margin-bottom: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb105_half {
      margin-bottom: 105px !important; } }

.u-ml105_half {
  margin-left: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml105_half {
      margin-left: 105px !important; } }

.u-mr105_half {
  margin-right: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr105_half {
      margin-right: 105px !important; } }

.u-pt105_half {
  padding-top: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt105_half {
      padding-top: 105px !important; } }

.u-pb105_half {
  padding-bottom: 52.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb105_half {
      padding-bottom: 105px !important; } }

.u-mt110_half {
  margin-top: 55px !important; }
  @media all and (min-width: 800px) {
    .u-mt110_half {
      margin-top: 110px !important; } }

.u-mb110_half {
  margin-bottom: 55px !important; }
  @media all and (min-width: 800px) {
    .u-mb110_half {
      margin-bottom: 110px !important; } }

.u-ml110_half {
  margin-left: 55px !important; }
  @media all and (min-width: 800px) {
    .u-ml110_half {
      margin-left: 110px !important; } }

.u-mr110_half {
  margin-right: 55px !important; }
  @media all and (min-width: 800px) {
    .u-mr110_half {
      margin-right: 110px !important; } }

.u-pt110_half {
  padding-top: 55px !important; }
  @media all and (min-width: 800px) {
    .u-pt110_half {
      padding-top: 110px !important; } }

.u-pb110_half {
  padding-bottom: 55px !important; }
  @media all and (min-width: 800px) {
    .u-pb110_half {
      padding-bottom: 110px !important; } }

.u-mt115_half {
  margin-top: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt115_half {
      margin-top: 115px !important; } }

.u-mb115_half {
  margin-bottom: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb115_half {
      margin-bottom: 115px !important; } }

.u-ml115_half {
  margin-left: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml115_half {
      margin-left: 115px !important; } }

.u-mr115_half {
  margin-right: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr115_half {
      margin-right: 115px !important; } }

.u-pt115_half {
  padding-top: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt115_half {
      padding-top: 115px !important; } }

.u-pb115_half {
  padding-bottom: 57.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb115_half {
      padding-bottom: 115px !important; } }

.u-mt120_half {
  margin-top: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mt120_half {
      margin-top: 120px !important; } }

.u-mb120_half {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb120_half {
      margin-bottom: 120px !important; } }

.u-ml120_half {
  margin-left: 60px !important; }
  @media all and (min-width: 800px) {
    .u-ml120_half {
      margin-left: 120px !important; } }

.u-mr120_half {
  margin-right: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mr120_half {
      margin-right: 120px !important; } }

.u-pt120_half {
  padding-top: 60px !important; }
  @media all and (min-width: 800px) {
    .u-pt120_half {
      padding-top: 120px !important; } }

.u-pb120_half {
  padding-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-pb120_half {
      padding-bottom: 120px !important; } }

.u-mt125_half {
  margin-top: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt125_half {
      margin-top: 125px !important; } }

.u-mb125_half {
  margin-bottom: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb125_half {
      margin-bottom: 125px !important; } }

.u-ml125_half {
  margin-left: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml125_half {
      margin-left: 125px !important; } }

.u-mr125_half {
  margin-right: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr125_half {
      margin-right: 125px !important; } }

.u-pt125_half {
  padding-top: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt125_half {
      padding-top: 125px !important; } }

.u-pb125_half {
  padding-bottom: 62.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb125_half {
      padding-bottom: 125px !important; } }

.u-mt130_half {
  margin-top: 65px !important; }
  @media all and (min-width: 800px) {
    .u-mt130_half {
      margin-top: 130px !important; } }

.u-mb130_half {
  margin-bottom: 65px !important; }
  @media all and (min-width: 800px) {
    .u-mb130_half {
      margin-bottom: 130px !important; } }

.u-ml130_half {
  margin-left: 65px !important; }
  @media all and (min-width: 800px) {
    .u-ml130_half {
      margin-left: 130px !important; } }

.u-mr130_half {
  margin-right: 65px !important; }
  @media all and (min-width: 800px) {
    .u-mr130_half {
      margin-right: 130px !important; } }

.u-pt130_half {
  padding-top: 65px !important; }
  @media all and (min-width: 800px) {
    .u-pt130_half {
      padding-top: 130px !important; } }

.u-pb130_half {
  padding-bottom: 65px !important; }
  @media all and (min-width: 800px) {
    .u-pb130_half {
      padding-bottom: 130px !important; } }

.u-mt135_half {
  margin-top: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt135_half {
      margin-top: 135px !important; } }

.u-mb135_half {
  margin-bottom: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb135_half {
      margin-bottom: 135px !important; } }

.u-ml135_half {
  margin-left: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml135_half {
      margin-left: 135px !important; } }

.u-mr135_half {
  margin-right: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr135_half {
      margin-right: 135px !important; } }

.u-pt135_half {
  padding-top: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt135_half {
      padding-top: 135px !important; } }

.u-pb135_half {
  padding-bottom: 67.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb135_half {
      padding-bottom: 135px !important; } }

.u-mt140_half {
  margin-top: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mt140_half {
      margin-top: 140px !important; } }

.u-mb140_half {
  margin-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mb140_half {
      margin-bottom: 140px !important; } }

.u-ml140_half {
  margin-left: 70px !important; }
  @media all and (min-width: 800px) {
    .u-ml140_half {
      margin-left: 140px !important; } }

.u-mr140_half {
  margin-right: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mr140_half {
      margin-right: 140px !important; } }

.u-pt140_half {
  padding-top: 70px !important; }
  @media all and (min-width: 800px) {
    .u-pt140_half {
      padding-top: 140px !important; } }

.u-pb140_half {
  padding-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-pb140_half {
      padding-bottom: 140px !important; } }

.u-mt145_half {
  margin-top: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt145_half {
      margin-top: 145px !important; } }

.u-mb145_half {
  margin-bottom: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb145_half {
      margin-bottom: 145px !important; } }

.u-ml145_half {
  margin-left: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml145_half {
      margin-left: 145px !important; } }

.u-mr145_half {
  margin-right: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr145_half {
      margin-right: 145px !important; } }

.u-pt145_half {
  padding-top: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt145_half {
      padding-top: 145px !important; } }

.u-pb145_half {
  padding-bottom: 72.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb145_half {
      padding-bottom: 145px !important; } }

.u-mt150_half {
  margin-top: 75px !important; }
  @media all and (min-width: 800px) {
    .u-mt150_half {
      margin-top: 150px !important; } }

.u-mb150_half {
  margin-bottom: 75px !important; }
  @media all and (min-width: 800px) {
    .u-mb150_half {
      margin-bottom: 150px !important; } }

.u-ml150_half {
  margin-left: 75px !important; }
  @media all and (min-width: 800px) {
    .u-ml150_half {
      margin-left: 150px !important; } }

.u-mr150_half {
  margin-right: 75px !important; }
  @media all and (min-width: 800px) {
    .u-mr150_half {
      margin-right: 150px !important; } }

.u-pt150_half {
  padding-top: 75px !important; }
  @media all and (min-width: 800px) {
    .u-pt150_half {
      padding-top: 150px !important; } }

.u-pb150_half {
  padding-bottom: 75px !important; }
  @media all and (min-width: 800px) {
    .u-pb150_half {
      padding-bottom: 150px !important; } }

.u-mt155_half {
  margin-top: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt155_half {
      margin-top: 155px !important; } }

.u-mb155_half {
  margin-bottom: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb155_half {
      margin-bottom: 155px !important; } }

.u-ml155_half {
  margin-left: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml155_half {
      margin-left: 155px !important; } }

.u-mr155_half {
  margin-right: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr155_half {
      margin-right: 155px !important; } }

.u-pt155_half {
  padding-top: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt155_half {
      padding-top: 155px !important; } }

.u-pb155_half {
  padding-bottom: 77.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb155_half {
      padding-bottom: 155px !important; } }

.u-mt160_half {
  margin-top: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mt160_half {
      margin-top: 160px !important; } }

.u-mb160_half {
  margin-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mb160_half {
      margin-bottom: 160px !important; } }

.u-ml160_half {
  margin-left: 80px !important; }
  @media all and (min-width: 800px) {
    .u-ml160_half {
      margin-left: 160px !important; } }

.u-mr160_half {
  margin-right: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mr160_half {
      margin-right: 160px !important; } }

.u-pt160_half {
  padding-top: 80px !important; }
  @media all and (min-width: 800px) {
    .u-pt160_half {
      padding-top: 160px !important; } }

.u-pb160_half {
  padding-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-pb160_half {
      padding-bottom: 160px !important; } }

.u-mt165_half {
  margin-top: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt165_half {
      margin-top: 165px !important; } }

.u-mb165_half {
  margin-bottom: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb165_half {
      margin-bottom: 165px !important; } }

.u-ml165_half {
  margin-left: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml165_half {
      margin-left: 165px !important; } }

.u-mr165_half {
  margin-right: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr165_half {
      margin-right: 165px !important; } }

.u-pt165_half {
  padding-top: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt165_half {
      padding-top: 165px !important; } }

.u-pb165_half {
  padding-bottom: 82.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb165_half {
      padding-bottom: 165px !important; } }

.u-mt170_half {
  margin-top: 85px !important; }
  @media all and (min-width: 800px) {
    .u-mt170_half {
      margin-top: 170px !important; } }

.u-mb170_half {
  margin-bottom: 85px !important; }
  @media all and (min-width: 800px) {
    .u-mb170_half {
      margin-bottom: 170px !important; } }

.u-ml170_half {
  margin-left: 85px !important; }
  @media all and (min-width: 800px) {
    .u-ml170_half {
      margin-left: 170px !important; } }

.u-mr170_half {
  margin-right: 85px !important; }
  @media all and (min-width: 800px) {
    .u-mr170_half {
      margin-right: 170px !important; } }

.u-pt170_half {
  padding-top: 85px !important; }
  @media all and (min-width: 800px) {
    .u-pt170_half {
      padding-top: 170px !important; } }

.u-pb170_half {
  padding-bottom: 85px !important; }
  @media all and (min-width: 800px) {
    .u-pb170_half {
      padding-bottom: 170px !important; } }

.u-mt175_half {
  margin-top: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt175_half {
      margin-top: 175px !important; } }

.u-mb175_half {
  margin-bottom: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb175_half {
      margin-bottom: 175px !important; } }

.u-ml175_half {
  margin-left: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml175_half {
      margin-left: 175px !important; } }

.u-mr175_half {
  margin-right: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr175_half {
      margin-right: 175px !important; } }

.u-pt175_half {
  padding-top: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt175_half {
      padding-top: 175px !important; } }

.u-pb175_half {
  padding-bottom: 87.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb175_half {
      padding-bottom: 175px !important; } }

.u-mt180_half {
  margin-top: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mt180_half {
      margin-top: 180px !important; } }

.u-mb180_half {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mb180_half {
      margin-bottom: 180px !important; } }

.u-ml180_half {
  margin-left: 90px !important; }
  @media all and (min-width: 800px) {
    .u-ml180_half {
      margin-left: 180px !important; } }

.u-mr180_half {
  margin-right: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mr180_half {
      margin-right: 180px !important; } }

.u-pt180_half {
  padding-top: 90px !important; }
  @media all and (min-width: 800px) {
    .u-pt180_half {
      padding-top: 180px !important; } }

.u-pb180_half {
  padding-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-pb180_half {
      padding-bottom: 180px !important; } }

.u-mt185_half {
  margin-top: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt185_half {
      margin-top: 185px !important; } }

.u-mb185_half {
  margin-bottom: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb185_half {
      margin-bottom: 185px !important; } }

.u-ml185_half {
  margin-left: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml185_half {
      margin-left: 185px !important; } }

.u-mr185_half {
  margin-right: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr185_half {
      margin-right: 185px !important; } }

.u-pt185_half {
  padding-top: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt185_half {
      padding-top: 185px !important; } }

.u-pb185_half {
  padding-bottom: 92.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb185_half {
      padding-bottom: 185px !important; } }

.u-mt190_half {
  margin-top: 95px !important; }
  @media all and (min-width: 800px) {
    .u-mt190_half {
      margin-top: 190px !important; } }

.u-mb190_half {
  margin-bottom: 95px !important; }
  @media all and (min-width: 800px) {
    .u-mb190_half {
      margin-bottom: 190px !important; } }

.u-ml190_half {
  margin-left: 95px !important; }
  @media all and (min-width: 800px) {
    .u-ml190_half {
      margin-left: 190px !important; } }

.u-mr190_half {
  margin-right: 95px !important; }
  @media all and (min-width: 800px) {
    .u-mr190_half {
      margin-right: 190px !important; } }

.u-pt190_half {
  padding-top: 95px !important; }
  @media all and (min-width: 800px) {
    .u-pt190_half {
      padding-top: 190px !important; } }

.u-pb190_half {
  padding-bottom: 95px !important; }
  @media all and (min-width: 800px) {
    .u-pb190_half {
      padding-bottom: 190px !important; } }

.u-mt195_half {
  margin-top: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt195_half {
      margin-top: 195px !important; } }

.u-mb195_half {
  margin-bottom: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb195_half {
      margin-bottom: 195px !important; } }

.u-ml195_half {
  margin-left: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml195_half {
      margin-left: 195px !important; } }

.u-mr195_half {
  margin-right: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr195_half {
      margin-right: 195px !important; } }

.u-pt195_half {
  padding-top: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt195_half {
      padding-top: 195px !important; } }

.u-pb195_half {
  padding-bottom: 97.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb195_half {
      padding-bottom: 195px !important; } }

.u-mt200_half {
  margin-top: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mt200_half {
      margin-top: 200px !important; } }

.u-mb200_half {
  margin-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mb200_half {
      margin-bottom: 200px !important; } }

.u-ml200_half {
  margin-left: 100px !important; }
  @media all and (min-width: 800px) {
    .u-ml200_half {
      margin-left: 200px !important; } }

.u-mr200_half {
  margin-right: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mr200_half {
      margin-right: 200px !important; } }

.u-pt200_half {
  padding-top: 100px !important; }
  @media all and (min-width: 800px) {
    .u-pt200_half {
      padding-top: 200px !important; } }

.u-pb200_half {
  padding-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-pb200_half {
      padding-bottom: 200px !important; } }

.u-mt205_half {
  margin-top: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt205_half {
      margin-top: 205px !important; } }

.u-mb205_half {
  margin-bottom: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb205_half {
      margin-bottom: 205px !important; } }

.u-ml205_half {
  margin-left: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml205_half {
      margin-left: 205px !important; } }

.u-mr205_half {
  margin-right: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr205_half {
      margin-right: 205px !important; } }

.u-pt205_half {
  padding-top: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt205_half {
      padding-top: 205px !important; } }

.u-pb205_half {
  padding-bottom: 102.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb205_half {
      padding-bottom: 205px !important; } }

.u-mt210_half {
  margin-top: 105px !important; }
  @media all and (min-width: 800px) {
    .u-mt210_half {
      margin-top: 210px !important; } }

.u-mb210_half {
  margin-bottom: 105px !important; }
  @media all and (min-width: 800px) {
    .u-mb210_half {
      margin-bottom: 210px !important; } }

.u-ml210_half {
  margin-left: 105px !important; }
  @media all and (min-width: 800px) {
    .u-ml210_half {
      margin-left: 210px !important; } }

.u-mr210_half {
  margin-right: 105px !important; }
  @media all and (min-width: 800px) {
    .u-mr210_half {
      margin-right: 210px !important; } }

.u-pt210_half {
  padding-top: 105px !important; }
  @media all and (min-width: 800px) {
    .u-pt210_half {
      padding-top: 210px !important; } }

.u-pb210_half {
  padding-bottom: 105px !important; }
  @media all and (min-width: 800px) {
    .u-pb210_half {
      padding-bottom: 210px !important; } }

.u-mt215_half {
  margin-top: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt215_half {
      margin-top: 215px !important; } }

.u-mb215_half {
  margin-bottom: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb215_half {
      margin-bottom: 215px !important; } }

.u-ml215_half {
  margin-left: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml215_half {
      margin-left: 215px !important; } }

.u-mr215_half {
  margin-right: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr215_half {
      margin-right: 215px !important; } }

.u-pt215_half {
  padding-top: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt215_half {
      padding-top: 215px !important; } }

.u-pb215_half {
  padding-bottom: 107.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb215_half {
      padding-bottom: 215px !important; } }

.u-mt220_half {
  margin-top: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mt220_half {
      margin-top: 220px !important; } }

.u-mb220_half {
  margin-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mb220_half {
      margin-bottom: 220px !important; } }

.u-ml220_half {
  margin-left: 110px !important; }
  @media all and (min-width: 800px) {
    .u-ml220_half {
      margin-left: 220px !important; } }

.u-mr220_half {
  margin-right: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mr220_half {
      margin-right: 220px !important; } }

.u-pt220_half {
  padding-top: 110px !important; }
  @media all and (min-width: 800px) {
    .u-pt220_half {
      padding-top: 220px !important; } }

.u-pb220_half {
  padding-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-pb220_half {
      padding-bottom: 220px !important; } }

.u-mt225_half {
  margin-top: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt225_half {
      margin-top: 225px !important; } }

.u-mb225_half {
  margin-bottom: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb225_half {
      margin-bottom: 225px !important; } }

.u-ml225_half {
  margin-left: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml225_half {
      margin-left: 225px !important; } }

.u-mr225_half {
  margin-right: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr225_half {
      margin-right: 225px !important; } }

.u-pt225_half {
  padding-top: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt225_half {
      padding-top: 225px !important; } }

.u-pb225_half {
  padding-bottom: 112.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb225_half {
      padding-bottom: 225px !important; } }

.u-mt230_half {
  margin-top: 115px !important; }
  @media all and (min-width: 800px) {
    .u-mt230_half {
      margin-top: 230px !important; } }

.u-mb230_half {
  margin-bottom: 115px !important; }
  @media all and (min-width: 800px) {
    .u-mb230_half {
      margin-bottom: 230px !important; } }

.u-ml230_half {
  margin-left: 115px !important; }
  @media all and (min-width: 800px) {
    .u-ml230_half {
      margin-left: 230px !important; } }

.u-mr230_half {
  margin-right: 115px !important; }
  @media all and (min-width: 800px) {
    .u-mr230_half {
      margin-right: 230px !important; } }

.u-pt230_half {
  padding-top: 115px !important; }
  @media all and (min-width: 800px) {
    .u-pt230_half {
      padding-top: 230px !important; } }

.u-pb230_half {
  padding-bottom: 115px !important; }
  @media all and (min-width: 800px) {
    .u-pb230_half {
      padding-bottom: 230px !important; } }

.u-mt235_half {
  margin-top: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt235_half {
      margin-top: 235px !important; } }

.u-mb235_half {
  margin-bottom: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb235_half {
      margin-bottom: 235px !important; } }

.u-ml235_half {
  margin-left: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml235_half {
      margin-left: 235px !important; } }

.u-mr235_half {
  margin-right: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr235_half {
      margin-right: 235px !important; } }

.u-pt235_half {
  padding-top: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt235_half {
      padding-top: 235px !important; } }

.u-pb235_half {
  padding-bottom: 117.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb235_half {
      padding-bottom: 235px !important; } }

.u-mt240_half {
  margin-top: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mt240_half {
      margin-top: 240px !important; } }

.u-mb240_half {
  margin-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mb240_half {
      margin-bottom: 240px !important; } }

.u-ml240_half {
  margin-left: 120px !important; }
  @media all and (min-width: 800px) {
    .u-ml240_half {
      margin-left: 240px !important; } }

.u-mr240_half {
  margin-right: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mr240_half {
      margin-right: 240px !important; } }

.u-pt240_half {
  padding-top: 120px !important; }
  @media all and (min-width: 800px) {
    .u-pt240_half {
      padding-top: 240px !important; } }

.u-pb240_half {
  padding-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-pb240_half {
      padding-bottom: 240px !important; } }

.u-mt245_half {
  margin-top: 122.5px !important; }
  @media all and (min-width: 800px) {
    .u-mt245_half {
      margin-top: 245px !important; } }

.u-mb245_half {
  margin-bottom: 122.5px !important; }
  @media all and (min-width: 800px) {
    .u-mb245_half {
      margin-bottom: 245px !important; } }

.u-ml245_half {
  margin-left: 122.5px !important; }
  @media all and (min-width: 800px) {
    .u-ml245_half {
      margin-left: 245px !important; } }

.u-mr245_half {
  margin-right: 122.5px !important; }
  @media all and (min-width: 800px) {
    .u-mr245_half {
      margin-right: 245px !important; } }

.u-pt245_half {
  padding-top: 122.5px !important; }
  @media all and (min-width: 800px) {
    .u-pt245_half {
      padding-top: 245px !important; } }

.u-pb245_half {
  padding-bottom: 122.5px !important; }
  @media all and (min-width: 800px) {
    .u-pb245_half {
      padding-bottom: 245px !important; } }

.u-mt250_half {
  margin-top: 125px !important; }
  @media all and (min-width: 800px) {
    .u-mt250_half {
      margin-top: 250px !important; } }

.u-mb250_half {
  margin-bottom: 125px !important; }
  @media all and (min-width: 800px) {
    .u-mb250_half {
      margin-bottom: 250px !important; } }

.u-ml250_half {
  margin-left: 125px !important; }
  @media all and (min-width: 800px) {
    .u-ml250_half {
      margin-left: 250px !important; } }

.u-mr250_half {
  margin-right: 125px !important; }
  @media all and (min-width: 800px) {
    .u-mr250_half {
      margin-right: 250px !important; } }

.u-pt250_half {
  padding-top: 125px !important; }
  @media all and (min-width: 800px) {
    .u-pt250_half {
      padding-top: 250px !important; } }

.u-pb250_half {
  padding-bottom: 125px !important; }
  @media all and (min-width: 800px) {
    .u-pb250_half {
      padding-bottom: 250px !important; } }

.u-mb15_23rd {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb15_23rd {
      margin-bottom: 15px !important; } }

.u-mb30_23rd {
  margin-bottom: 20px !important; }
  @media all and (min-width: 800px) {
    .u-mb30_23rd {
      margin-bottom: 30px !important; } }

.u-mb45_23rd {
  margin-bottom: 30px !important; }
  @media all and (min-width: 800px) {
    .u-mb45_23rd {
      margin-bottom: 45px !important; } }

.u-mb60_23rd {
  margin-bottom: 40px !important; }
  @media all and (min-width: 800px) {
    .u-mb60_23rd {
      margin-bottom: 60px !important; } }

.u-mb75_23rd {
  margin-bottom: 50px !important; }
  @media all and (min-width: 800px) {
    .u-mb75_23rd {
      margin-bottom: 75px !important; } }

.u-mb90_23rd {
  margin-bottom: 60px !important; }
  @media all and (min-width: 800px) {
    .u-mb90_23rd {
      margin-bottom: 90px !important; } }

.u-mb105_23rd {
  margin-bottom: 70px !important; }
  @media all and (min-width: 800px) {
    .u-mb105_23rd {
      margin-bottom: 105px !important; } }

.u-mb120_23rd {
  margin-bottom: 80px !important; }
  @media all and (min-width: 800px) {
    .u-mb120_23rd {
      margin-bottom: 120px !important; } }

.u-mb135_23rd {
  margin-bottom: 90px !important; }
  @media all and (min-width: 800px) {
    .u-mb135_23rd {
      margin-bottom: 135px !important; } }

.u-mb150_23rd {
  margin-bottom: 100px !important; }
  @media all and (min-width: 800px) {
    .u-mb150_23rd {
      margin-bottom: 150px !important; } }

.u-mb165_23rd {
  margin-bottom: 110px !important; }
  @media all and (min-width: 800px) {
    .u-mb165_23rd {
      margin-bottom: 165px !important; } }

.u-mb180_23rd {
  margin-bottom: 120px !important; }
  @media all and (min-width: 800px) {
    .u-mb180_23rd {
      margin-bottom: 180px !important; } }

.u-mb195_23rd {
  margin-bottom: 130px !important; }
  @media all and (min-width: 800px) {
    .u-mb195_23rd {
      margin-bottom: 195px !important; } }

.u-mb210_23rd {
  margin-bottom: 140px !important; }
  @media all and (min-width: 800px) {
    .u-mb210_23rd {
      margin-bottom: 210px !important; } }

.u-mb225_23rd {
  margin-bottom: 150px !important; }
  @media all and (min-width: 800px) {
    .u-mb225_23rd {
      margin-bottom: 225px !important; } }

.u-mb240_23rd {
  margin-bottom: 160px !important; }
  @media all and (min-width: 800px) {
    .u-mb240_23rd {
      margin-bottom: 240px !important; } }

.u-mb255_23rd {
  margin-bottom: 170px !important; }
  @media all and (min-width: 800px) {
    .u-mb255_23rd {
      margin-bottom: 255px !important; } }

.u-mb270_23rd {
  margin-bottom: 180px !important; }
  @media all and (min-width: 800px) {
    .u-mb270_23rd {
      margin-bottom: 270px !important; } }

.u-mb10_level {
  margin-bottom: 3.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb10_level {
      margin-bottom: 5px !important; } }
  @media all and (min-width: 800px) {
    .u-mb10_level {
      margin-bottom: 10px !important; } }

.u-mb20_level {
  margin-bottom: 6.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb20_level {
      margin-bottom: 10px !important; } }
  @media all and (min-width: 800px) {
    .u-mb20_level {
      margin-bottom: 20px !important; } }

.u-mb30_level {
  margin-bottom: 10px !important; }
  @media all and (min-width: 500px) {
    .u-mb30_level {
      margin-bottom: 15px !important; } }
  @media all and (min-width: 800px) {
    .u-mb30_level {
      margin-bottom: 30px !important; } }

.u-mb40_level {
  margin-bottom: 13.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb40_level {
      margin-bottom: 20px !important; } }
  @media all and (min-width: 800px) {
    .u-mb40_level {
      margin-bottom: 40px !important; } }

.u-mb50_level {
  margin-bottom: 16.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb50_level {
      margin-bottom: 25px !important; } }
  @media all and (min-width: 800px) {
    .u-mb50_level {
      margin-bottom: 50px !important; } }

.u-mb60_level {
  margin-bottom: 20px !important; }
  @media all and (min-width: 500px) {
    .u-mb60_level {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 800px) {
    .u-mb60_level {
      margin-bottom: 60px !important; } }

.u-mb70_level {
  margin-bottom: 23.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb70_level {
      margin-bottom: 35px !important; } }
  @media all and (min-width: 800px) {
    .u-mb70_level {
      margin-bottom: 70px !important; } }

.u-mb80_level {
  margin-bottom: 26.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb80_level {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 800px) {
    .u-mb80_level {
      margin-bottom: 80px !important; } }

.u-mb90_level {
  margin-bottom: 30px !important; }
  @media all and (min-width: 500px) {
    .u-mb90_level {
      margin-bottom: 45px !important; } }
  @media all and (min-width: 800px) {
    .u-mb90_level {
      margin-bottom: 90px !important; } }

.u-mb100_level {
  margin-bottom: 33.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb100_level {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 800px) {
    .u-mb100_level {
      margin-bottom: 100px !important; } }

.u-mb110_level {
  margin-bottom: 36.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb110_level {
      margin-bottom: 55px !important; } }
  @media all and (min-width: 800px) {
    .u-mb110_level {
      margin-bottom: 110px !important; } }

.u-mb120_level {
  margin-bottom: 40px !important; }
  @media all and (min-width: 500px) {
    .u-mb120_level {
      margin-bottom: 60px !important; } }
  @media all and (min-width: 800px) {
    .u-mb120_level {
      margin-bottom: 120px !important; } }

.u-mb130_level {
  margin-bottom: 43.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb130_level {
      margin-bottom: 65px !important; } }
  @media all and (min-width: 800px) {
    .u-mb130_level {
      margin-bottom: 130px !important; } }

.u-mb140_level {
  margin-bottom: 46.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb140_level {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 800px) {
    .u-mb140_level {
      margin-bottom: 140px !important; } }

.u-mb150_level {
  margin-bottom: 50px !important; }
  @media all and (min-width: 500px) {
    .u-mb150_level {
      margin-bottom: 75px !important; } }
  @media all and (min-width: 800px) {
    .u-mb150_level {
      margin-bottom: 150px !important; } }

.u-mb160_level {
  margin-bottom: 53.33333px !important; }
  @media all and (min-width: 500px) {
    .u-mb160_level {
      margin-bottom: 80px !important; } }
  @media all and (min-width: 800px) {
    .u-mb160_level {
      margin-bottom: 160px !important; } }

.u-mb170_level {
  margin-bottom: 56.66667px !important; }
  @media all and (min-width: 500px) {
    .u-mb170_level {
      margin-bottom: 85px !important; } }
  @media all and (min-width: 800px) {
    .u-mb170_level {
      margin-bottom: 170px !important; } }

.u-mb180_level {
  margin-bottom: 60px !important; }
  @media all and (min-width: 500px) {
    .u-mb180_level {
      margin-bottom: 90px !important; } }
  @media all and (min-width: 800px) {
    .u-mb180_level {
      margin-bottom: 180px !important; } }

.u-mb-minus10_half {
  margin-bottom: -5px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus10_half {
      margin-bottom: -10px !important; } }

.u-mb-minus20_half {
  margin-bottom: -10px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus20_half {
      margin-bottom: -20px !important; } }

.u-mb-minus30_half {
  margin-bottom: -15px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus30_half {
      margin-bottom: -30px !important; } }

.u-mb-minus40_half {
  margin-bottom: -20px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus40_half {
      margin-bottom: -40px !important; } }

.u-mb-minus50_half {
  margin-bottom: -25px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus50_half {
      margin-bottom: -50px !important; } }

.u-mb-minus60_half {
  margin-bottom: -30px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus60_half {
      margin-bottom: -60px !important; } }

.u-mb-minus70_half {
  margin-bottom: -35px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus70_half {
      margin-bottom: -70px !important; } }

.u-mb-minus80_half {
  margin-bottom: -40px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus80_half {
      margin-bottom: -80px !important; } }

.u-mb-minus90_half {
  margin-bottom: -45px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus90_half {
      margin-bottom: -90px !important; } }

.u-mb-minus100_half {
  margin-bottom: -50px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus100_half {
      margin-bottom: -100px !important; } }

.u-mb-minus110_half {
  margin-bottom: -55px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus110_half {
      margin-bottom: -110px !important; } }

.u-mb-minus120_half {
  margin-bottom: -60px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus120_half {
      margin-bottom: -120px !important; } }

.u-mb-minus130_half {
  margin-bottom: -65px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus130_half {
      margin-bottom: -130px !important; } }

.u-mb-minus140_half {
  margin-bottom: -70px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus140_half {
      margin-bottom: -140px !important; } }

.u-mb-minus150_half {
  margin-bottom: -75px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus150_half {
      margin-bottom: -150px !important; } }

.u-mb-minus160_half {
  margin-bottom: -80px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus160_half {
      margin-bottom: -160px !important; } }

.u-mb-minus170_half {
  margin-bottom: -85px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus170_half {
      margin-bottom: -170px !important; } }

.u-mb-minus180_half {
  margin-bottom: -90px !important; }
  @media all and (min-width: 800px) {
    .u-mb-minus180_half {
      margin-bottom: -180px !important; } }

.u-mb1em {
  margin-bottom: 1em !important; }

.u-mb1em_half {
  margin-bottom: 0.5em !important; }
  @media all and (min-width: 800px) {
    .u-mb1em_half {
      margin-bottom: 1em !important; } }

.u-mb2em {
  margin-bottom: 2em !important; }

.u-mb2em_half {
  margin-bottom: 1em !important; }
  @media all and (min-width: 800px) {
    .u-mb2em_half {
      margin-bottom: 2em !important; } }

.u-mb3em {
  margin-bottom: 3em !important; }

.u-mb3em_half {
  margin-bottom: 1.5em !important; }
  @media all and (min-width: 800px) {
    .u-mb3em_half {
      margin-bottom: 3em !important; } }

.u-mb4em {
  margin-bottom: 4em !important; }

.u-mb4em_half {
  margin-bottom: 2em !important; }
  @media all and (min-width: 800px) {
    .u-mb4em_half {
      margin-bottom: 4em !important; } }

.u-mb5em {
  margin-bottom: 5em !important; }

.u-mb5em_half {
  margin-bottom: 2.5em !important; }
  @media all and (min-width: 800px) {
    .u-mb5em_half {
      margin-bottom: 5em !important; } }

.u-mb6em {
  margin-bottom: 6em !important; }

.u-mb6em_half {
  margin-bottom: 3em !important; }
  @media all and (min-width: 800px) {
    .u-mb6em_half {
      margin-bottom: 6em !important; } }

.u-mb7em {
  margin-bottom: 7em !important; }

.u-mb7em_half {
  margin-bottom: 3.5em !important; }
  @media all and (min-width: 800px) {
    .u-mb7em_half {
      margin-bottom: 7em !important; } }

.u-mb8em {
  margin-bottom: 8em !important; }

.u-mb8em_half {
  margin-bottom: 4em !important; }
  @media all and (min-width: 800px) {
    .u-mb8em_half {
      margin-bottom: 8em !important; } }

.u-mb9em {
  margin-bottom: 9em !important; }

.u-mb9em_half {
  margin-bottom: 4.5em !important; }
  @media all and (min-width: 800px) {
    .u-mb9em_half {
      margin-bottom: 9em !important; } }

.u-mb10em {
  margin-bottom: 10em !important; }

.u-mb10em_half {
  margin-bottom: 5em !important; }
  @media all and (min-width: 800px) {
    .u-mb10em_half {
      margin-bottom: 10em !important; } }

.u-mb0_10 {
  margin-bottom: 10px !important; }
  @media all and (min-width: 800px) {
    .u-mb0_10 {
      margin-bottom: 0 !important; } }

.u-mbProductsOb01 {
  margin-bottom: 80px !important; }
  @media all and (min-width: 480px) {
    .u-mbProductsOb01 {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 800px) {
    .u-mbProductsOb01 {
      margin-bottom: 120px !important; } }

.e-fadeIn1 {
  animation: 0.3s ease-in 0s 1 both fadeIn !important; }

.e-fadeOut1 {
  animation: 0.3s ease-in 0s 1 both fadeOut !important; }

@keyframes fadeIn {
  0% {
    opacity: 0;
    pointer-events: none; }
  99% {
    opacity: 1;
    pointer-events: none; }
  100% {
    opacity: 1;
    pointer-events: auto; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    pointer-events: auto; }
  1% {
    opacity: 1;
    pointer-events: none; }
  100% {
    opacity: 0;
    pointer-events: none; } }

@keyframes drawerIn {
  0% {
    transform: translateY(-105vh);
    background: rgba(40, 48, 79, 0);
    opacity: 0; }
  1% {
    transform: translateY(0);
    background: rgba(40, 48, 79, 0.9);
    opacity: 0; }
  100% {
    transform: translateY(0);
    background: rgba(40, 48, 79, 0.9);
    opacity: 1; } }

@keyframes drawerOut {
  0% {
    transform: translateY(0);
    background: rgba(40, 48, 79, 0.9);
    opacity: 1; }
  99% {
    transform: translateY(0);
    background: rgba(40, 48, 79, 0.9);
    opacity: 0; }
  100% {
    transform: translateY(-105vh);
    background: rgba(40, 48, 79, 0);
    opacity: 0; } }

@keyframes slideRight {
  0% {
    transform: translateX(-110%); }
  1% {
    transform: translateX(0%); }
  20% {
    transform: translateX(0%); }
  100% {
    transform: translateX(110%); } }

.e-kvCatchcopyLoad {
  animation: 0.5s ease-in-out 1s 1 both fadeIn !important; }

.e-kvLogoLoad {
  position: relative;
  overflow: hidden;
  animation: 0.5s ease-in-out 1s 1 both fadeIn !important;
  opacity: 0;
  /* 0.00s：全体非表示
     1.00s：全体フェードイン開始
     1.50s：全体フェードイン完了
     3.00s：rectのxは-100%
     3.24s：rectのxは0%
     3.96s：rectのxは0%
     4.20s：rectのxは100%
  */ }
  .e-kvLogoLoad::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    width: 100%;
    height: 100%;
    content: "";
    background-color: white;
    animation: 1s cubic-bezier(0.22, 0.61, 0.36, 1) 3s 1 both kvLogoChangeRect; }
  .e-kvLogoLoad .e-kvLogoChangeImg1 {
    opacity: 0;
    animation: 0s linear 3.33333s 1 both kvLogoChangeImg1; }
  .e-kvLogoLoad .e-kvLogoChangeImg2 {
    opacity: 0;
    animation: 0s linear 3.66667s 1 both kvLogoChangeImg2; }

@keyframes kvLogoChangeImg1 {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes kvLogoChangeRect {
  0% {
    transform: translateX(-100%); }
  36% {
    transform: translateX(0); }
  64% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

@keyframes kvLogoChangeImg2 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.js-animeLogoEn {
  opacity: 0; }

.e-animeLogoChange {
  position: relative;
  overflow: hidden;
  /* 0.00s：全体非表示
     1.00s：全体フェードイン開始
     1.50s：全体フェードイン完了
     3.00s：rectのxは-100%
     3.24s：rectのxは0%
     3.96s：rectのxは0%
     4.20s：rectのxは100%
  */ }
  .e-animeLogoChange::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #28304f;
    animation: 1s cubic-bezier(0.22, 0.61, 0.36, 1) 1.5s 1 both animeLogoChangeRect; }
  .e-animeLogoChange .e-animeLogoChangeImg1 {
    animation: 0.1s ease-in 1.7s 1 both animeLogoChangeImg1; }
  .e-animeLogoChange .e-animeLogoChangeImg2 {
    animation: 0.1s ease-in 1.9s 1 both animeLogoChangeImg2; }

@keyframes animeLogoChangeImg1 {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes animeLogoChangeRect {
  0% {
    transform: translateX(-100%); }
  36% {
    transform: translateX(0); }
  64% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

@keyframes animeLogoChangeImg2 {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
