.p3-text {  
  &.-absolute {
    position: absolute;
  }
  
  &.-relative {
    position: relative;
  }

  ///// テキスト寄せ /////
  &.-left {
    text-align: left;
  }  
  &.-center {
    text-align: center;
  }  
  &.-right {
    text-align: right;
  }  
  &.-justify {
    text-align: justify;
  }
  
  ///// テキストカラー /////
  &.-wh {
    color: color-wh(1);
  }

  &.-bk {
    color: color-bk(1);
  }

  &.-white01 {
    color: color-white01(1);
  }
  &.-white02 {
    color: color-white02(1);
  }

  &.-black01 {
    color: color-black01(1);
  }

  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-gray03 {
    color: color-gray03(1);
  }

  &.-blue01 {
    color: color-blue01(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }
  &.-blue03 {
    color: color-blue03(1);
  }
  &.-blue04 {
    color: color-blue04(1);
  }
  &.-blue05 {
    color: color-blue05(1);
  }

  &.-purple01 {
    color: color-purple01(1);
  }
  &.-purple02 {
    color: color-purple02(1);
  }
  &.-purple03 {
    color: color-purple03(1);
  }
  
  &.-orange01 {
    color: color-orange01(1);
  }

  &.-red01 {
    color: color-red01(1);
  }

  &.-yellow01 {
    color: color-yellow01(1);
  }
  
  
  //mix-blend-mode
  &.-exclusion {
    mix-blend-mode: exclusion;
  }
  
  
  ///// フォントサイズ調整(親要素に対するパーセント) /////
  &.-fs060per {
    font-size: 60%;
  }

  ///// letter-spacing /////
  //マイナス値。句読点用
  &.-lsm050 {
    letter-spacing: -0.5em;
  }
  &.-lsm025 {
    letter-spacing: -0.25em;
  }
  //VA80相当
  &.-ls008 {
    letter-spacing: 0.08em;
  }
  //VA100相当
  &.-ls010 {
    letter-spacing: 0.1em;
  }
  //VA250相当・VA255相当
  &.-ls025 {
    letter-spacing: 0.25em;
  }


  ///// 複数列テキスト /////
  &.-col2 {
    div {
      column-count: 1;

      @include breakpoint-pc {
        column-count: 2;
        column-gap: 5vw;
      }
      @include breakpoint-contentinner {
        column-gap: 60px;
      }
    }
  }
  
  
  ///// 縦書き /////
  &.-tategaki {
    @include tategaki("upright");
    
    &.-mixed {
      @include tategaki("mixed");
    }
    &.-sideways {
      @include tategaki("sideways");
    }

    &.-center {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.-pcTate_spYoko {
    @include breakpoint-pc {
      @include tategaki("upright");
    }
    
    &.-mixed {
      @include breakpoint-pc {
        @include tategaki("mixed");
      }
    }
    &.-sideways {
      @include breakpoint-pc {
        @include tategaki("sideways");
      }
    }
  }
  
  ///// 縦書き解除 /////
  &.-tateOff {
    @include tategaki-off();
  }


  ///// 注釈(上付き文字) /////
  &.-annotation {
    font-size: 50%;
    vertical-align: super;
  }


  ///// テキスト /////
  //noto sans
  &.-t01 {
    @include font-ntsan("r");
    @include fontSize(1, 1);
    @include lineHeight(1.8, 1.8);

    &.-regular {
      @include font-ntsan("r");
    }
    &.-medium {
      @include font-ntsan("m");
    }
    &.-bold {
      @include font-ntsan("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }

  &.-t02 {
    @include font-ntsan("r");
    @include fontSize(1.3, 1.04); //1.3 * 0.8 = 1.04
    @include lineHeight(2.3, 2.3);

    &.-medium {
      @include font-ntsan("m");
    }
    &.-bold {
      @include font-ntsan("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh140 {
      @include lineHeight(1.4, 1.4);
    }
    &.-lh180 {
      @include lineHeight(1.8, 1.8);
    }

    @include breakpoint-pc {
    }
  }

  &.-t03 {
    @include font-ntsan("r");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    @include lineHeight(2, 2);

    &.-medium {
      @include font-ntsan("m");
    }
    &.-bold {
      @include font-ntsan("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }

  &.-t04 {
    @include font-ntsan("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    @include lineHeight(2.4, 2.4);

    &.-medium {
      @include font-ntsan("m");
    }
    &.-bold {
      @include font-ntsan("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh180 {
      @include lineHeight(1.8, 1.8);
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t05 {
    @include font-ntsan("r");
    @include fontSize(1.4, 1.12); //1.4 * 0.8 = 1.12
    @include lineHeight(2, 2);

    &.-medium {
      @include font-ntsan("m");
    }
    &.-bold {
      @include font-ntsan("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }

  //しっぽり明朝
  &.-t21 {
    @include font-sprmin("r");
    @include fontSize(1.4, 1.12); //1.4 * 0.8 = 1.12
    @include lineHeight(2.1, 2.1);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t22 {
    @include font-sprmin("r");
    @include fontSize(1.5, 1.2); //1.5 * 0.8 = 1.2
    @include lineHeight(2, 2);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t23 {
    @include font-sprmin("r");
    @include fontSize(1.6, 1.28); //1.6 * 0.8 = 1.28
    @include lineHeight(1.8, 1.8);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t24 {
    @include font-sprmin("r");
    @include fontSize(1.7, 1.36); //1.7 * 0.8 = 1.36
    @include lineHeight(2.3, 2.3);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh180 {
      @include lineHeight(1.8, 1.8);
    }
    &.-lh200 {
      @include lineHeight(2, 2);
    }
    &.-lh210 {
      @include lineHeight(2.1, 2.1);
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t25 {
    @include font-sprmin("r");
    @include fontSize(2.0, 1.6); //2.0 * 0.8 = 1.6
    @include lineHeight(2, 2);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh210 {
      @include lineHeight(2.1, 2.1);
    }

    @include breakpoint-pc {
    }
  }
  
  &.-t26 {
    @include font-sprmin("r");
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    @include lineHeight(2.3, 2.3);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh130 {
      @include lineHeight(1.3, 1.3);
    }
    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }

    @include breakpoint-pc {
    }
    
    &.-tgFeatures {
      font-size: 1.92rem; //2.4 * 0.8 = 1.92
      
      @include breakpoint-pc {
        font-size: 2.16rem; //2.4 * 0.9 = 2.16
      }
      @include breakpoint-narrow {
        font-size: 2.4rem;
      }
    }
  }
  
  &.-t27 {
    @include font-sprmin("r");
    font-size: 2.1rem; //2.8 * 0.75 = 2.1
    @include lineHeight(2.1, 2.1);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.24rem; //2.8 * 0.8 = 2.24
    }
    @include breakpoint-pc {
      font-size: 2.8rem;
    }
  }
  
  &.-t28 {
    @include font-sprmin("r");
    font-size: 2.175rem; //2.9 * 0.75 = 2.175
    @include lineHeight(1.9, 1.9);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.32rem; //2.9 * 0.8 = 2.32
    }
    @include breakpoint-pc {
      font-size: 2.9rem;
    }
  }

  &.-t29 {
    @include font-sprmin("r");
    font-size: 2.24rem; //3.2 * 0.7 = 2.24
    @include lineHeight(2, 2);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.56rem; //3.2 * 0.8 = 2.56
    }
    @include breakpoint-pc {
      font-size: 3.2rem;
    }
  }
  
  &.-t30 {
    @include font-sprmin("r");
    font-size: 2.52rem; //3.6 * 0.7 = 2.52
    @include lineHeight(2, 2);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.88rem; //3.6 * 0.8 = 2.88
    }
    @include breakpoint-pc {
      font-size: 3.6rem;
    }
  }
  
  &.-t31 {
    @include font-sprmin("r");
    font-size: 3.22rem; //4.6 * 0.7 = 3.22
    @include lineHeight(2, 2);

    &.-medium {
      @include font-sprmin("m");
    }
    &.-semibold {
      @include font-sprmin("sb");
    }
    &.-bold {
      @include font-sprmin("bd");
    }
    &.-extrabold {
      @include font-sprmin("eb");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 3.68rem; //4.6 * 0.8 = 3.68
    }
    @include breakpoint-pc {
      font-size: 4.6rem;
    }
  }


  //YakuHanMP
  &.-t21, &.-t22, &.-t23, &.-t24, &.-t25, &.-t26, &.-t27, &.-t28, &.-t29, &.-t30, &.-t31, &.-t32, &.-t33, &.-t34, &.-t35, &.-t36, &.-t37, &.-t38, &.-t39 {
    &.-yakuhanmp {
      @include font-yakuhanmp("r");

      &.-medium {
        @include font-yakuhanmp("m");
      }
      &.-semibold {
        @include font-yakuhanmp("sb");
      }
      &.-bold {
        @include font-yakuhanmp("bd");
      }
      &.-extrabold {
        @include font-yakuhanmp("eb");
      }
    }
  }


  //しっぽりアンチック
  &.-t41 {
    @include font-spratq();
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    @include lineHeight(2, 2);

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }


  //Cormorant Garamond
  &.-t51 {
    @include font-cmrgrm("m");
    @include fontSize(2.0, 1.6); //2.0 * 0.8 = 1.6
    @include lineHeight(1, 1);

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }

  &.-t52 {
    @include font-cmrgrm("m");
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    @include lineHeight(1, 1);

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }


  //Roboto Serif
  &.-t61 {
    @include font-rbtsrf("r");
    font-size: 2.25rem; //3.0 * 0.75 = 2.25
    @include lineHeight(1, 1);

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-contentinnerhalf {
      font-size: 2.4rem; //3.0 * 0.8 = 2.4
    }
    @include breakpoint-pc {
      font-size: 3rem;
    }
  }


  //Crimson Text
  &.-t71 {
    @include font-critxt("r");
    @include fontSize(2.0, 1.6); //2.0 * 0.8 = 1.6
    @include lineHeight(1, 1);

    &.-semibold {
      @include font-critxt("sb");
    }
    &.-bold {
      @include font-critxt("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }

  &.-t72 {
    @include font-critxt("r");
    @include fontSize(2.4, 1.92); //2.4 * 0.8 = 1.92
    @include lineHeight(1, 1);

    &.-semibold {
      @include font-critxt("sb");
    }
    &.-bold {
      @include font-critxt("bd");
    }

    &.-lh100 {
      @include lineHeight(1, 1);
    }

    @include breakpoint-pc {
    }
  }


/*
  ///// リンク /////
  &.-link01 {
    color: color-txt08(1);
    text-decoration: underline;
  }
*/

  ///// リスト /////
  &.-list01 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;

    @include breakpoint-pc {
    }

    li {
      list-style-type: inherit;
    }
  }
/*
  &.-list02 {
    padding-left: 5px;
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "◎";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "※";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list04 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-ntsan("r");
    @include fontSize(1.5, 1.4);
    color: color-txt01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }
*/

}