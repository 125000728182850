body {
  color: color-blue02(1);
  background: color-white01(1);
  width: 100%;
  @include fontSize(1.5, 1.4);
  line-height: 1;
  @include font-ntsan("r");

  @include breakpoint-pc {
  }
}

a {
  color: inherit;
  text-decoration: none;
}

::selection {
  background: color-effect02(1);
}
::-moz-selection {
  background: color-effect02(1);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}

//luxy
#luxy {
  background-color: color-white01(1);
}