.p3-img {
  &.-i01 {
    
  }
  
  ////// トップページ /////
  //コンテンツ部のアニメーションロゴ
  &.-animeLogo {
    position: relative;
//    width: 152px; //189px * 0.8 = 151.2px
//    height: 62px; //50px * 0.8 = 49.6px
    width: 189px;
    height: 62px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-pc {
      width: 189px;
      height: 62px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
  }


  //シーン02
  &.-frontScene02 {
    display: block;
    position: absolute;
    top: -3.5vh; //-35px / 1000px * 100vh
    left: 50%;
    transform: translate(-50%, -100%);
    width: 104px; //209px / 2
    height: 80px; //160px / 2
    
    @media all and (min-width: 800px) and (min-height: 600px) {  //幅800px & 高さ800以上
      top: -7vh; //-70px / 1000px * 100vh
      width: 156px; //209px * 0.75 = 156.75
      height: 120px; //160px * 0.75 = 120
    }
    @media all and (min-width: 800px) and (min-height: 1000px) {  //幅800px & 高さ1000以上
      top: -70px;
      width: 209px;
      height: 160px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  
  //シーン03
  &.-frontScene0301 {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -25%);
    width: 87.5vw; //980px / 1120px * 100vw
    height: 43.839285714285714vw; //491px / 1120px * 100vw
    
    @include breakpoint-pc {
    }
    @include breakpoint-narrow {
      top: 0;
      width: 980px;
      height: 491px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.-frontScene0302Wrap {
    display: block;
    position: relative;
    margin-top: -22vh;

    @include breakpoint-pc {
      margin-top: 0;
    }

    &::before, &::after {
      display: block;
      position: relative;
      content: "";
    }
  }
  &.-frontScene0302 {
    height: calc(300px * 5);

    @include breakpoint-pc {
      height: calc(610px * 5);
    }
      
    div {
      display: block;
      position: relative;
      width: 49vw;
      height: 75vh;
      top: 12.5vh;
      margin: auto;
      background-image: url("../images/front/kv/scene03_img02_sp.jpg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      -webkit-transition: background-image .3s linear;
      transition: background-image .3s linear;
      
      @include breakpoint-pc {
        width: 35vw;
        background-image: url("../images/front/kv/scene03_img02.jpg");
      }
      @include breakpoint-narrow {
  
      }
    }
  }
  
  
  ///// 取扱商品 /////
  &.-frontLineupSubttl {
    width: 101px; //81px * 0.8 = 80.8px
    height: 24px; //30px * 0.8 = 24px
    
    @include breakpoint-pc {
      width: 101px;
      height: 30px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  
  &.-frontLineupImg {
    width: 100%;
    height: 100%;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.-frontLineupRegular {
    position: absolute;
    bottom: -5px - 16px - 5px;  //-16pxは、英字見出しのfont-size、2つ目の-5pxは微調整
    right: 0;
    transform: translate(0, 0);
//    bottom: -5px;  
//    right: -30px;
//    transform: translate(100%, 0);
    width: 93px;  //186px * 0.5 = 93px
    height: 51px;  //102px * 0.5 = 51px

    @include breakpoint-pc {
      bottom: -3.225806451612903vw;  //40px / 1240px * 100vw
      right: -3.225806451612903vw;
      transform: translate(0, 100%);
      width: 15vw;  //186px / 1240px * 100vw
      height: 8.225806451612903vw;  //102px / 1240px * 100vw
    }
    @include breakpoint-narrowwrap {
      bottom: -40px;
      right: -40px;
      width: 186px;
      height: 102px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
  
  &.-frontLineupSeasonal {
    position: absolute;
    bottom: -10px - 16px - 5px;  //-16pxは、英字見出しのfont-size、-5pxは微調整  
    right: 0;
    transform: translate(0, 0);
//    bottom: -10px;  
//    right: -30px;
//    transform: translate(100%, 0);
    width: 80px;  //159px * 0.5 = 79.5px
    height: 108px;  //215px * 0.5 = 107.5px

    @include breakpoint-pc {
      right: auto;
      bottom: -0.806451612903226vw;  //10px / 1240px * 100vw
      left: -0.806451612903226vw;
      transform: translate(0, 100%);
      width: 12.82258064516129vw;  //159px / 1240px * 100vw
      height: 17.338709677419355vw;  //215px / 1240px * 100vw
    }
    @include breakpoint-narrowwrap {
      bottom: -10px;
      left: -10px;
      width: 159px;
      height: 215px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.-frontLineupOriginal {
    position: absolute;
    bottom: -10px - 16px - 5px;  //-16pxは、英字見出しのfont-size、-5pxは微調整  
    right: 0;
    transform: translate(0, 0);
//    bottom: -10px;  
//    right: -30px;
//    transform: translate(100%, 0);
    width: 71px;  //142px * 0.5 = 71px
    height: 73px;  //146px * 0.5 = 51px

    @include breakpoint-pc {
      bottom: -0.806451612903226vw;  //10px / 1240px * 100vw
      right: -2.419354838709677vw;  //30px / 1240px * 100vw
      transform: translate(0, 100%);
      width: 11.451612903225806vw;  //142px / 1240px * 100vw
      height: 11.774193548387097vw;  //146px / 1240px * 100vw
    }
    @include breakpoint-narrowwrap {
      bottom: -10px;
      right: -30px;
      width: 142px;
      height: 146px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  
  ///// ごあいさつ /////
  &.-frontGreetings {
    position: relative;
    margin-bottom: 15px;
    
    @include breakpoint-pc {
      position: absolute;
      top: 30px;
      right: 0;
      width: 71.4%; //800px / 1120px * 100% = 71.428571428571429%
      margin-bottom: 0;
    }
  }


  ///// 製品開発 - つえがさ - 杖・傘 /////
  &.-frontOriginalTk {
    &Bg {
      position: relative;
      width: 100%;
      height: 100%;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &Txt {
      display: block;
      position: absolute;
      width: 140px / 560px * 100%; //140px / 560px * 100%
      height: 140px / 580px * 100%;  //140px / 580px  * 100%
      
      @include breakpoint-narrowwrap {
      }

      &.-tsue {
        bottom: 5vw;
        left: 5vw;

        @include breakpoint-tb {
          bottom: 10px;
          left: 10px;
        }
        @include breakpoint-pc {
          bottom: 20px;
          left: 20px;
        }
      }
      
      &.-kasa {
        top: 5vw;
        right: 5vw;

        @include breakpoint-tb {
          top: 10px;
          right: 10px;
        }
        @include breakpoint-pc {
          top: 20px;
          right: 20px;
        }
      }

      img {
        
      }
    }
  }
  
  ///// 製品開発 - つえがさ /////
  &.-frontOriginalTg01 {
    position: relative;
    width: 78px;  //97px * 0.8 = 77.6px
    margin-top: 31px; //-frontOriginalTg01Txtの半分の高さ、ずらす
    margin-left: auto;
    margin-right: auto;
    
    @include breakpoint-pc {
      position: absolute;
      top: 120px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 97px;
      margin-top: 0;
      margin-left: 0;
    }
    @include breakpoint-narrow {
      top: 95px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  &.-frontOriginalTg01Txt {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(100%);
    width: 62px;  //77px * 0.8 = 61.6px
    
    @include breakpoint-pc {
      left: auto;
      top: 15px;
      right: 15px;
      transform: translateX(0);
      width: 77px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  
  ///// 製品開発 - つえがさ - 特徴4列 /////
  &.-frontTsuegasaFeatures {
    width: 100%;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  ///// 製品開発 - つえがさ - バリエーション /////
  &.-frontTsuegasaVariation {
    position: absolute;
    bottom: -5px - 16px - 5px;  //-16pxは、英字見出しのfont-size、2つ目の-5pxは微調整
    right: 0;
    transform: translate(0, 0);
//    bottom: -5px;  
//    right: -30px;
//    transform: translate(100%, 0);
    width: 61px;  //122px * 0.5 = 61px
    height: 57px;  //113px * 0.5 = 56.5px

    @include breakpoint-pc {
      bottom: 0;
      right: 2.419354838709677vw;  //30px / 1240px * 100vw
      transform: translate(0, 100%);
      width: 9.838709677419355vw;  //122px / 1240px * 100vw
      height: 9.112903225806452vw;  //113px / 1240px * 100vw
    }
    @include breakpoint-narrowwrap {
      bottom: 0;
      right: 30px;
      width: 122px;
      height: 113px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  ///// 製品開発 - グリッド状に配置する画像 /////
  &.-frontOriginalImg {
    width: 100%;
    height: 100%;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }



  ///// 動かない背景画像 /////
  &.-frontGreetingsFull, &.-frontHistoryFull {
    position: relative;
    width: 100%;
    height: 100vh;

    @include breakpoint-max {
      height: 100vh;
    }
    
    //スマホ時、縦長画像導入後 2024/5/8以前
    .luxy-fixed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      object-fit: cover;

      @media (min-aspect-ratio: 4/10) {  //幅4 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 7/10) {  //幅7 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 10/10) {  //幅10 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 13/10) {  //幅13 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 16/10) {  //幅16 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
    }
/*
    //スマホ時、縦長画像導入前 2024/5/8以前
    .luxy-fixed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      object-fit: cover;

      @media (min-aspect-ratio: 4/10) {  //幅4 : 高さ10の比率より「幅がひろい」時に適用
        height: 190vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 7/10) {  //幅7 : 高さ10の比率より「幅がひろい」時に適用
        height: 180vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 10/10) {  //幅10 : 高さ10の比率より「幅がひろい」時に適用
        height: 170vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 13/10) {  //幅13 : 高さ10の比率より「幅がひろい」時に適用
        height: 160vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
      @media (min-aspect-ratio: 16/10) {  //幅16 : 高さ10の比率より「幅がひろい」時に適用
        height: 150vh;  //150vh以上にしないと、ブラウザの高さが大きい時に、下に余白ができてしまう。
      }
    }
*/
  }


}