.p3-shadow {
  ///// テキストシャドウ /////
/*
  &.-shadow01 {
    -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.47), 0px 0px 18px rgba(0, 0, 0, 0.25);
  }

  &.-shadow02 {
    -moz-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    -webkit-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    -ms-text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.21), 0px 0px 18px rgba(0, 0, 0, 0.16);
  }
*/

  &.-box01 {
    -moz-box-shadow: -2px -2px 2px color-bk(0.21) inset, 2px 2px 2px color-wh(1) inset, 3px 9px 10px color-bk(0.06);
    -webkit-box-shadow: -2px -2px 2px color-bk(0.21) inset, 2px 2px 2px color-wh(1) inset, 3px 9px 10px color-bk(0.06);
    -ms-box-shadow: -2px -2px 2px color-bk(0.21) inset, 2px 2px 2px color-wh(1) inset, 3px 9px 10px color-bk(0.06);
    box-shadow: -2px -2px 2px color-bk(0.21) inset, 2px 2px 2px color-wh(1) inset, 3px 9px 10px color-bk(0.06);
  }
}