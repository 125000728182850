.p2-ftlogo {
  width: 65px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  @include breakpoint-contentinnerhalf {
    margin-left: 0;
  }
  @include breakpoint-pc {
    margin-bottom: 20px;
  }
  
  img {
    width: 100%;
    height: auto;
  }
}