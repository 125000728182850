.p1-logo {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include flex-align-items-center;
  position: fixed;
  top: 0;
  left: 5vw;
//  position: relative;
  z-index: 1200;
  height: $header-height-sp;
//  height: 100%;
  box-sizing: border-box;
  mix-blend-mode: exclusion;

  @include breakpoint-sp {
  }
  @include breakpoint-pc {
    height: $header-height-pc;
  }
  @include breakpoint-narrowinner {
    height: $header-height-ni;
  }
  @include breakpoint-narrow {
    left: 60px;
    height: $header-height-na;
  }

  &_item {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    width: 100%;
    height: auto;
  }

  &_img {
    display: block;
    width: 96px;  //120px * 0.8
    height: auto;

    @include breakpoint-sp {
    }
    @include breakpoint-pc {
      width: 96px;  //120px * 0.8
    }
    @include breakpoint-narrowinner {
      width: 108px; //120px * 0.9 = 108px
    }
    @include breakpoint-narrow {
      width: 120px;
    }
  }
}