.p0-hero {
  @include breakpoint-pc {
  }
  
  /* トップページ */
  &.-front {
    position: relative;
    overflow: hidden;
    padding: 0;
    box-sizing: border-box;

    &.-scene01 {
      height: $front-kv-height-sp;
      background-image: url("../images/front/kv/scene01_bg_sp.jpg");
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;

      @include breakpoint-pc {
        height: $front-kv-height-pc;
        background-image: url("../images/front/kv/scene01_bg_pc.jpg");
        background-position: center bottom;
      }

      // 画面幅:高さ比が10:25より幅広の時スタイルが適用される
      @media (min-aspect-ratio: 10/25) {
        background-size: auto 100%;
      }
      // 画面幅:高さ比が10:20より幅広の時スタイルが適用される
      @media (min-aspect-ratio: 10/20) {
        background-size: auto 110%;
      }
      @media (min-aspect-ratio: 10/15) {
        background-size: auto 120%;
      }
      @media (min-aspect-ratio: 10/10) {
        background-size: cover;
      }
    }
    
    &.-scene02, &.-scene03 {
      & .p0-hero {
        &_content {
          width: calc(100% - #{$main-content-px-sp} - #{$main-content-px-sp});
          margin: 0 auto;
      
          @include breakpoint-pc {
            width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
          }
      
          @include breakpoint-narrow {
            width: $narrowinner;
          }
          @include breakpoint-content {
            width: $narrowinner;
          }
        }
      }
    }

    &.-scene02 {
      height: $front-kv-scene02-height-sp;

      @include breakpoint-pc {
        height: $front-kv-scene02-height-pc;
      }
    }
    
    & .p0-hero {
      &_content {
        position: relative;
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
  
        @include breakpoint-pc {
        }

        &.-relative, &.-relativeDummy {
          padding-top: 36vh !important;
          box-sizing: border-box;
          transform: none !important;
          height: auto;
      
          @media (min-aspect-ratio: 8/6) {  //幅 / 高さ(幅800px : 高さ600pxの比率よりも幅広の場合
            padding-top: 30vh !important;
        
            @media all and (min-height: 600px) {  //高さ600以上
              padding-top: 32vh !important;
            }
            @media all and (min-height: 700px) {  //高さ700以上
              padding-top: 34vh !important;
            }
            @media all and (min-height: 800px) {  //高さ800以上
              padding-top: 36vh !important; //(380px - 20px(line-heightで上に20px余白が開く分調整)) / 1000px(PSDでのKVの高さ) * 100% = 36%
            }
          }
        }
        
        &.-relativeDummy {
          position: absolute;
          left: -100vw;
          width: 1px;
          opacity: 0;
        }
      }
      
      &_catchcopy {
        @include flexbox;
        @include flex-direction-column;
        @include flex-justify-center;
        @include flex-align-items-center;
        @include flex-align-content-center;
        z-index: 1;
        box-sizing: border-box;
        @include font-sprmin("sb");
        font-size: 12.333333333333333vw; //59.2 / 480px * 100vw 
        text-align: center;
        line-height: 1.4;
  
        @include breakpoint-tb {
          font-size: 5.92rem; //7.4 * 0.8 = 5.92
        }
        @include breakpoint-pc {
          font-size: 7.4rem;
        }
        @media all and (max-height: 480px) {
          font-size: 4rem;
        }
        
        & .delimiter {
          display: inline-block;
          position: absolute;
          top: 0;
          right: 0;
          transform: translateX(100%);
          width: 1em;
        }
        
        &_txt {
          @include flexbox;
          @include flex-direction-column;
          @include flex-justify-center;
          @include flex-align-items-center;
          @include flex-align-content-center;
          position: relative;
          box-sizing: border-box;

          &.-scene01 {
            margin-bottom: 28px;  //55px / 2 = 27.5
            color: color-wh(1);

            @include breakpoint-pc {
              margin-bottom: 55px;
            }
          }

          &.-scene02 {
            margin-bottom: 35px;  //70px / 2 = 35
            color: color-blue02(1);

            @include breakpoint-pc {
              margin-bottom: 70px;
            }
          }

          &.-scene03 {
            margin-bottom: 35px;  //70px / 2 = 35
            color: color-blue02(1);

            @include breakpoint-pc {
              margin-bottom: 70px;
            }
          }

        }
      }
      
      &_logo {
        position: relative;
        width: 189px;
        height: 60px;
        margin: 0 auto;

        &_jp, &_en {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  
  /* 下層ページ */
  &.-lowerpage {
    position: relative;
    width: 100%;
    height: 290px;  //360px * 0.8 = 288px、≒ 290px
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 0;
    box-sizing: border-box;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint-pc {
      height: 360px;
      margin-bottom: 40px;
      padding: 0;
    }

    & .p0-hero_content {
      @include flexbox;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-center;
      @include flex-align-content-center;
      height: 100%;
    }

    & .p0-hero_headline {
      @include font-sprmin("sb");
      @include fontSize(5, 4); //5 * 0.8 = 4
      @include lineHeight(1, 1);
      color: color-wh(1);
    }
  }
  

  ///// お問い合わせ /////
  &.-contact {
    background-image: url("../images/contact/kv_pc.jpg");
  }
  

  ///// プライバシーポリシー /////
  &.-privacy {
    background-image: url("../images/privacy/kv_pc.jpg");
  }
  

  ///// エラーページ /////
  &.-error {
    background-image: url("../images/error/kv_pc.jpg");
  }
}


// hero js & animation
.js-heroScene01, .js-heroScene02, .js-heroScene03 {
  display: block;
  position: relative;
  width: 100%;
  text-align: center !important;
}
.js-heroScene01 {
  top: 36vh; //(380px - 20px(line-heightで上に20px余白が開く分調整)) / 1000px(PSDでのKVの高さ) * 100% = 36%

  @media (min-aspect-ratio: 8/6) {  //幅 / 高さ(幅800px : 高さ600pxの比率よりも幅広の場合
    top: 30vh;   //高さ600未満

    @media all and (min-height: 600px) {  //高さ600以上
      top: 32vh;
    }
    @media all and (min-height: 700px) {  //高さ700以上
      top: 34vh;
    }
    @media all and (min-height: 800px) {  //高さ800以上
      top: 36vh;
    }
  }
}
.js-heroScene02 {
  top: calc(36vh - #{$front-kv-height-sp}); //(380px - 20px(line-heightで上に20px余白が開く分調整)) / 1000px(PSDでのKVの高さ) * 100% = 36%

  @media (min-aspect-ratio: 8/6) {  //幅 / 高さ(幅800px : 高さ600pxの比率よりも幅広の場合
    top: calc(30vh - #{$front-kv-height-sp});   //高さ600未満

    @media all and (min-height: 600px) {  //高さ600以上
      top: calc(32vh - #{$front-kv-height-sp});
    }
    @media all and (min-height: 700px) {  //高さ700以上
      top: calc(34vh - #{$front-kv-height-sp});
    }
    @media all and (min-height: 800px) {  //高さ800以上
      top: calc(36vh - #{$front-kv-height-sp});
    }
  }

  @include breakpoint-pc {
    top: calc(36vh - #{$front-kv-height-pc}); //(380px - 20px(line-heightで上に20px余白が開く分調整)) / 1000px(PSDでのKVの高さ) * 100% = 36%

    @media (min-aspect-ratio: 8/6) {  //幅 / 高さ(幅800px : 高さ600pxの比率よりも幅広の場合
      top: calc(30vh - #{$front-kv-height-pc});   //高さ600未満
  
      @media all and (min-height: 600px) {  //高さ600以上
        top: calc(32vh - #{$front-kv-height-pc});
      }
      @media all and (min-height: 700px) {  //高さ700以上
        top: calc(34vh - #{$front-kv-height-pc});
      }
      @media all and (min-height: 800px) {  //高さ800以上
        top: calc(36vh - #{$front-kv-height-pc});
      }
    }
  }
  
  &.-relative {
    position: relative;
    top: 0 !important;
    transform: none !important;
    height: auto;
  }
}
