.c-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); //二等分
  gap: 8px 8px; //10px * 0.8 = 8px
  
  @include breakpoint-tb {
    grid-template-columns: repeat(3, 1fr); //三等分
    gap: 10px 10px;
  }

  &_item {
    position: relative;

    //1列目2列目結合
    &.-col13 {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    //2列目3列目結合
    &.-col24 {
      grid-column-start: 2;
      grid-column-end: 4;
    }

    //1行目2行目結合
    &.-row13 {
      grid-row-start: 1;
      grid-row-end: 3;
    }
    
    &.-tbCol24SpCol13 {
      grid-column-start: 1;
      grid-column-end: 3;

      @include breakpoint-tb {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }
  }
  
  // 4列
  ///// 製品開発 - つえがさ - 「特徴4列」で使用 /////
  &.-col4 {
    $col4-gap-x-sp: 3vw;  //5vw * (40 / 60)
    $col4-gap-x-pc: 3.2vw;  //40px / 1240px * 100vw
    $col4-gap-x-nw: 40px;
    $col4-gap-y-sp: 7.4vw;
    $col4-gap-y-tb: 3.2vw;  //5vw * (40 / 60)

    grid-template-columns: repeat(1, 1fr); //一等分
    gap: $col4-gap-y-sp 0;
    width: 100%;

    @include breakpoint-sp {
      grid-template-columns: repeat(2, 1fr); //二等分
      gap: $col4-gap-y-tb $col4-gap-x-sp;
    }
    @include breakpoint-pc {
      grid-template-columns: repeat(4, 1fr); //四等分
      gap: 0 $col4-gap-x-pc;
    }
    @include breakpoint-narrowwrap {
      gap: 0 $col4-gap-x-nw;
    }
    
    .c-grid {
      &_item {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }


  ///// お取引実績 /////
  &.-bizrec {
    grid-template-columns: repeat(1, 1fr); //一等分(縦並び)
    gap: 10px 0;
    
    @include breakpoint-contentinnerhalf {
      grid-template-columns: 170px auto; //左寄せ・左のhead固定幅、右のbody可変幅
      gap: 0 40px;
    }
    @include breakpoint-pc {
      grid-template-columns: 210px auto; //左寄せ・左のhead固定幅、右のbody可変幅
    }
    @include breakpoint-narrowinner {
      grid-template-columns: repeat(2, 1fr); //二等分(横並び)
    }
    
    .c-grid {
      &_item {
        &.-body {
//          padding-left: 60px;
          box-sizing: border-box;
  
          @include breakpoint-contentinnerhalf {
//            padding-left: 0;
          }
        }
      }
    }
  }
}