@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500&family=Crimson+Text:wght@400;600;700&family=Noto+Sans+JP:wght@400;500;700&family=Roboto+Serif:opsz@8..144&family=Shippori+Antique&family=Shippori+Mincho:wght@400;500;600;700;800&display=swap');

@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


///// Google Fonts /////
//源の角ゴシック | noto sans
@mixin font-ntsan($status) {
  font-family: 'Noto Sans JP', sans-serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'bd' {
    font-weight: 700;
  }
}


//しっぽり明朝 | Shippori Mincho
@mixin font-sprmin($status) {
  font-family: 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'sb' {  //SemiBold
    font-weight: 600;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  } @else if $status == 'eb' {  //ExtraBold
    font-weight: 800;
  }
}


//YakuHanMP
@mixin font-yakuhanmp($status) {
  font-family: YakuHanMP, 'Shippori Mincho', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'm' {
    font-weight: 500;
  } @else if $status == 'sb' {  //SemiBold
    font-weight: 600;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  } @else if $status == 'eb' {  //ExtraBold
    font-weight: 800;
  }
}

//しっぽりアンチック | Shippori Antique
@mixin font-spratq() {
  font-family: 'Shippori Antique', sans-serif;
  font-style: normal;
  font-weight: 400;
}


//Cormorant Garamond
@mixin font-cmrgrm($status) {
  font-family: 'Cormorant Garamond', serif;
  font-style: normal;
  font-weight: 500;

  @if $status == 'm' {
    font-weight: 500;
  }
}


//Roboto Serif
@mixin font-rbtsrf($status) {
  font-family: 'Roboto Serif', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  }
}


//Crimson Text
@mixin font-critxt($status) {
  font-family: 'Crimson Text', serif;
  font-style: normal;
  font-weight: 400;

  @if $status == 'r' {
    font-weight: 400;
  } @else if $status == 'sb' {  //SemiBold
    font-weight: 600;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  }
}
///// Google Fonts /////
